<!-- 学习排行 -->
<template>
 <div>
    <div v-if="isNodata == false">
        <div class="ranking_wrap">
            <div class="content">
                <img src="../../../assets/images/bg_ranking.png" alt="">
                <div v-for="(v,i) in rankingList" :key="i">
                    <div class="timeRanking timeRankingFirst" v-if="i==0">
                        {{SecondToDate(v.studyTime)?SecondToDate(v.studyTime):'-'}}
                    </div>
                    <div class="timeRanking timeRankingSecond" v-if="i==1">
                        {{SecondToDate(v.studyTime)?SecondToDate(v.studyTime):'-'}}
                    </div>
                    <div class="timeRanking timeRankingThird" v-if="i==2">
                       {{SecondToDate(v.studyTime)?SecondToDate(v.studyTime):'-'}}
                    </div>
                    <dl class="personInformation firstPerson" v-if="i==0">
                        <dt><img :src="v.src?v.src:headImg" style="width:76px;height:76px;border-radius:50%;border:2px solid #ECCA9F"/></dt>
                        <dd>
                            {{v.userName}}
                        </dd>
                    </dl>
                    <dl class="personInformation secondPerson" v-if="i==1">
                        <dt><img :src="v.src?v.src:headImg" style="width:56px;height:56px;border-radius:50%;border:2px solid #ECCA9F"/></dt>
                        <dd>
                            {{v.userName}}
                        </dd>
                    </dl>
                    <dl class="personInformation thirdPerson" v-if="i==2">
                        <dt><img :src="v.src?v.src:headImg" style="width:56px;height:56px;border-radius:50%;border:2px solid #ECCA9F"/></dt>
                        <dd>
                            {{v.userName}}
                        </dd>
                    </dl>
                </div>
        
        
            </div>
        </div>
        <div class="content_table"> 
            <el-table
                :data="rankingList"
                :header-cell-style="{
                    background: '#f8f8f8',
                    color: '#999',
                    fontSize:'16px',
                }"
                :cell-style="{
                    background: '#f8f8f8',
                    fontSize:'16px',
                }"
                style="width: 100%;">
                <el-table-column
                    label="排行"
                    type="index"
                    width="120">
                    <template #default="scope">
                        <span v-if="scope.$index==0"><img src="../../../assets/images/icon_first.png" alt=""></span>
                        <span v-if="scope.$index==1"><img src="../../../assets/images/icon_second.png" alt=""></span>
                        <span v-if="scope.$index==2"><img src="../../../assets/images/icon_third.png" alt=""></span>
                        <span v-if="scope.$index>=3" class="rankStyle">{{scope.$index+1}}</span>
                    </template>  
                </el-table-column>
                <el-table-column
                    label="用户名"
                    width="180">
                    <template #default="scope">
                        <div style="display:flex"><img :src="scope.row.headImg?scope.row.headImg:headImg" style="width:30px;height:30px;border-radius:50%;" alt=""><span style="margin-top:4%;font-weight: 400;color: #333333;font-size:16px;margin-left:6px">{{scope.row.userName}}</span></div>
                    </template> 
                </el-table-column>
                <el-table-column
                    label="时间"
                    width="180">
                    <template #default="scope">
                        <div style="font-weight: 400;color: #333333;font-size:16px">
                            学习<span style="color:#F04B4A">{{scope.row.lastStudyTime?SecondToDate(scope.row.studyTime):'0时'}}</span>
                        </div>
                    </template>    
                </el-table-column>
                <el-table-column
                    label="最后一次学习时间"
                    show-overflow-tooltip>
                    <template #default="scope">
                        <div style="font-weight: 400;color: #333333;font-size:16px">
                            {{scope.row.lastStudyTime?scope.row.lastStudyTime:'-'}}
                        </div>
                    </template>  
                </el-table-column>
            </el-table>
        </div>
    </div>
    <div v-else style="text-align: center;height: 66vh;">
      <img src="@/assets/images/noData.png" alt="" style="margin-top:12%;margin-bottom:30px;">
      <p style="font-weight: 400;line-height: 22px;color: #BFBFBF;">暂无数据</p>
    </div>
 </div>
</template>

<script>
import {GETSTUDYRANKE} from '@/server/details'
export default {
 components:{

 },
 data () {
  return {
      rankingList:[],
      headImg:require('@/assets/images/header.png'),
      isNodata:false
  }
 },
 created(){
     this.getList()
 },
 methods:{
     SecondToDate: function(msd) {

        var time =msd / 1000
        console.log(time)
        if (null != time && "" != time) {
        if (time > 60 && time < 60 * 60) {
            time = parseInt(time / 60.0) + "分钟";
            // time = parseInt(time / 60.0) + "分钟" + parseInt((parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60) + "秒";
        }
        else if (time >= 60 * 60 && time < 60 * 60 * 24) {
            time = parseInt(time / 3600.0) + "时" + parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) + "分";

            // time = parseInt(time / 3600.0) + "小时" + parseInt((parseFloat(time / 3600.0) -
            // parseInt(time / 3600.0)) * 60) + "分钟" +
            // parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
            // parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
        } else if (time >= 60 * 60 * 24) {
            let daay = parseInt(time / 3600.0/24);
            time = (parseInt(time / 3600.0) + (daay * 24)) + "时" + parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) + "分";

            // time = parseInt(time / 3600.0/24) + "天" +parseInt((parseFloat(time / 3600.0/24)-
            // parseInt(time / 3600.0/24))*24) + "小时" + parseInt((parseFloat(time / 3600.0) -
            // parseInt(time / 3600.0)) * 60) + "分钟" +
            // parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
            // parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
        }
            else {
                time = "0时";
                //time = parseInt(time) + "秒";
            }
        }
        return time;
    },
     getList(){
         GETSTUDYRANKE({gradeId:this.$route.query.id}).then(res=>{
             console.log(res.data,'============');
             if(res.data.code==0){
                if(res.data.data.length == 0){
                    console.log('111')
                    this.isNodata = true
                }
                 this.rankingList = res.data.data
             }
         })
     }
 }
}

</script>

<style lang='scss' scoped>
.ranking_wrap {
    width: 100%;
    height: 296px;
    background: #F8F8F8;
    .content {
        width: 540px;
        margin: 0 auto;
        padding-top: 130px;
        position: relative;
        .timeRanking {
            position: absolute;
            width: 110px;
            height: 33px;
            text-align: center;
            line-height: 33px;
            background: #FFFFFF;
            border-radius: 17px;
            font-weight: 400;
            font-size: 16px;
            color:#BC7B2A ;
        }
        .timeRankingFirst {
            bottom: 74px;
            left: 214px;
        }
        .timeRankingSecond {
            bottom: 36px;
            left: 39px;
        }
        .timeRankingThird {
            bottom:36px;
            right: 40px
        }
        .personInformation {
            position: absolute;
        }
        .firstPerson {
            top: 14px;
            left: 42%;
            dd{
                width: 80px;
                height: 28px;
                text-align: center;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                // line-height: 28px;
            }
        }
        .secondPerson {
            top: 76px;
            left: 12%;
            dd{
                width: 60px;
                height: 28px;
                text-align: center;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                // line-height: 28px;
            }
        }
        .thirdPerson {
            top: 76px;
            right:64px;
            dd{
                width: 60px;
                height: 28px;
                text-align: center;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                // line-height: 28px;
            }
        }
    }
}
.content_table {
    width: 100%;
    margin: 0 auto;
    margin-top: 2px;
    background: #F8F8F8;
    .rankStyle {
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        color:#999;
        display: inline-block;
        border-radius: 50%;
        background: #EDECED;
    }
}
/deep/.el-table tr{
    height: 60px;
}
/deep/.el-table th{
    height: 80px;
    // line-height: 80px;
    border-bottom: 1px solid #fff;
}
/deep/.el-table td{
    height: 80px;
    border-bottom: 1px solid #fff;
}
/deep/ .cell{
    font-size: 16px;
    padding-left: 31px;
}

</style>
