export const class_date = '/corporate/university/course/find/grade/course/day'

export const class_time = '/corporate/university/course/find/grade/course/time/seg'

export const class_day = '/corporate/university/course/find/grade/course/info/day'

export const teacher = '/corporate/university/teacher/grade/role/teacher'

export const update_teacher = '/corporate/university/course/tea/grade/course'

export const extend_course = '/corporate/university/course/extend/grade/course'

export const unuse_day = '/corporate/university/course/find/grade/course/unuse/day'

export const move_day = '/corporate/university/course/move/grade/course'