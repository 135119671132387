<template>
  <div class="login_wrap">
    <div class="login_form_wrap" v-if="isReg">
      <h3 class="login_form_title">
        {{
          userRole == "teacher"
            ? "讲师端"
            : userRole == "student"
            ? "学员端"
            : ""
        }}登录
        <span
          class="login_change"
          @click="switchPlatform"
          v-if="!hasOriganRole"
        >
          <i class="el-icon-refresh"></i>
          切换角色
        </span>
      </h3>
      <Form />
      <!-- <wx-code v-if="loginMode == 2"></wx-code> -->
    </div>
    <div class="login_form_wrap" v-else>
      <!-- 未注册 -->
      <h3 class="login_form_title">绑定手机号</h3>
      <reg-forms :code="unionId" :state="state"></reg-forms>
    </div>
  </div>
</template>

<script>
import Form from "./components/LoginForm.vue";
import WxCode from "./components/WxCode.vue";
import RegForms from "./components/RegForms.vue";
import { getRole } from "@/utils/utils";
import { getUserRole } from "@/server/login";
import { ElMessageBox } from "element-plus";
import { Refresh } from "@element-plus/icons";
let appPage = null;
export default {
  components: {
    Form,
    WxCode,
    RegForms,
  },
  data() {
    return {
      hasOriganRole: true,
      userRole: null,
      isReg: true,
      unionId: "",
      state: "",
      code: "",
    };
  },
  created() {
    appPage = this;
    // this.getRoles();
  },
  methods: {
    convertFormData(data) {
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      return formData;
    },
    getRoles() {
      getUserRole()
        .then(({ data }) => {
          if (data.code === 0) {
            const arr = [];
            const role = getRole();
            data.data.forEach(({ roleCode }) => {
              arr.push(roleCode);
            });
            console.log(role);
            console.log(arr);
            if (
              arr.indexOf(role) > -1 ||
              (role === "teacher" && arr.indexOf("mater") > -1)
            ) {
              if (role === "teacher") {
                this.$router.push({
                  path: "/teachlogin",
                });
              } else {
                this.$router.push({
                  path: "/studentlogin",
                });
              }

              sessionStorage.setItem("role", JSON.stringify(arr));
            } else {
              if (arr.length) {
                let message =
                  arr.indexOf("teacher") > -1 || arr.indexOf("mater") > -1
                    ? "请使用老师端登录"
                    : "";
                if (!message && arr[0] === "student")
                  message = "请使用学员端登录";
                this.$message.warning(message);
              } else {
                if (role === "teacher") {
                  this.$message.warning("请使用学员端登录");
                } else {
                  this.$router.push({
                    path: "/studentlogin",
                  });
                }
              }
            }
          }
        })
        .catch((e) => {});
    },
    switchPlatform() {
      ElMessageBox.alert("请选择角色", "提示", {
        confirmButtonText: "我是学员",
        cancelButtonText: "我是讲师",
        showCancelButton: true,
        showClose: false,
        type: "warning",
        center: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        closeOnHashChange: false,
      })
        .then(() => {
          window.SUN_LOCAL_ROLE = "student";
          this.userRole = getRole();
          localStorage.setItem("handRole", "student");
        })
        .catch(() => {
          window.SUN_LOCAL_ROLE = "teacher";
          localStorage.setItem("handRole", "teacher");
          this.userRole = getRole();
        });
    },
  },
  mounted() {
    this.userRole = getRole();
    if (!this.userRole) {
      this.hasOriganRole = false;
      this.switchPlatform();
    }
  },
  provide: () => ({
    wechartLogin: function (res, paramsCode) {
      appPage.code = paramsCode.code;
      appPage.state = paramsCode.state;
      let { data } = res;
      if (data.code == 0) {
        // 这里 数据后端返回的数据层级不一致
        // 如果登录出现问题 找大史磊
        console.log("token");
        let data = res.data.data;
        sessionStorage.setItem("access_token", data.access_token);
        sessionStorage.setItem("loginInfo", JSON.stringify(data));
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("loginInfo", JSON.stringify(data));
        //成功后走之后逻辑
        appPage.getRoles();
      }
      if (data.code == 10010) {
        appPage.unionId = data.data;
        appPage.$message.success("请绑定手机号！");
        appPage.isReg = false;
      }
    },
  }),
};
</script>

<style lang="scss">
.login_change {
  font-size: 16px;
  color: #2d70f8;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.login_wrap {
  background-color: $theme_color;
  height: 100vh;
  position: relative;

  .login_form_wrap {
    position: absolute;
    top: 200px;
    margin: 0 auto;
    width: 500px;
    height: 400px;
    background-color: $white;
    border-radius: 8px;
    padding-top: 40px;
    position: relative;
    .loginSwich {
      position: absolute;
      right: 0;
      top: 0;
      height: 80px;
      width: 160px;
      .loginWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        img {
          width: 80px;
          height: 80px;
        }
        .txt {
          width: 82px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          background: #eaf2ff;
          font-size: 14px;
          font-weight: 400;
          color: #2d70f8;
        }
      }
    }
  }

  .login_form_title {
    font-size: 33px;
    font-weight: 500;
    color: $font_color_black;
    text-align: center;
    margin-bottom: 40px;
  }
}
</style>
