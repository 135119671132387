<template>
  <div class="wrap">
    <Menu menuTitle="回顾考题" :type="role == 'student'?true:false"  :gradeId="gradeId" :gradeConfId="gradeConfId" />
    <div class="main">
      <div v-for="(v, i) in data" :key="i">
        <div v-if="i == nextID">
          <Single
            v-if="v.type === 0"
            :keys="[v.questionsPic?1:2]"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.length"
            :itemIndex="i + 1"
            :reverse="true"
            :review="true"
          ></Single>
          <Multiple
            v-if="v.type === 1"
            :keys="[v.questionsPic?1:2]"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.length"
            :itemIndex="i + 1"
            :reverse="true"
            :review="true"
          ></Multiple>
          <Judge
            v-if="v.type === 2"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.length"
            :itemIndex="i + 1"
            :reverse="true"
            :review="true"
          ></Judge>
          <Completion
            v-if="v.type === 3"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.length"
            :itemIndex="i + 1"
            :reverse="true"
            :review="true"
          ></Completion>
          <Answer
            v-if="v.type === 4"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.length"
            :itemIndex="i + 1"
            :reverse="true"
            :review="true"
          ></Answer>
          <Sort
            v-if="v.type === 6"
            :keys="[v.questionsPic?1:2]"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.length"
            :itemIndex="i + 1"
            :reverse="true"
            :review="true"
          ></Sort>
        </div>
      </div>
      <div class="bottom">
        <div class="right_bottom">
        <el-button
          size="mini"
          type="primary"
          v-if="endIndex !== data.length"
          @click="handleNext">下一个</el-button>
        <el-button
          size="mini"
          type="primary"
          v-if="endIndex === data.length && !this.$route.query.index"
          @click="handleEnd"
          >结束</el-button>
        <el-button size="mini" class="question_board" @click="handleOpen">{{ openText }}</el-button>
        <el-button v-if="isTeacher" size="mini" style="margin-left:256px" type="warning" class="questionDetail" @click="handleOpenDetails">答题详情</el-button>
      </div>
      <div class="table" v-show="tabShow">
        <p class="table_item" v-for="(v,i) in data" :key="i" @click="handleTab(i)"
        :class="[activer ==  i?'activer':'']">
        <span v-if="v.studentAnswerRelas !== null && !this.$route.query.index" :class="[v.isAnswerRight?'active_right':'active_err']">{{ i+1 }}</span>
        <span v-else>{{ i+1 }}</span></p>
      </div>
      <!-- 答题详情 -->
      <div v-if="isTeacher && showDetails" class="table">
        <answer-details :visivle="showDetails" :endIndex="endIndex" :nextID="nextID" :list="data" :key="nextID"/>  
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import Single from "@/components/AnswerComponents/single.vue"; // 单选
import Multiple from "@/components/AnswerComponents/multiple.vue"; // 多选
import Judge from "@/components/AnswerComponents/judge.vue"; // 判断
import Completion from "@/components/AnswerComponents/completion.vue"; // 填空
import Sort from "@/components/AnswerComponents/sort.vue"; // 排序
import Answer from "@/components/AnswerComponents/answer.vue"; // 简答
import AnswerDetails from "./components/AnswerDetails"; // 答题详情
import Menu from '@/components/Menu'
import http from "@/utils/request";
import { getRole } from '@/utils/utils'
import { ref, toRaw } from "vue";
import {useRouter,useRoute} from 'vue-router';
export default {
  data () {
    return {
      role:getRole(),
    };
  },
  props: {
    type: Number,
  },
  components: { Single, Multiple, Judge, Completion, Sort, Answer,Menu , AnswerDetails},
  created(){
    if(this.$route.query.index){
      this.handleTab(this.$route.query.index)
    }
  },
  setup() {
    const nextID = ref(0);
    const tabShow = ref(false);
    const correct = ref(false);
    const data = ref([]);
    const openText = ref("展开题板");
    const qusionList = ref()
    const endType = ref()
    const route = useRoute()
    const router = useRouter()
    const activer = ref(0)

    // const keyIndex = ref(0)

    const answer = ref([])
    const showDetails = ref(false)
    const isTeacher = ref(getRole() === 'teacher')
    // console.log(isTeacher)
    let que = toRaw(route.query)
    const gradeId = que.gradeId
    const gradeConfId = que.gradeConfId

    // 下一个
    const handleNext = () => {
      nextID.value++;
      correct.value = false;
      activer.value=nextID.value;
    };

    const handleOpenDetails = () => {
      showDetails.value = !showDetails.value
    }
    // 题板
    const handleOpen = () => {
      tabShow.value = !tabShow.value;
      openText.value = tabShow.value ? "收起题板" : "展开题板";
    };
    const endIndex = ref();
    const handleIndex = (val) => {
      endIndex.value = val;
      endType.value = data.value[val-1].type;
    };
    // 结束按钮
    const handleEnd = () =>{
      // if(data.value.length - Object.values(qusionList.value).length !== 0){
      //   endType.value = 3
      // }else{
      //   endType.value = 2
      // }
      let role = getRole()
      if(role == 'student'){
        router.push({
          path: '/studentDetails',
          query: {
            id: gradeId,
            gradeConfId: gradeConfId
          }
        })
      }
      
    }

    // 题板点击
    const handleTab = (val) => {
      nextID.value = val;
      activer.value = nextID.value

    };
    // 获取数据
    const datas = { paperId: route.query.paperId, gradeId: route.query.gradeId, gradeConfId: route.query.gradeConfId};
    const datas1 ={ paperId: route.query.paperId, quId: route.query.quId };
    // console.log(getRole())
    if(route.query.index){
      http.post("/corporate/university/teacher/exam/viewTest", datas1).then((res) => {
        if (res.data.code === 0) {
          data.value = res.data.data.gradeQus
          console.log(data.value)
        }
      }).catch((e) => {});
    }else{
      http.post("/corporate/university/exam/testReview", datas).then((res) => {
        if (res.data.code === 0) {
          data.value = res.data.data
        }
      });
    }
    return {
      nextID,
      isTeacher,
      showDetails,
      tabShow,
      correct,
      endIndex,
      data,
      openText,
      endType,
      activer,
      answer,
      gradeId,
      gradeConfId,
      handleTab,
      handleNext,
      handleOpen,
      handleIndex,
      handleEnd,
      handleOpenDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
}
.main{
  width: 1200px;
  height: calc(100%-80px);
  background: white;
  margin: 80px auto 0 auto;
}
.bottom{
  // position: absolute;
  left: 100px;
  margin-top: 40px;
  .right_bottom {
    margin-left: 44px;
    .time {
      padding: 0 50px 0 20px;
      color: red;
    }
  }
.table {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  margin: 20px 0px;
  .table_item,.table_item>span {
    width: 44px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: inline-block; 
  }
  .table_item{
    border: 1px solid #CCCCCC;
    margin-right: -1px;
  }
  .activer{
    background: #2D70F8;
    color: white;
    margin-left: -1px;
    margin-right: -1px;
  }
  .active_right{
    background: #00C456;
    color:white
  }
  .active_err{
    background: #F04B4A;
    color:white
  }
}
}
.question_board{
  border: 1px solid #ddd;
}
.questionDetail{
  background: #FF7C02;
  border-radius: 4px;
  border: none;
}
</style>