<template>
  <div class="v-table">
    <!-- 考试成绩 -->
    <el-table
        v-if="type === 'curriculum'"
        :data="list"
        :empty-text='"暂无数据"'
        style="width: 100%">

        <el-table-column prop="date" label="排行" width="120" >
            <template #default="scope">
              <img v-if="scope.$index == 0" :src="first"  class="icon-img"/>
              <img v-else-if="scope.$index == 1" :src="second" class="icon-img"/>
              <img v-else-if="scope.$index == 2" :src="third" class="icon-img"/>
              <div v-else class="bage">{{scope.$index + 1}}</div>
            </template>
        </el-table-column>
        <el-table-column prop="userName" label="用户名" width="190">
            <template #default="scope">
              <el-avatar size="small" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />
              <span style="vertical-align:super;margin-left:8px;">{{ scope.row.userName }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="score" :label="showText" width="160" >
          <template #default="scope">
            <span style="color: red" v-if="parameType == '4'">{{ scope.row.wrongQunum }}</span>
            <span style="color: red" v-else-if="parameType == '5'">{{ scope.row.score }}<span style="color: #333333">分</span></span>
          </template>
        </el-table-column>
        <el-table-column prop="costTime" label="考试用时" >
          <template #default="scope">
            <span >{{ secondsFormat(scope.row.costTime) }}</span>
          </template>
        </el-table-column>
    </el-table>

    <!-- 回顾题目 -->
     <el-table
          v-else
          :data="list"
          :empty-text='"暂无数据"'
          style="width: 100%"
          :row-class-name="tableRowClassName"
          @row-click='handleClick'>
          <el-table-column type="index" label="题号" width="130" />
          <el-table-column prop="name" label="题目" >
             <template #default="scope">
               <span v-if="scope.row.name && String(scope.row.name).length > 28">{{ String(scope.row.name).substring(0,28) }}...</span>
               <span v-else>{{ scope.row.name }}</span>
             </template>
          </el-table-column>
          <el-table-column prop="rightNum" label="正确人数" width="130" /> 
          <el-table-column label="错误人数" width="130" >
            <!-- <template #default="scope">
               <span @click="handleClick(scope.row)">{{ scope.row.date }}</span>
             </template> -->
          <template #default="scope">
               <span v-if="scope.row.wrongNum > 10" style="color:#F04B4A">{{ scope.row.wrongNum }}</span>
               <span v-else>{{ scope.row.wrongNum }}</span>
             </template>
          </el-table-column>
    </el-table>

  </div>
    <!-- {{list}} -->
  
  
</template>

<script>
// import firstIcon from '@assets/images/avatar.png';
export default {
  data() {
    return {
      first:require("@/assets/images/icon_first.png"),
      second:require("@/assets/images/icon_second.png"),
      third:require("@/assets/images/icon_third.png"),
      selectedIndex:0,
      showText: ''
    };
  },
  props: ['list', 'day', 'id', 'type', 'parameType'],
  watch: {
    list() {
      // if (this.type === 'outline') this.dayId = this.list[0].id
    }
  },
  methods: {
    secondsFormat(e) {
      let s = Math.floor(e / 1000)
      console.log(s)
      // if(s<60){
      //   return '1分钟内'
      // }
     
      var day = Math.floor(s / (24 * 3600)); // Math.floor()向下取整
      var hour = Math.floor((s - day * 24 * 3600) / 3600);
      var minute = Math.floor((s - day * 24 * 3600 - hour * 3600) / 60);
      // console.log(minute)
      var second = s - day * 24 * 3600 - hour * 3600 - minute * 60;
      if (hour) {
        return hour + ":" + minute + ":" + second;
      } else {
        if (second < 10) {
          if (minute < 10) {
            return "00:0" + minute + ":" + second + "0";
          } else {
            return "00:" + minute + ":" + second + "0";
          }
        } else {
          if (minute < 10) {
            return "00:0" + minute + ":" + second ;
          } else {
            return "00:" + minute + ":" + second ;
          }
        }
      }
    },
    change() {
      if(this.type=='curriculum') {
        this.getClassDetail()
      }else{
        console.log(111);
      }
      
    },
    tableRowClassName ({row, rowIndex}) {
        //把每一行的索引放进row
        row.index = rowIndex;
    },
    handleClick(row, event, column) {
      console.log(row)
      const obj = {
        // gradeConfId: '1430404462558773250',
        quId: row.id,
        paperId: this.$route.query.paperId,
        curQesNum: row.index
      }
      // this.$router.push({path:'/treview',query:obj})

      const obj1 = {
        gradeConfId: this.$route.query.gradeConfId,
        gradeId: this.$route.query.gradeId,
        paperId: this.$route.query.paperId,
        quId: row.id,
        index: row.index
      };
      this.$router.push({ path: "/review", query: obj1 });
    }
  },
  created() {
    // this.gradeConfId = this.$route.query.id || JSON.parse(sessionStorage.getItem('gradeConfInfo')).id
    // this.dayList = data.data
    if (this.parameType == '4') {
      this.showText = '错题数'
    } else if (this.parameType == '5') {
      this.showText = '考试成绩'
    }
  }
}
</script>

<style lang="scss" scope>
.v-table {
  .el-table{
    th{
      background-color: #fafafa;
      
      .cell{
        color: #999;
      }
    }
    .cell{
        font-size: 16px;
        padding-left: 31px;
        color: #333;
    }
    td{
        height: 80px;
        .cell{
          div{
            font-size: 16px;
            color: #333;
          }
        }
    }
    td:nth-of-type(1){
      border-left: 1px solid #DDDDDD;
    }
    td:last-of-type{
      border-right: 1px solid #DDDDDD;
    }
  }
  .icon-img{
    width: 36px;
    height: 45px;
    display: block;
  }
  .bage{
     width: 32px;
    height: 32px;
    border-radius: 32px;
    text-align: center;
    line-height: 32px;
    color: #fff !important;
    background-color: #DDDDDD;
  }
}
</style>