export const classAttr = '/corporate/university/grade/getGradeConfList'

export const createClassApi = '/corporate/university/grade/add'

export const getClassListApi = '/corporate/university/grade/getList'

export const updateClassApi = '/corporate/university/grade/update'

export const organList = '/corporate/university/organ/organList'

export const graderoleuser = '/corporate/university/graderoleuser/page'

export const getStudentListApi = '/corporate/university/student/getList'

export const getTeacherListApi = '/corporate/university/grade/getList'

export const getTeacherStatus = '/corporate/university/async/data'

export const deleteClass = '/corporate/university/grade/delete'
