<template>
  <div class="wrap">
    <Menu
      :menuTitle="(this.$route.query.type === 'wrongQs')?'我的错题': '自测'"
      @closeTest="closeTest"
      :test="true"
      bgColor="#0B0C26"
    />
    <div class="main">
      <div style="position: relative;height: 500px;" v-if="loadingTrue"><img style="width:60px;position: absolute;left: 50%; top:200px" src="../../assets/images/loading.gif"></div>
      <!-- keys 1 === 单选按钮 2 === 单选图片 -->
      <div v-for="(v, i) in data" :key="i">
        <div v-if="i == nextID">
          <Single
            v-if="v.type === 0"
            :keys="[v.questionsPic?1:2]"
            @handleChange="handleChange"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.length"
            :itemIndex="i + 1"
            :test="true"
            :valIsRight="valIsRight"
          ></Single>
          <Multiple
            v-if="v.type === 1"
            :keys="[v.questionsPic?1:2]"
            @handleChange="handleChange"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.length"
            :itemIndex="i + 1"
            :boolVal="boolVal"
            :arr2="arr2"
            :test="true"
            :valIsRight="valIsRight"
          ></Multiple>
          <Judge
            v-if="v.type === 2"
            @handleChange="handleChange"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.length"
            :itemIndex="i + 1"
            :boolVal="boolVal"
            :arr2="arr2"
            :test="true"
            :valIsRight="valIsRight"
          ></Judge>
          <Completion
            v-if="v.type === 3"
            @handleChange="handleChange"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.length"
            :itemIndex="i + 1"
            :boolVal="boolVal"
            :arr2="arr2"
            :test="true"
            :valIsRight="valIsRight"
          ></Completion>
          <Answer
            v-if="v.type === 4"
            @handleChange="handleChange"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.length"
            :itemIndex="i + 1"
            :boolVal="boolVal"
            :arr2="arr2"
            :test="true"
            :valIsRight="valIsRight"
          ></Answer>
          <Sort
            v-if="v.type === 6"
            :keys="[v.questionsPic?1:2]"
            @handleChange="handleChange"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.length"
            :itemIndex="i + 1"
            :boolVal="boolVal"
            :arr2="arr2"
            :test="true"
            :valIsRight="valIsRight"
          ></Sort>
        </div>
      </div>
      <div class="bottom">
        <div class="right_bottom">
          <el-button
            size="mini"
            type="primary"
            v-if="
              !correct && !ending && data[nextID]
                ? data[nextID]['type'] != 0 && data[nextID]['type'] != 2
                : ''
            "
            :disabled="disabledSub"
            @click="handleSubmit"
            >提交</el-button
          >
          <el-button
            size="mini"
            type="primary"
            v-if="endIndex !== data.length"
            @click="handleNext"
            >下一个</el-button
          >
          <el-button
            size="mini"
            type="primary"
            v-if="endIndex === data.length || ending"
            @click="handleEnd"
            >结束</el-button
          >
          <el-button class="questionBoard" size="mini" @click="handleOpen">{{ openText }}</el-button>
          <span v-if="this.$route.query.type !== 'wrongQs'" class="time"> 剩余时间 <span v-if="this.$route.query.duration === '1'" class="time"> {{timetext}}秒</span><span v-else class="time"> {{timetext}}</span></span>
          <span
            v-show="!correct && data.length !== endIndex"
            @click="handleSkip"
            style="cursor: pointer;margin-left:20px;"
            >不会,跳过</span
          >
        </div>
        <div class="table" v-show="tabShow">
          <p
            class="table_item"
            v-for="(v, i) in data"
            :key="i"
            @click="handleTab(i, v.id)"
            :class="[activer == i ? 'activer' : '']"
          >
            <span
              :class="[
                activerRight.includes(v.id)
                  ? 'active_right'
                  : activerErr.includes(v.id)
                  ? 'active_err'
                  : '',
              ]"
              >{{ i + 1 }}</span
            >
          </p>
        </div>
      </div>
      <div class="selftest_loading">
        <Loading
          v-model="dialogVisible"
          :dialogVisible="dialogVisible"
          :dialogTitle="dialogTitle"
          @handleCancel="handleCancel"
          @handleConfirm="handleConfirm"
          :btnNormal="btnNormalText"
          :btnPrimary="btnPrimaryText"
        >
          <template #content>
            <div v-if="endType == 1">
              <p v-if="num >= 0">还有{{ num }}道题未做,确定退出吗？</p>
              <p v-else>还有0道题未做,确定退出吗？</p>
            </div>
            <div v-if="endType == 2">
              <p>本次练习有{{ num }}道错题</p>
            </div>
            <div v-if="endType == 3">
              <p>还有{{ num }}道题未做，确定结束吗?</p>
            </div>
          </template>
        </Loading>
      </div>
    </div>
    
  </div>
</template>

<script>
import Single from "@/components/AnswerComponents/single.vue"; // 单选
import Multiple from "@/components/AnswerComponents/multiple.vue"; // 多选
import Judge from "@/components/AnswerComponents/judge.vue"; // 判断
import Completion from "@/components/AnswerComponents/completion.vue"; // 填空
import Sort from "@/components/AnswerComponents/sort.vue"; // 排序
import Answer from "@/components/AnswerComponents/answer.vue"; // 简答
import Loading from "@/components/AnswerComponents/loading.vue"; // 退出框
import Menu from "@/components/Menu";
import http from "@/utils/request";
import { ref, reactive, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from 'element-plus'
import {StartStudy,EndStudy} from '@/server/details'

export default {
  props: {
    type: Number,
  },
  components: {
    Single,
    Multiple,
    Judge,
    Completion,
    Sort,
    Answer,
    Loading,
    Menu,
  },
  setup() {
    const nextID = ref(0);
    const tabShow = ref(false);
    const loadingTrue = ref(true);
    const disabledSub = ref(true);
    const correct = ref(false);
    const dialogVisible = reactive({ value: false });
    const dialogTitle = reactive("温馨提示");
    const data = ref([]);
    const openText = ref("展开题板");
    const num = ref(0);
    const bools = ref();
    const ending = ref();
    const qusionList = ref(undefined);
    const boolVal = ref();
    const endType = ref();
    const arr1 = ref([]); /// data
    const arr2 = ref([]); /// 选择后的数组答案
    const route = useRoute();
    const router = useRouter();
    const timetext = ref("不限时");
    const activer = ref(0);
    const activerRight = ref([]);
    const activerErr = ref([]);
    const valIsRight = ref(false);
    const btnNormalText = ref('取消')
    const btnPrimaryText = ref('确定')
    const selfVal = ref()

    localStorage.removeItem('selfVal')
    // 下一个
    const handleNext = () => {
      nextID.value++;
      disabledSub.value = true;
      num.value = Number(num.value) + 1;
      activer.value = nextID.value;
      if (activerRight.value.includes(data.value[nextID.value].id) || activerErr.value.includes(data.value[nextID.value].id)) {
        boolVal.value = false;
        correct.value = true;
      } else {
        boolVal.value = true;
        correct.value = false;
      }
      valIsRight.value = activerErr.value.includes(data.value[nextID.value].id)
        ? false
        : activerRight.value.includes(data.value[nextID.value].id)
        ? true
        : false;
    };
    // 题板
    const handleOpen = () => {
      tabShow.value = !tabShow.value;
      openText.value = tabShow.value ? "收起题板" : "展开题板";
    };
    const obj = {};
    const handleChange = (val) => {
      if(localStorage.getItem('selfVal') ) {
        selfVal.value = JSON.parse(localStorage.getItem('selfVal'))
      } else {
        selfVal.value = []
      }
      if(val.answerList.answerList.length > 1) {
        selfVal[val.answerList.answerList[0].itemIndex - 1] = val.answerList.answerList
      } else {
        if(val.type == 1) {
          selfVal[val.answerList.answerList[0].itemIndex - 1] = val.answerList.answerList
        } else {
          selfVal[val.answerList.answerList[0].itemIndex - 1] = val.answerList.answerList[0]
        }
      }
      localStorage.setItem('selfVal',JSON.stringify(selfVal))
      bools.value = val.bool;
      if (val.type == 0 || val.type == 2) {
        if (val.bool) {
          if (data.value.length !== endIndex.value) {
            // 选择正确自动跳转到下一题
            nextID.value++;
            correct.value = false;
            disabledSub.value = true;
          }
          activerRight.value.push(val.id);
        } else {
          correct.value = true;
          activerErr.value.push(val.id);
        }
        obj[val.id] = val.answerList;
        qusionList.value = obj;
        let anserData = {
          qusionList: Object.values(qusionList.value),
          duration: route.query.duration,
          gradeConfId: route.query.gradeConfId,
          gradeId: route.query.gradeId,
          paperId: route.query.paperId,
          score: null,
          examType: 2
        };
      } else {
        correct.value = false;
        disabledSub.value = false;
      }
      obj[val.id] = val.answerList;
      qusionList.value = obj;
    };
    const handleSubmit = () => {
      let ans = Object.values(qusionList.value);
      for (let i = 0; i < ans.length; i++) {
        for (let j = 0; j < data.value.length; j++) {
          if (ans[i].quId == data.value[j].id && data.value[j].type === 6) {
            // 排序
            arr1.value = [];
            arr1.value = data.value[j].answerList.map((v) => {
              return v.rightNum;
            });
            arr2.value = ans[i].answerList.map((v) => {
              return v.rightNum;
            });
            if (JSON.stringify(arr1.value) == JSON.stringify(arr2.value)) {
              boolVal.value = true;
            } else {
              boolVal.value = false;
            }
          } else if (
            ans[i].quId == data.value[j].id &&
            data.value[j].type === 4
          ) {
            // 简答
            arr1.value = [];
            arr1.value = data.value[j].answerList.map((v) => {
              return v.content;
            });
            arr2.value = ans[i].answerList.map((v) => {
              return v.content;
            });
            if (JSON.stringify(arr1.value) == JSON.stringify(arr2.value)) {
              boolVal.value = true;
            } else {
              boolVal.value = false;
            }
          } else if (
            ans[i].quId == data.value[j].id &&
            data.value[j].type === 3
          ) {
            // 填空
            arr1.value = [];
            arr1.value = data.value[j].answerList.map((v) => {
              return v.content;
            });
            arr2.value = ans[i].answerList.map((v) => {
              return v.content;
            });
            if (JSON.stringify(arr1.value) == JSON.stringify(arr2.value)) {
              boolVal.value = true;
            } else {
              boolVal.value = false;
            }
          } else if (
            ans[i].quId == data.value[j].id &&
            data.value[j].type === 1
          ) {
            // 多选
            arr1.value = [];
            data.value[j].answerList.filter((v) => {
              if (v.isRight == 1) arr1.value.push(v.content);
            });
            arr2.value = ans[i].answerList.map((v) => {
              return v.content;
            });
            if (
              JSON.stringify(arr1.value.sort()) ==
              JSON.stringify(arr2.value.sort())
            ) {
              boolVal.value = true;
            } else {
              boolVal.value = false;
            }
          } 
        }
      }

      if (data.value.length == endIndex.value) {
        ending.value = true;
        if (boolVal.value) {
          activerRight.value.push(data.value[endIndex.value - 1].id);
        } else {
          correct.value = true;
          activerErr.value.push(data.value[endIndex.value - 1].id);
        }
      } else {
        if (boolVal.value) {
          activerRight.value.push(data.value[endIndex.value - 1].id);
          nextID.value++;
          disabledSub.value = true;
        } else {
          correct.value = true;
          activerErr.value.push(data.value[endIndex.value - 1].id);
        }
      }
      let anserData = {
        qusionList: Object.values(qusionList.value),
        duration: route.query.duration,
        gradeConfId: route.query.gradeConfId,
        gradeId: route.query.gradeId,
        paperId: route.query.paperId,
        score: null,
        examType: 2
      };
      let isWrong
      if (route.query.type === 'wrongQs') {
        isWrong = 'endWrongAnswer'
      } else {
        isWrong = 'handPaper'
        http
          .post("/corporate/university/exam/" + isWrong, anserData)
          .then((res) => {
            if (res.data.code === 0) {
              stop()
            }
          });
      }
    };
    const endIndex = ref();
    const handleIndex = (val) => {
      endIndex.value = val;
    };
    // 结束按钮
    const handleEnd = () => {
      dialogVisible.value = true;
      if (qusionList.value) {
        if (data.value.length - Object.values(qusionList.value).length !== 0) {
          num.value = qusionList.value
            ? data.value.length - Object.values(qusionList.value).length
            : data.value.length;
          endType.value = 1;
        } else {
          num.value = activerErr.value ? activerErr.value.length : 0;
          endType.value = 2;
        }
      } else {
        num.value = data.value.length;
        endType.value = 3;
      }
      btnNormalText.value = endType.value==1?'退出':endType.value==2?'返回课程':'结束'
      btnPrimaryText.value = endType.value==2?'查看错题':'继续做题'
    };
    // 跳过
    const handleSkip = () => {
      if (data.value.length !== endIndex.value) {
        nextID.value++;
        correct.value = false;
        disabledSub.value = true;
        boolVal.value = true;
        num.value = Number(num.value) + 1;
        activer.value = nextID.value;
      }
    };

    // 题板点击
    const handleTab = (index, val) => {
      nextID.value = index;
      activer.value = nextID.value;
      if (activerRight.value.includes(val) || activerErr.value.includes(val)) {
        boolVal.value = false;
        correct.value = true;
      } else {
        boolVal.value = true;
        correct.value = false;
      }
      valIsRight.value = activerErr.value.includes(val)
        ? false
        : activerRight.value.includes(val)
        ? true
        : false;
    };
    var endTime = ''
    if (Number(route.query.duration) > 0) {
      // 倒计时
      let hr = "";
      let min = "";
      let sec = "";
      let date = new Date();
      let h = date.getHours();
      let m = date.getMinutes() + Number(route.query.duration);
      if (m > 60) {
        h = Number(h) + Math.floor(m / 60) + ":";
        m = Math.floor(m % 60);
      } else {
        h = date.getHours() + ":";
      }
      if(Number(route.query.duration) === 1) {
        timetext.value = 60
          var countdown1 = () => {
            if(timetext.value > 0) {
              timetext.value--
              setTimeout(function () {
                countdown1()
              }, 1000)
            } else {
              dialogVisible.value = true;
              endType.value = 2;
              btnNormalText.value = '返回课程'
              btnPrimaryText.value = '查看错题'
            }
          };countdown1()
      } else {
        endTime = Date.parse(new Date()) + Number(route.query.duration)*60*1000
        countdown();
      }
    } else {
      timetext.value = "不限时";
    }
    function countdown () {
      const end = endTime
      const now = Date.parse(new Date())
      const msec = end - now
      // 交卷时间
      if (msec < 0) return;
      let hrs = parseInt((msec / 1000 / 60 / 60) % 24);
      let mins = parseInt((msec / 1000 / 60) % 60);
      let secs = parseInt((msec / 1000) % 60);
      let hr = hrs ? (hrs > 9 ? hrs : "0" + hrs) : "00";
      let min = mins ? (mins > 9 ? mins : "0" + mins) : "00";
      let sec = secs ? (secs > 9 ? secs : "0" + secs) : "00";
      timetext.value = hr + ":" + min + ":" + sec;
      if (mins >= 0 && secs >= 0) {
        //倒计时结束交卷
        if (mins == 0 && secs == 0) {
          dialogVisible.value = true;
          endType.value = 2;
          btnNormalText.value = '返回课程'
          btnPrimaryText.value = '查看错题'
          return;
        }
        setTimeout(function () {
          countdown();
        }, 1000);
      }
    };
    // 获取数据
    const datas = {
      paperId: route.query.paperId,
      gradeId: route.query.gradeId,
      gradeConfId: route.query.gradeConfId,
      examType:2
    };
    if (route.query.type=='wrongQs') {
        http.post("/corporate/university/exam/listMyWrongQu",{gradeId:datas.gradeId}).then(res=>{
          if (res.data.data.length > 0) {
            data.value = res.data.data.map((v) => {
              loadingTrue.value = false
              if (v.answerList) {
                v.answerList = v.answerList.sort((x, y) => {
                  return x.opSeq - y.opSeq;
                });
              }
              return v;
            });
          } else {
            router.go(-1)
          }
        })
    }else{
      http.post("/corporate/university/exam/startExam", datas).then((res) => {
        loadingTrue.value = false
        if (res.data.code === 0) {
          data.value = res.data.data.map((v) => {
            if (v.answerList) {
              v.answerList = v.answerList.sort((x, y) => {
                return x.opSeq - y.opSeq;
              });
            }
            return v;
          });
        }
      });
  
       // 开始学习
      StartStudy({
        "bussId": route.query.paperId,
        "bussType": 2,//学习类型(1预习 2自测 3课件 4巩固 5考试)	
        "gradeConfId": route.query.gradeConfId,
        "gradeId": route.query.gradeId,//班级id	
        // "studyTime": 0
      }).then(res=>{
        if(res.data.code === 0){
          //this.details = res.data.data
        }
      })
    }
    const closeTest = () => {
      dialogVisible.value = true;
      endType.value = 1;
      btnNormalText.value = '退出'
      btnPrimaryText.value = '继续做题'
      num.value = qusionList.value
        ? data.value.length - Object.values(qusionList.value).length
        : data.value.length;
    };
    const wrongAnsOrHandPa = () => {
      let anserData = {
        qusionList: Object.values(qusionList.value),
        duration: route.query.duration,
        gradeConfId: route.query.gradeConfId,
        gradeId: route.query.gradeId,
        paperId: route.query.paperId,
        score: null,
        examType: 2
      };
      let isWrong
      if (route.query.type === 'wrongQs') {
        isWrong = 'endWrongAnswer'
      } else {
        isWrong = 'handPaper'
      }
      http
      .post("/corporate/university/exam/" + isWrong, anserData)
      .then((res) => {
        if (res.data.code === 0) {
          // if(btnPrimaryText.value != '查看错题') {
            router.go(-1);
          // }
          dialogVisible.value = false;
        }
      }).catch((e) => {});
    }
    const endStudyF = () => {
      EndStudy({
        "bussId": route.query.paperId,
        "bussType": 2,//学习类型(1预习 2自测 3课件 4巩固 5考试)	
        "gradeConfId": route.query.gradeConfId,
        "gradeId": route.query.gradeId,//班级id	
        // "studyTime": 0
      }).then(res=>{
        if(res.data.code === 0){
          //this.details = res.data.data
        }
      })
    }
    const handleCancel = () => {
      localStorage.removeItem('selfVal')
      if (qusionList.value) {
        wrongAnsOrHandPa()
      } else {
        router.go(-1);
        dialogVisible.value = false;
      }
      endStudyF()
    };
    const handleConfirm = () => {
      if(endType.value==2){
        router.push({ path: "/selftest", query:{ type:'wrongQs',gradeId:datas.gradeId } });
        if(btnPrimaryText.value == '查看错题') {
          wrongAnsOrHandPa()
          http.post("/corporate/university/exam/listMyWrongQu",{gradeId:datas.gradeId}).then(res=>{
            if (res.data.data.length > 0) {
              data.value = res.data.data.map((v) => {
                router.go(0)
              });
            } else {
              router.go(-1)
            }
          })
        } else {
          router.go(0)
        }
      }
      dialogVisible.value = false
    };
    onBeforeUnmount(() => {
      clearTimeout(countdown);
      countdown = null;
    });
    return {
      nextID,
      tabShow,
      loadingTrue,
      disabledSub,
      correct,
      endIndex,
      data,
      openText,
      dialogVisible,
      dialogTitle,
      num,
      bools,
      ending,
      endType,
      boolVal,
      arr1,
      arr2,
      timetext,
      activer,
      activerRight,
      activerErr,
      valIsRight,
      handleTab,
      handleNext,
      handleOpen,
      handleChange,
      handleSubmit,
      handleIndex,
      handleEnd,
      handleSkip,
      closeTest,
      handleCancel,
      handleConfirm,
      btnNormalText,
      btnPrimaryText,
      selfVal,
      wrongAnsOrHandPa,
      endStudyF,
    };
  },
};
</script>
<style lang="scss">
.selftest_loading{
  .el-dialog{
    width: 380px !important;
  }
}
</style>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  min-height: 100%;
  height: auto;
  background: #0b0c26;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
}
.main {
  width: 1200px;
  height: calc(100%-80px);
  margin: 80px auto 0 auto;
  border-top: 1px solid #2c2e4c;
  background: #0b0c26;
}

/deep/.el-radio,
/deep/.el-checkbox {
  color: white;
}
.bottom {
  width: 100%;
  padding-top: 40px;
  background: #0b0c26;
  // position: relative;
  top: 20px;
  .right_bottom {
    // position: absolute;
    margin-left: 44px;
    top: 0;
    left: 50%;
    .questionBoard{
      border-radius: 4px;
      border: 1px solid #747582;
      background: #0B0C26;
      color: #FFFFFF;
    }
    .time {
      padding: 0 50px 0 20px;
      color: #FFFFFF;
    }
  }
  .table {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    margin: 20px 0px;
    .table_item,
    .table_item > span {
      width: 44px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      display: inline-block;
    }
    .table_item{
      border: 1px solid #858693;
      margin-right: -1px;
    }
    .active_right {
      background: #00c456;
    }
    .active_err {
      background: #f04b4a;
    }
    .activer {
      background: #2d70f8;
      margin-left: -1px;
      margin-right: -1px;
    }
  }
}
</style>