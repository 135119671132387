<!-- 数据报告 -->
<template>
 <div>
    <Menu :menuTitle="title"/>
    <div class="report_wrap">
        <div class="content_top">
            <div>课程数据</div>
            <div>数据截止2020年11月20日</div>
        </div>
        <div class="curriculum_analysis"> 
            <dl>
                <dt>
                    <p class="data_top">{{dataObj.studyTime}}<span>h</span></p>
                    <p class="data_bottom">累计学习时长</p>
                </dt>
                <dd><img src="../../assets/images/icon_data_duration.png" alt=""/></dd>
            </dl>
            <dl>
                <dt>
                    <p class="data_top">{{dataObj.errorCount}}<span>个</span></p>
                    <p class="data_bottom">累计错题</p>
                </dt>
                <dd><img src="../../assets/images/icon_data_wrong.png" alt=""/></dd>
            </dl>
            <dl>
                <dt>
                    <p class="data_top">{{dataObj.avgScore}}<span>分</span></p>
                    <p class="data_bottom">课程平均分</p>
                </dt>
                <dd><img src="../../assets/images/icon_data_average.png" alt=""/></dd>
            </dl>
            <dl>
                <dt>
                    <p class="data_top">{{dataObj.standardRate}}<span>%</span></p>
                    <p class="data_bottom">合格率</p>
                </dt>
                <dd><img src="../../assets/images/icon_data_qualified.png" alt=""/></dd>
            </dl>
        </div>
        <div class="achievement_course">
            <dl>
                <dt><img src="../../assets/images/icon_data_excellent.png" alt="" style="margin-right:10px;"/>成绩优秀课程</dt>
                <dd v-for="(v,i) in classObj.goodCourseRank" :key="i">
                    <span class="className">{{v.name}}</span>
                    <span class="rank"><svg t="1629359222487" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3079" width="200" height="200"><path d="M845 189.7c-1-50.3-3-84.7-3-84.7H182s-2.4 34.3-3.7 84.7l-104.6 0.4 39.7 301.4 87.1 17.9c7.8 29 18.1 52.1 31.5 65.6 60.3 60.3 198.3 124.4 255 149.3v144.6H322v50h380v-50H537V724.2c57.2-25.5 197.3-91.4 255-149.1 13-13 22.9-36.1 30.5-65.4l88.1-18.1 39.7-301.4-105.3-0.5zM158.6 449.8l-28.4-209.9 47.3-0.2c-0.5 67.6 1.8 149.2 12.1 216.5l-31-6.4z m635.9-138.1c-1.9 60.4-6.3 111.2-13.1 150.8-10.5 61.5-23.4 75.8-24.8 77.2-25.9 25.9-80.6 60.6-154.1 98-36.8 18.7-70.6 34.1-90.6 43-19.8-8.6-52.8-23.4-89.2-41.7-74-37.3-127.7-71.6-155.4-99.2-3.5-3.5-34.1-39.4-39.2-229.1-1.6-59.9-0.2-117.3 1.4-155.5h564.6c1.2 38.4 2.3 96.3 0.4 156.5z m70.9 138.1l-32.4 6.6c10-67.2 12.6-149 12.5-216.8l48.2 0.2-28.3 210z" fill="#999999" p-id="3080"></path></svg>{{v.rank}}</span>
                </dd>
            </dl>
            <dl>
                <dt><img src="../../assets/images/icon_data_secondary.png" alt="" style="margin-right:10px;"/>成绩中等课程</dt>
                <dd v-for="(v,i) in classObj.midCourseRank" :key="i">
                    <span class="className">{{v.name}}</span>
                    <span class="rank"><svg t="1629359222487" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3079" width="200" height="200"><path d="M845 189.7c-1-50.3-3-84.7-3-84.7H182s-2.4 34.3-3.7 84.7l-104.6 0.4 39.7 301.4 87.1 17.9c7.8 29 18.1 52.1 31.5 65.6 60.3 60.3 198.3 124.4 255 149.3v144.6H322v50h380v-50H537V724.2c57.2-25.5 197.3-91.4 255-149.1 13-13 22.9-36.1 30.5-65.4l88.1-18.1 39.7-301.4-105.3-0.5zM158.6 449.8l-28.4-209.9 47.3-0.2c-0.5 67.6 1.8 149.2 12.1 216.5l-31-6.4z m635.9-138.1c-1.9 60.4-6.3 111.2-13.1 150.8-10.5 61.5-23.4 75.8-24.8 77.2-25.9 25.9-80.6 60.6-154.1 98-36.8 18.7-70.6 34.1-90.6 43-19.8-8.6-52.8-23.4-89.2-41.7-74-37.3-127.7-71.6-155.4-99.2-3.5-3.5-34.1-39.4-39.2-229.1-1.6-59.9-0.2-117.3 1.4-155.5h564.6c1.2 38.4 2.3 96.3 0.4 156.5z m70.9 138.1l-32.4 6.6c10-67.2 12.6-149 12.5-216.8l48.2 0.2-28.3 210z" fill="#999999" p-id="3080"></path></svg>{{v.rank}}</span>
                </dd>
            </dl>
            <dl>
                <dt><img src="../../assets/images/icon_data_bad.png" alt="" style="margin-right:10px;"/>成绩较差课程</dt>
                 <dd v-for="(v,i) in classObj.badCourseRank" :key="i">
                    <span class="className">{{v.name}}</span>
                    <span class="rank"><svg t="1629359222487" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3079" width="200" height="200"><path d="M845 189.7c-1-50.3-3-84.7-3-84.7H182s-2.4 34.3-3.7 84.7l-104.6 0.4 39.7 301.4 87.1 17.9c7.8 29 18.1 52.1 31.5 65.6 60.3 60.3 198.3 124.4 255 149.3v144.6H322v50h380v-50H537V724.2c57.2-25.5 197.3-91.4 255-149.1 13-13 22.9-36.1 30.5-65.4l88.1-18.1 39.7-301.4-105.3-0.5zM158.6 449.8l-28.4-209.9 47.3-0.2c-0.5 67.6 1.8 149.2 12.1 216.5l-31-6.4z m635.9-138.1c-1.9 60.4-6.3 111.2-13.1 150.8-10.5 61.5-23.4 75.8-24.8 77.2-25.9 25.9-80.6 60.6-154.1 98-36.8 18.7-70.6 34.1-90.6 43-19.8-8.6-52.8-23.4-89.2-41.7-74-37.3-127.7-71.6-155.4-99.2-3.5-3.5-34.1-39.4-39.2-229.1-1.6-59.9-0.2-117.3 1.4-155.5h564.6c1.2 38.4 2.3 96.3 0.4 156.5z m70.9 138.1l-32.4 6.6c10-67.2 12.6-149 12.5-216.8l48.2 0.2-28.3 210z" fill="#999999" p-id="3080"></path></svg>{{v.rank}}</span>
                </dd>
            </dl>
        </div>
        <div class="content_practice">
            <div>智能陪练数据</div>
            <div>数据截止2020年11月20日</div>
        </div>
        <div class="curriculum_analysis"> 
            <dl>
                <dt>
                    <p class="data_top">{{aiObj.trainTime}}<span>h</span></p>
                    <p class="data_bottom">累计学习时长</p>
                </dt>
                <dd><img src="../../assets/images/icon_data_duration.png" alt=""/></dd>
            </dl>
            <dl>
                <dt>
                    <p class="data_top">{{aiObj.trainCount}}<span>次</span></p>
                    <p class="data_bottom">累计训练次数</p>
                </dt>
                <dd><img src="../../assets/images/icon_data_number.png" alt=""/></dd>
            </dl>
            <dl>
                <dt>
                    <p class="data_top">{{aiObj.avgScore}}<span>分</span></p>
                    <p class="data_bottom">训练平均分</p>
                </dt>
                <dd><img src="../../assets/images/icon_data_mean.png" alt=""/></dd>
            </dl>
            <dl>
                <dt>
                    <p class="data_top">{{aiObj.rank}}<span>名</span></p>
                    <p class="data_bottom">本次排名</p>
                </dt>
                <dd><img src="../../assets/images/icon_data_qualified.png" alt=""/></dd>
            </dl>
        </div>
    </div>
    <div>

    </div>
 </div>
</template>

<script>
import Menu from '@/components/Menu'
import {GETANALYZER,GETRANK,GETAIANALYZER} from '@/server/details'
export default {
 components:{
    Menu
 },
 data () {
  return {
      title:'数据报告',
      dataObj:{},
      classObj:{},
      aiObj:{}
  }
 },
 created(){
    GETANALYZER().then(res=>{ //课程分析
        console.log(res.data);
        if(res.data.code == 0){
            this.dataObj = res.data.data
        }
    })
    GETRANK().then(res=>{
        console.log(res.data);
        if(res.data.code == 0){
            this.classObj = res.data.data
        }
    })
    GETAIANALYZER().then(res=>{
        console.log(res.data,'\\\\\\\\\\');
        if(res.data.code == 0) {
            this.aiObj = res.data.data
        }
    })
 },
 methods:{

 }
}

</script>

<style lang='scss' scoped>
.report_wrap{
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 80px;
    margin-top: 80px;
    .content_top {
        width: 100%;
        height: 76px;
        line-height: 76px;
        display: flex;
        justify-content: space-between;
        div {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333
        }
    }
    .content_practice {
        width: 100%;
        height: 76px;
        line-height: 76px;
        display: flex;
        justify-content: space-between;
        div {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333
        }
    }
    .curriculum_analysis {
        width: 100%;
        display: flex;
        dl {
            flex: 1;
            height: 140px;
            margin-right: 20px;
            background: #fff;
            display: flex;
            dd {
                width: 35%;
                text-align: center;
                line-height: 190px;
            }
            dt {
                flex: 1;
                .data_top {
                    height: 70%;
                    font-size: 46px;
                    padding-top: 26px;
                    padding-left: 20px;
                    box-sizing: border-box;
                    span {
                        font-size: 30px;
                    }
                }
                .data_bottom {
                    text-indent: 20px;
                    color: #999999;
                    font-size: 20px;
                }
            }
        }
        dl:last-child {
            margin-right: 0px;
        }
    }
    .achievement_course {
        width: 100%;
        display: flex;
        margin-top: 20px;
        dl {
            flex: 1;
            height: 180px; ///////////////////
            margin-right: 20px;
            background: #fff;
            padding-top: 20px;
            padding-left: 20px;
            dt {
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-bottom: 20px;
            }
            dd {
                width: 100%;
                padding: 0 28px;
                box-sizing: border-box;
                display: flex;
                font-size: 20px;
                justify-content: space-between;
                margin-bottom: 15px;
                .rank {
                    color:#999 ;
                    .icon {
                        width: 20px;
                        height: 20px;
                        color:#999 ;
                        position: relative;
                        top: 2px;
                        margin-right: 3px;
                    }
                }
            }
        }
        dl:last-child {
            margin-right: 0px;
        }
    }
}
</style>
