<!--  -->
<template>
  <div class="create_member_wrap">
    <el-dialog
      v-model="dialogVisible"
      :before-close="handleClose">
      <ul class="create_member_content">
        <li class="create_member_left">
          <div class="create_member_title">全部</div>
          <div class="create_member_con">
            <el-input v-model="inputName" placeholder="搜索人员" clearable @change="inputChange" class="search_input">
              <!-- <template #append>
                <el-button icon="el-icon-search" @click="inputChange"></el-button>
              </template> -->
            </el-input>
            <!-- <el-select
              style="margin-bottom:20px"
              v-model="selectValue"
              multiple
              filterable
              remote
              reserve-keyword
              placeholder="搜索人员"
              @change="selectChange"
              ref="selectList"
              @focus="selectBlur"
              :remote-method="remoteMethod"
              :loading="loading">
              <el-option
               style="height: 60px;
                    line-height: 1!important;
                    padding:0 10px"
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item">
                <p style="margin: 10px; 0">
                  <svg class="icon-svg" aria-hidden="true" style="color:#84B0FF;margin-right:10px;">
                    <use xlink:href="#icon_duties"></use>
                  </svg>{{item.label}}</p>
                  <p style="color: #999;margin-left: 20px;">{{item.extra}}</p>
              </el-option>
            </el-select> -->
            <div class="create_member_tree_wrap">
              <el-tree
              style="margin-top:20px"
              v-show="isSeach == false"
              :props="props"
                :data="data"
                :load="loadNode"
                node-key="nodeId"
                lazy
                ref="tree"
                @check="handleCheck">
                <template #default="{ data }">
                <div class="custom-tree-node" >
                  <el-checkbox-group
                    v-if="data.nodeType === 4"
                    v-model="chooseLists">
                    <el-checkbox @change="() => updateChoose(data)" :label="data" :checked="checkedKeys.includes(data.nodeId)">{{""}}</el-checkbox>
                  </el-checkbox-group>
                  <svg class="icon-svg" aria-hidden="true" style="color:#84B0FF;margin-right:10px;">
                    <use xlink:href="#icon_institution" v-if="data.nodeType === 2 && data.nodeId === '1'"></use>
                    <use xlink:href="#icon_equally" v-if="data.nodeType === 2 && data.nodeId === '2'"></use>
                    <use xlink:href="#icon_company" v-if="data.nodeType === 2 && data.nodeId === '3'"></use>
                    <use xlink:href="#icon_supply" v-if="data.nodeType === 2 && data.nodeId === '4'"></use>
                    <use xlink:href="#icon_zu" v-if="data.nodeType === 2  && data.nodeId !== '1' && data.nodeId !== '2'&& data.nodeId !== '3'&& data.nodeId !== '4' "></use>
                    <use xlink:href="#icon_duties" v-else-if='data.nodeType === 3'></use>
                  </svg>
                  <span>{{ data.nodeName }}</span>
                </div>
                </template>
              </el-tree>
              <div style="margin-top:20px" v-show="isSeach == true">
                <el-checkbox-group v-model="chooseList" @change="checkboxChange" class="seachBox" style="width:90%">
                  <template v-for="v, in options"
                      :key="v.nodeId">
                    <el-checkbox
                      class="checkBox"
                      style="position: relative;left:10px;display:block"
                      
                      :checked="checkedKeys.includes(v.nodeId)"
                      v-if="checkedKeys.includes(v.nodeId) == false"
                      :label="v">
                      <!-- :style="checkedKeys.includes(v.nodeId)?'display:none':''" -->

                      <div style="position: relative; top: 20px;margin-bottom: 10px;">
                        <svg class="icon-svg" aria-hidden="true" style="color:#84B0FF;margin-right:10px;">
                          <use xlink:href="#icon_duties"></use>
                        </svg>
                        {{v.nodeName}}<br />
                        <span style="margin:5px 0 0 20px;">{{v.jobName}}</span>
                        
                      </div>
                        
                    </el-checkbox>
                  </template>
                  
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </li>
        <!-- :default-checked-keys="checkedKeys" -->
        <li class="create_member_right" >
          <div class="create_member_title" style="display: flex;justify-content: space-between;">
            <span>已选人员（{{ memberNumber }}项）</span>
            <span style="margin-right:20px;color:#3a8ee6" @click="clearAll">清空</span></div>
          <div class="create_member_con">
            <!-- <el-input class="create_member_search" prefix-icon="el-icon-search"/> -->
            <div class="create_member_choose_wrap">
              <ul class="create_member_choose">
                <li
                  v-for="(item, i) in chooseList"
                  :key="item.nodeId">
                  <span>
                    <svg class="icon-svg" aria-hidden="true" style="color: #84B0FF;margin-right:17px;">
                      <use xlink:href="#icon_user"></use>
                    </svg>

                    {{ item.nodeName }}
                  </span>
                  <i class="el-icon-close" @click="() => deleteItem(item,i)"></i>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
      
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleClick">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import http from "@/utils/request";
export default {
  data () {
    return {
      dialogVisible: false,
      props: {
        label: 'nodeName',
        children: 'children',
        isLeaf:(data,node) =>{
          // console.log(data)
          if(data.nodeType===4){  // 根据需要进行条件判断添加is-leaf类名
            return  true
         }
        }
      },
      chooseDataObj: {},
      query: {
        nodeType: 2,
        organId: 0,
        roleName: "",
        username: ""
      },
      peopleQuery: {
        limit: 100,
        nodeType: 0,
        organId: 0,
        page: 1
      },
      chooseLists: [],
      chooseList: [],
      options: [],
      selectValue: [],
      loading: false,
      states: [],
      loadList:[],
      isSeach:false,
      chooseListObj:[],
      inputName:'',
    };
  },
  props: ['visible', 'type', 'checkedList'],
  computed: {
    checkedKeys() {
      const arr = []
      for (let id in this.chooseDataObj) {
        arr.push(String(id))
      }

      return arr;
    },
    memberNumber() {
      return this.chooseList.length
    }
  },
  watch: {
    visible() {
      this.dialogVisible = this.visible
    },
    chooseDataObj: {
      handler () {
        const chooseDataObj = this.chooseDataObj

        const arr = []
        for (let key in chooseDataObj) {
          arr.push(chooseDataObj[key])
        }

        this.chooseList = arr;
      },
      deep: true
    },
    inputName (val) {
      // console.log(val)
      if (this.inputName !== '') {
        this.loadAll()
      } else {
        this.isSeach = false
      }
    }
  },
  methods: {
    clearAll(){
      this.chooseList = []
      this.selectValue = ''
    },
    inputChange(e){
      console.log(e)
      console.log(this.checkedList)
      if(this.inputName !== ''){
          this.loadAll()
        }else{
          this.isSeach = false
        }
    },
    checkboxChange(e){
      // console.log(e)
      // console.log(this.chooseListObj)
      // this.chooseList = e
      const chooseDataObj = {}
      let arr = e.concat(this.checkedList)
      // console.log(arr)
      this.chooseList = arr
      arr.forEach(v => {
        chooseDataObj[v.nodeId] = v
      })
      
      this.chooseDataObj = chooseDataObj
    },
    remoteMethod(query) {
      console.log(query)
        if (query !== '') {
          this.loadAll(query)
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.options.filter(item => {
              return item.label
            });
          }, 200);
        } else {
          this.options = [];
        }
      },
    loadAll(){
      http.post('/corporate/university/organ/sysemployee/search', {
        username: this.inputName,
        // nodeType: 3
      }).then(({ data }) => {
        // console.log(data)
        if (data.code === 0) {
          let list = data.data.list
          let arrList = []
          for (let i = 0; i < list.length; i++) {
            let obj = {}
            obj.nodeId = list[i].employeeId
            obj.nodeName = list[i].username
            obj.jobName = list[i].jobName
            arrList.push(obj)
          }
          // console.log(arrList)
          // console.log(this.checkedList)
          let arr = this.uniqueArr(this.checkedList.concat(arrList))
          let arr1 = []
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].jobName) {
              arr1.push(arr[i])
            }
          }
          // console.log(arr1)
          this.options = arr1
          //  this.options = data.data
          this.isSeach = true
        } else {
          this.$message.error(data.data.msg)
        }
      })
    },
    selectChange(e){
        console.log(e)
        this.chooseList = []
        this.chooseList = this.uniqueArr(this.chooseList.concat(e))
        this.$refs.selectList.$refs.input.blur = () => {
          this.selectValue = ''
        }
    },
    selectBlur(e){
      console.log(e)
    },
    uniqueArr(arr1) {
      for(var i=0; i<arr1.length; i++){
          for(var j=i+1; j<arr1.length; j++){
              if(arr1[i].nodeId==arr1[j].nodeId){         //第一个等同于第二个，splice方法删除第二个
                  arr1.splice(j,1);
                  j--;
              }
          }
      }
      console.log(arr1)
      return arr1;
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('close')
    },

    // 
    handleCheck(data, { checkedNodes }) {
      if (data.nodeType === 4) {
        const chooseDataObj = {}
        
        checkedNodes.forEach(v => {
          chooseDataObj[v.nodeId] = v
        })
      }
    },

    // 点击确定
    handleClick() {
      // if (this.type === 1 && this.chooseList.length > 1) {
      //   this.$message.warning('班主任只可选择一人！请重新选择')
      //   return 
      // }
      // if (this.type === 1 && this.chooseList.length == 0) {
      //   this.$message.warning('班主任不能为空！')
      //   return
      // }
      this.$emit('success', this.chooseList)
    },

    // 选择 / 取消复选框
    updateChoose() {
      const chooseDataObj = {}
      this.chooseLists.forEach(v => {
        chooseDataObj[v.nodeId] = v
      })

      this.chooseDataObj = chooseDataObj
      // console.log(this.chooseDataObj)
    },

    // 取消已选择成员 - 右侧已选择删除按钮
    // 通过 nodeId 改变 chooseDataObj 对象，修改 chooseLists 数组
    deleteItem({ nodeId },index) {
      // console.log(index)
      const arr = []
      for (let key in this.chooseDataObj) {
        arr.push(this.chooseDataObj[key])
      }
      arr.splice(index,1)
      const chooseDataObj = {}
      arr.forEach(v => {
        // console.log(v)
        chooseDataObj[v.nodeId] = v
      })

      this.chooseDataObj = chooseDataObj
      this.chooseLists = arr
    },

    // 加载节点
    async loadNode(node, resolve) {

      const { key, data } = node
      
      if (node.level === 0) {
        this.query.nodeType = 2
        const res = await this.getTreeData()
        
        return resolve(res.data.data)
      } else {
        this.query.organId = key
        this.query.nodeType = data.nodeType

        if (data.nodeType === 4) return resolve([])

        const res = await this.getTreeData()

        return resolve(res.data.data)
      }
    },

    // 获取节点数据
    getTreeData() {
      return http.post('/corporate/university/organ/organList', this.query)
    },
  },

  created() {
    this.dialogVisible = this.visible
    
    if (this.checkedList.length) {
      const chooseDataObj = {}
      const arr = []
      // 初始化已选择的数据
      this.checkedList.forEach(({ nodeName, nodeId }) => {
        const id = String(nodeId)
        const obj =  { nodeName, nodeId: id }
        
        chooseDataObj[id] = obj
        arr.push(obj)
      })
      
      this.chooseLists = arr
      this.chooseDataObj = chooseDataObj
    }
    this.getTreeData()
  },
}

</script>
<style lang='scss'>
@mixin border($value: 1px, $lineType: solid, $color: #E6E6E6) {
  border: $value $lineType $color;
}

@mixin border_item ($border: left, $value: 1px, $lineType: solid, $color: #E6E6E6) {
  border-#{$border}: $value $lineType $color;
}

$bg_grey: #F8F8F8; // 灰色背景色

.create_member_wrap {
  .el-dialog {
    width: 895px !important;
    height: 704px;
    position: relative;
    padding-bottom: 90px;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0 !important;
    height: 100%;
  }

  .create_member_content {
    display: flex;
    width: 100%;
    height: 100%;
    @include border_item(bottom);

    & > li {
      flex: 1;
    }

    & > li:first-child {
      @include border_item(right);
    }

    .create_member_title {
      height: 76px;
      background-color: $bg_grey;
      line-height: 76px;
      font-size: 18px;
      padding-left: 24px;
    }
  }

  .create_member_con {
    padding: 24px;
  }

  .create_member_search {
    margin-bottom: 24px;
  }

  .create_member_left {
    .el-tree-node__content {
      position: relative;
      margin-bottom: 16px;
      height: 28px;
    }

    .el-checkbox {
      position: absolute;
      right: 10px;
    }

    .is-focusable .el-tree-node__expand-icon{
      &::before {
        // display: none;
      }
    }
    .el-tree-node__expand-icon.expanded {
      transform: rotate(0);
    }

    .is-leaf.el-tree-node__expand-icon {
      &::before {
        display: none;
      }
    }
  }

  .create_member_tree_wrap,
  .create_member_choose_wrap {
    height: 440px;
    overflow-y: auto;
  }

  .create_member_right {
    .el-checkbox {
      display: block;
      height: 28px;
      margin-bottom: 16px;
    }
  }

  .create_member_choose {
    & > li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      padding-right: 20px;
    }
  }

  .el-dialog__footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding-bottom: 40px;
  }
}
.active{
  display: none!important;
}
</style>