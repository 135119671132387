<!--  -->
<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="form"
    label-width="120px"
    class="create_form_wrap">
    <el-form-item label="班级名称" prop="gradeName">
      <el-input v-model="form.gradeName" placeholder="请输入"></el-input>
    </el-form-item>

    <el-form-item label="班级属性" prop="gradeConfId">
      <el-select
        v-model="form.gradeConfId"
        placeholder="请选择班级属性"
        clearable
        :disabled="isDisabled">
        <el-option
        style="width: 480px;margin-left: 20px;"
          v-for="item in gradeConfList"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>


    <el-form-item label="班级讲师：" prop="teaBatchList" >
      <el-form-item class="create_form_member">
        <el-input  v-model="form.teaBatchListStr" placeholder="请选择班级讲师" />
        <el-button class="create_form_member_btn" @click="() => chooseMember(2)"></el-button>
      </el-form-item>
    </el-form-item>
    
    <el-form-item label="开班时间" prop="openGradeTime" class="create_class_time">
      <el-date-picker
        v-model="form.openGradeTime"
        type="date"
        :picker-options="pickerOptions" 
        :disabled="disabled"
        placeholder="请选择开始时间">
      </el-date-picker>
    </el-form-item>

    <el-form-item label="结束时间" prop="endGradeTime" class="create_class_time">
      <el-date-picker
        v-model="form.endGradeTime"
        type="date"
        placeholder="请选择结束时间">
      </el-date-picker>
    </el-form-item>

    <el-form-item label="班级成员" prop="batchAddDTOS">
      <el-form-item class="create_form_member">
        <el-input v-model="form.stuBatchListStr" placeholder="请选择班级成员"/>
        <el-button class="create_form_member_btn" @click="() => chooseMember(3)"  />
      </el-form-item>
    </el-form-item>

    <el-form-item class="create_form_btns">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="onSubmit">确认</el-button>
    </el-form-item>

  </el-form>
  <SelectMember 
    v-if="visible"
    :visible="visible"
    :type="type"
    :checkedList="checkedList"
    @success="getMember"
    @close="close"
  
  />
</template>

<script>
import SelectMember from '@/components/SelectMember'
import {
  getClassAttr,
  getClassDetail,
  GradeUpdate
} from '@/server/class';
import {
  GETCLASSDETAIL,
  CheckStatusClass
}from '@/server/details';
import http from "@/utils/request";
const getDate = (time) => {
  const newTime = new Date(time);
  const y = newTime.getFullYear();
  const m = newTime.getMonth();
  const d = newTime.getDate();
  return `${y}.${m + 1}.${d}`;
}

const getStr = (data = [], key = 'nodeName') => {
  let str = ''
  if (data) {
    if (data.length > 5) {
      str = data.slice(0, 5).map(item => item[key]).join('、')
      str += `...等${data.length}人`
    } else {  
      str = data.map(item => item[key]).join('、')
    }
    
  }
  

  return str
}
export default {
  data () {
    return {
      name: '',
      form: {
        masterName: '',
        gradeConfId: '',
        gradeConfName: '',
        gradeName: '',
        masterId: '',
        endGradeTime: '',
        openGradeTime: '',
        stuBatchList: [],
        stuBatchListStr: '',
        teaBatchList: [],
        teaBatchListStr: '',
      },
      rules: {
        gradeName: [{ required: true, message: '请输入班级名称', trigger: 'blur' }],
        masterName: [{ required: true, message: '请选择班主任', trigger: 'blur' }],
        openGradeTime: [{ required: true, message: '请选择开班时间', trigger: 'blur' }],
        endGradeTime: [{ required: true, message: '请选择结束时间', trigger: 'blur' }],
      },
      gradeConfList: [],
      gradeConfObj: {},
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      endPickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      visible: false,
      type: 1,
      gradeId: '',
      title: '创建班级',
      disabled: false,
      checkedList: [],
      isDisabled:false
    };
  },

  components: {
    SelectMember
  },

  methods: {
    cancel() {
      this.$router.go(-1)
    },
    close() {
      this.visible = false
    },
    chooseMember(type) {
      this.visible = true
      this.type = type
      const key = {
        2: 'teaBatchList',
        3: 'stuBatchList'
      }

      if (type === 1) {
        if (this.form.masterId) {
          this.checkedList = [{
            nodeId: this.form.masterId,
            nodeName: this.form.masterName
          }]
        }
      } else {
        this.checkedList = this.form[key[type]]
      }
    },
    // 创建班级 / 修改班级信息
    onSubmit() {
      console.log(this.form.openGradeTime)
      var myDate = new Date();
      let todayTime = myDate.getFullYear() + '-' + (myDate.getMonth()+ 1) + '-' + myDate.getDate()
      let toTime = new Date(todayTime).getTime()
      if(this.disabled == false){
        if(new Date(this.form.openGradeTime).getTime() == toTime){
          // console.log('111')
          this.$message.warning('只能选择今天往后的日期！')
          return
        }
      }
      
      this.$refs.form.validate(async valid => {
        if (valid) {
          const { masterName, masterId, gradeName, openGradeTime, endGradeTime, gradeConfId, teaBatchList, stuBatchList, id } = this.form
          const time = {
            openGradeTime: new Date(openGradeTime).getTime(),
            endGradeTime: new Date(endGradeTime).getTime(),
          }
          const data = {
            masterName, masterId, gradeName, 
            openGradeTime: getDate(openGradeTime), endGradeTime: getDate(endGradeTime),
            gradeConfId,
          }

          if (time.openGradeTime === time.endGradeTime) {
            this.$message.warning('请选择正确的时间，开班时间和结束时间不能重合')
            return
          }

          if (time.openGradeTime > time.endGradeTime) {
            this.$message.warning('请选择正确的时间，结束时间需大于开班时间')
            return
          }

          data.gradeConfName = this.gradeConfObj[gradeConfId]
          data.teaBatchList = teaBatchList.map(({ nodeId }) => nodeId)
          data.stuBatchList = stuBatchList.map(({ nodeId }) => nodeId)
          
          if(id) data.id = id

          const path = '/corporate/university/grade/update'
          http.post(path, data).then((res) => {
              if (res.data.code === 0) {
                this.$message.success('修改成功!')
                this.$router.go(-1)
              } else {
                this.$message.error(res.data.msg)
              }
            }).catch((data) => {
              // console.log(data)
              this.$message.error(data.data.msg);
            })
          
        }
      })
    },
    getMember(data) {
      this.close()
      const type = this.type
      if (type === 1) {
        this.form.masterName = data[0].nodeName
        this.form.masterId = data[0].nodeId
      }

      if (type === 2 || type === 3) {
        const key = type === 2 ? 'teaBatchList' : 'stuBatchList'

        this.form[key] = data

        if (type === 2) {
          this.form.teaBatchListStr = getStr(data)
        } else {  
          this.form.stuBatchListStr = getStr(data)
        }
      }
      
    },
    // 获取课程详情
    getDetail() {
      GETCLASSDETAIL({ gradeId: this.gradeId })
        .then(({ data }) => {
          if (data.code === 0) {
            const { 
              teaLists, 
              stuLists, 
              gradeConfName, 
              gradeConfId, gradeName,
              masterId, masterName,
              openGradeTime, endGradeTime,
              id
            } = data.data
            const teaBatchList = teaLists ? teaLists.map(({ teaName, teaId }) => ({ nodeName: teaName, nodeId: teaId })) : []
            const stuBatchList = stuLists ? stuLists.map(({ stuName, stuId }) => ({ nodeName: stuName, nodeId: stuId })) : []

            this.form = {
              teaBatchList, 
              stuBatchList, 
              gradeConfName, 
              gradeConfId, gradeName,
              masterId, masterName,
              openGradeTime: new Date(openGradeTime),
              endGradeTime: new Date(endGradeTime),
              id, 
              stuBatchListStr: getStr(stuBatchList),
              teaBatchListStr: getStr(teaBatchList),
            }
            console.log(this.form.gradeConfId)
            if(this.form.gradeConfId !== null){
              console.log('1111')
              this.isDisabled = true
            }
          }
        })
    },
    check() {
      CheckStatusClass({gradeId:this.gradeId}).then(({ data }) => {
          if (data.code === 0) {
            this.disabled = data.data
          } else {
            this.$message.error(data.msg)
          }
        })
    },
    getClassAttr() {
      getClassAttr().then(({ data }) => {
        if (data.code === 0) {
          const gradeConfObj = {}
            const list = data.data

            list.forEach(({ id, name }) => {
              gradeConfObj[id] = name
            })

            this.gradeConfObj = gradeConfObj
            this.gradeConfList = list
        }
      })
    }
  },
  created() {
    this.gradeId = this.$route.query.id
   
    this.getDetail()
    this.check()
    this.getClassAttr()
     
  }
}

</script>
<style lang='scss' scoped>
.create_form_wrap {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 590px;
  margin: 0 auto;

  /deep/ .el-input {
    line-height: 54px;
  } 

  /deep/ .el-input__inner {
    width: 500px;
    border-radius: 0;
    padding: 0 20px;
    height: 54px;
    line-height: 54px;
    font-size:16px;

    &::placeholder {
      font-size:16px;
    }
  }

  /deep/ .el-form-item__label {
    line-height: 54px;
    font-size:16px;
  }

  /deep/ .el-form-item {
    margin-bottom: 24px;
  }

  /deep/ .el-input__prefix .el-icon-date {
    display: none;
  }

  /deep/ .el-select .el-input .el-select__caret {
    line-height: 54px;
  }

  /deep/ .el-select-v2__wrapper {
    height: 54px;
    font-size:16px;
    border-radius: 0;
  }

  /deep/ .el-date-editor.el-input,
  /deep/ .el-date-editor.el-input__inner {
    width: 500px;
  }

  .create_form_btns {
    text-align: center;
    margin-top: 40px;

    .el-button {
      width: 120px;
      height: 46px;
      border-radius: 4px;
      font-size:16px;
      padding: 0;
    }

    .el-button:last-child {
      margin-left: 30px;
    }
  }

  .create_form_member {
    position: relative;
    margin-bottom: 0;

    /deep/ .create_form_member_btn {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      background: none;
    }
  }
  .create_class_time {
    /deep/ .el-input__prefix {
      right: 5px;
      left: unset;
    }
  }
}
</style>
