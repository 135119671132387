<template>
  <div class="wrap">
    <Menu menuTitle="回顾考题" @close='close'/>
    <div class="main" v-if='data.gradeQus'>
      <div v-for="(v, i) in data.gradeQus" :key="i">
        <div v-if="i == nextID">
          <Single
            v-if="v.type === 0"
            :keys="[v.questionsPic?1:2]"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.gradeQus.length"
            :itemIndex="i + 1"
            :reverse="true"
            :review="true"
          ></Single>
          <Multiple
            v-if="v.type === 1"
            :keys="[v.questionsPic?1:2]"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.gradeQus.length"
            :itemIndex="i + 1"
            :reverse="true"
            :review="true"
          ></Multiple>
          <Judge
            v-if="v.type === 2"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.gradeQus.length"
            :itemIndex="i + 1"
            :reverse="true"
            :review="true"
          ></Judge>
          <Completion
            v-if="v.type === 3"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.gradeQus.length"
            :itemIndex="i + 1"
            :reverse="true"
            :review="true"
          ></Completion>
          <Answer
            v-if="v.type === 4"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.gradeQus.length"
            :itemIndex="i + 1"
            :reverse="true"
            :review="true"
          ></Answer>
          <Sort
            v-if="v.type === 6"
            :keys="[v.questionsPic?1:2]"
            @handleIndex="handleIndex"
            :correct="correct"
            :item="v"
            :dataLen="data.gradeQus.length"
            :itemIndex="i + 1"
            :reverse="true"
            :review="true"
          ></Sort>
          <!-- <div class="topicBoard">
            <ul>
              <li>1</li>
              <li>1</li>
              <li>1</li>
            </ul>
          </div> -->
          
        </div>
      </div>
      <div class="bottom">
        <div class="right_bottom">
        <el-button
          size="mini"
          type="primary"
          v-if="endIndex !== data.gradeQus.length"
          @click="handleNext">下一个</el-button>
        <el-button
          size="mini"
          type="primary"
          v-if="endIndex === data.gradeQus.length"
          @click="handleEnd"
          >结束</el-button>
        <el-button size="mini" @click="handleOpen">{{ openText }}</el-button>
        <el-button v-if="isTeacher" size="mini" style="margin-left:256px" type="warning" @click="handleOpenDetails">答题详情</el-button>
      </div>
      <div class="table" v-show="tabShow">
        <p class="table_item" v-for="(v,i) in data.gradeQus" :key="i" @click="handleTab(i)"
        :class="[activer ==  i?'activer':'']">
        <span v-if="v.studentAnswerRelas !== null" :class="[v.isAnswerRight?'active_right':'active_err']">{{ i+1 }}</span>
        <span v-else>{{ i+1 }}</span></p>
      </div>
      <!-- 答题详情11 -->
      <div v-if="isTeacher && showDetails" class="table">
        <answer-details :visivle="showDetails" :endIndex="endIndex" :list="data.gradeQus"/>  
      </div>
      </div>


      
    </div>
    
  </div>
</template>

<script>
import Single from "@/components/AnswerComponents/single.vue"; // 单选
import Multiple from "@/components/AnswerComponents/multiple.vue"; // 多选
import Judge from "@/components/AnswerComponents/judge.vue"; // 判断
import Completion from "@/components/AnswerComponents/completion.vue"; // 填空
import Sort from "@/components/AnswerComponents/sort.vue"; // 排序
import Answer from "@/components/AnswerComponents/answer.vue"; // 简答
import AnswerDetails from "./components/AnswerDetails"; // 答题详情
import Menu from '@/components/Menu'
import http from "@/utils/request";
import { getRole } from '@/utils/utils'
import { ref } from "vue";
import {useRoute} from 'vue-router';
export default {
  props: {
    type: Number,
  },
  components: { Single, Multiple, Judge, Completion, Sort, Answer,Menu , AnswerDetails},
  setup() {
    const nextID = ref(0);
    const tabShow = ref(false);
    const correct = ref(false);
    const data = ref([]);
    const openText = ref("展开题板");
    const qusionList = ref()
    const endType = ref()
    const route = useRoute()
    const activer = ref(0)
    const answer = ref([])
    const showDetails = ref(false)
    const isTeacher = ref(getRole() === 'teacher')
    // 下一个
    const handleNext = () => {
      nextID.value++;
      correct.value = false;
      activer.value=nextID.value;
      showDetails.value = false;
    };

    const handleOpenDetails = () => {
      showDetails.value = !showDetails.value
    }
    // 题板
    const handleOpen = () => {
      tabShow.value = !tabShow.value;
      openText.value = tabShow.value ? "收起题板" : "展开题板";
    };
    const endIndex = ref();
    const handleIndex = (val) => {
      
      endIndex.value = val;
      if( endType ){
        endType.value = data.value.gradeQus[val-1].type;
      }
    };
    // 结束按钮
    const handleEnd = () =>{
      if(data.value.length - Object.values(qusionList.value).length !== 0){
        endType.value = 3
      }else{
        endType.value = 2
      }
    }

    // 题板点击
    const handleTab = (val) => {
      nextID.value = val;
      activer.value = nextID.value
    };
    // 获取数据
    // const datas = { paperId: route.query.paperId, gradeId: route.query.gradeId, gradeConfId: route.query.gradeConfId };
    // http.post("/corporate/university/exam/testReview", datas).then((res) => {
    //   if (res.data.code === 0) {
    //     // data.value = res.data.data
    //     data.value = [{}]
    //   }
    // });
    const datas = { 
      "paperId": route.query.paperId,//'1430498430026297346',//this.$route.paperId,
      "quId": route.query.quId//'1430498430290538497'//this.$route.quId
    };

    
    http.post("/corporate/university/teacher/exam/viewTest", datas).then((res) => {
      if (res.data.code === 0) {
        data.value = res.data.data
        endIndex.value = res.data.data.gradeQus.length-1;

        handleTab(route.query.curQesNum);

      }
    });
    return {
      nextID,
      isTeacher,
      showDetails,
      tabShow,
      correct,
      endIndex,
      data,
      openText,
      endType,
      activer,
      answer,
      handleTab,
      handleNext,
      handleOpen,
      handleIndex,
      handleEnd,
      handleOpenDetails
    };
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
}
.main{
  width: 100%;
  height: calc(100%-80px);
  background: white;
  margin-top: 80px;
}
.bottom{
  position: absolute;
  left: 100px;
  margin-top: 20px;
  .right_bottom {
    margin-left: 200px;
    .time {
      padding: 0 50px 0 20px;
      // color: red;
    }
  }
.table {
  display: flex;
  flex-wrap: wrap;
  width: 1100px;
  margin: 20px 100px;
  .table_item,.table_item>span {
    width: 44px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: inline-block; 
  }
  .table_item{
    color: #000;
    border: 1px solid #CCCCCC;
  }
  .activer{
    background: #2D70F8;
    color: white;
  }
  .active_right{
    background: green;
    color:white
  }
  .active_err{
    // background: red;
    // color:white
  }
}
}
// .topicBoard{
//   width: 1920px;
//   margin:auto;
//   li{
//     width: 20px;
//     height: 20px;
//     line-height: 20px;
//     text-align: center;
//     float: left;
//     border: 1px solid #CCCCCC;
//   }
// }

</style>
