<template>
  <div class="infoPage">
    <el-timeline class="class_info_wrap">
      <div v-if="type === 'curriculum'">
        <el-timeline-item v-for="item in list" :key="'list1' + item.id">
          <div class="class_info_left">
            <span
              class="time"
              style="font-size: 16px; line-height: 28px; margin-bottom: 4px"
              >{{ item.timeRange }}</span
            >
            <!-- <h2 class="nameDAY" style="margin-bottom:4px;">
              <el-tooltip class="item" effect="dark" :content="item.courseName" placement="top-start">
                <p style="font-size:16px;line-height: 28px;">{{item.courseName}}</p>
              </el-tooltip>
            </h2> -->
            <!-- <span class="name" v-if="item.inClass === 0 || item.inClass === 1" style="font-size:16px;line-height: 28px;color:#2D70F8;">{{ item.teaName }}</span>
            <span class="name" v-else style="font-size:16px;line-height: 28px;color:#999">{{ item.teaName }}</span> -->
            <!-- <span class="name" v-if="item.inClass === 0" style="color:red;margin-top:4px;">未上课</span> -->
            <!-- <span class="name" v-if="item.inClass === 1" style="color:red;margin-top:4px;">上课中<img src="../../../../assets/images/classing.png" style="margin-left:5px"></span> -->
          </div>
          <div class="class_info_right_box">
            <div class="class_title_name">
              <span class="class_title">{{
                item.courseName.substring(0, 20)
              }}</span>
              <span
                class="class_name"
                v-if="item.inClass === 0 || item.inClass === 1"
                >{{ item.teaName }}</span
              >
              <span class="class_name" v-else>{{ item.teaName }}</span>
              <span class="name" v-if="item.inClass === 1" style="color: red"
                >上课中
                <img src="../../../../assets/images/classing.png" />
              </span>
            </div>
            <div
              class="class_info_right"
              v-for="(v, i) in item.resRepoList"
              :key="'list2' + i"
            >
              <!-- 预习 -->
              <div>
                <span class="tag tag_1" v-if="v.fileType === 1"></span>
                <span class="tag tag_2" v-if="v.fileType === 2"></span>
                <span class="tag tag_3" v-if="v.fileType === 3"></span>
                <span class="tag tag_4" v-if="v.fileType === 4"></span>
                <span class="tag tag_5" v-if="v.fileType === 5"></span>
                <span class="tag tag_6" v-if="v.fileType === 6"></span>
                <ul class="class_info_item">
                  <li
                    v-for="o in v.dataList"
                    :key="'list3' + o.id"
                    :style="
                      item.inClass === 0 || item.inClass === 1
                        ? 'color:#333'
                        : 'color:#999'
                    "
                  >
                    <span v-if="v.fileType <= 3" class="nameTitle">
                      {{ o.name }}
                    </span>
                    <span v-if="v.fileType > 3" class="nameTitle">
                      {{ o.name }}
                      <span style="color: #999; margin-left: 3px"
                        >{{ o.totalQu > 0 ? `${o.totalQu}个` : "" }}/{{
                          o.duration > 0 ? `${o.duration}分钟` : "不限时间"
                        }}</span
                      >
                    </span>
                    <span class="class_info_item_btns_outline">
                      <span
                        @click="seeView(1, o, v.fileType)"
                        v-if="
                          o.resRepoStatus &&
                          v.fileType !== 2 &&
                          v.fileType !== 4 &&
                          v.fileType !== 5 &&
                          v.fileType !== 6
                        "
                        style="
                          color: #000;
                          font-size: 16px;
                          width: 64px;
                          line-height: 34px;
                          background-color: #2d70f8;
                          color: #fff;
                          border-radius: 4px;
                          text-align: center;
                        "
                        >查看</span
                      >
                      <span v-else style="width: 64px"></span>
                      <!-- 自测 -->
                      <!-- <span v-if="!o.resRepoStatus && v.fileType === 2" style='color:#2D70F8;' @click="handleOpenTest(o,item.courseConfId)">查看</span> -->
                      <!-- 巩固和考试 -->
                      <span
                        v-if="
                          (v.fileType == 4 ||
                            v.fileType == 5 ||
                            v.fileType == 6) &&
                          o.resRepoStatus !== 0 &&
                          o.resRepoStatus !== 1
                        "
                        style="
                          color: #999;
                          width: 18px;
                          margin-left: -18px;
                          position: relative;
                          left: 18px;
                          line-height: 34px;
                        "
                        @click="
                          goExaminationRecord(item.courseConfId, o, v.fileType)
                        "
                      >
                        <i
                          v-if="o.isTest == 1"
                          class="el-icon-document lefBtn_icon"
                        ></i>
                        <!-- <i class="el-icon-document lefBtn_icon"></i> -->
                      </span>
                      <!-- <el-button v-if="(v.fileType === 4 || v.fileType === 5) && o.resRepoStatus == 2" style='color:#F04B4A;border-color:#F04B4A;padding: 0 10px;' @click="handleOpenExamDialog(o,item.courseConfId,v.fileType)">开始</el-button> -->
                      <!-- <el-button v-if="(v.fileType === 4 || v.fileType === 5) && o.resRepoStatus == 3" style='color:#2D70F8;border-color:#2D70F8;' @click="handleJumpReview(o,item.courseConfId)">回顾</el-button> -->
                      <!-- v-if="v.fileType > 3 && o.resRepoStatus == 3" -->
                      <!-- <span>
                      <span @click="seeView(1,o, v.fileType)" v-if="v.fileType == 3" style="color:#000;font-size:16px;line-height: 34px;background-color: #2D70F8;color: #fff;border-radius:4px;text-align: center;display:block;width: 64px;">查看</span>
                    </span> -->

                      <!-- <el-button @click="seeView(2,o, v.fileType,true)" class="class_info_item_btn_play" v-if="v.fileType == 3">播放</el-button> -->
                      <el-button
                        :class="[
                          o.resRepoStatus !== 1
                            ? 'class_info_item_btn_submitover'
                            : 'class_info_item_btn_submit',
                        ]"
                        @click="ReleaseTeaching(o, item, v)"
                        >{{
                          o.resRepoStatus == 1 ? "发布" : "已发布"
                        }}</el-button
                      >
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </el-timeline-item>
      </div>

      <div class="teaching_program" v-if="type === 'outline'">
        <el-timeline-item v-for="(item, j) in list" :key="j">
          <div class="class_info_left">
            <span
              class="time"
              style="font-size: 16px; line-height: 28px; margin-bottom: 4px"
              v-if="item.nameList && item.nameList.length > 1"
              >{{ item.nameList ? item.nameList[0] : "" }} -
              {{ item.nameList[item.nameList.length - 1] }}</span
            >
            <span
              class="time"
              style="font-size: 16px; line-height: 28px; margin-bottom: 4px"
              v-else
              >{{ item.nameList[0] }}</span
            >
            <span
              v-for="(v, i) in item.teaList"
              :key="i"
              style="color: #9c9c9c; font-size: 16px"
            >
              {{ v.teaName }}<br />
            </span>
          </div>

          <div
            class="class_info_right"
            v-for="(v, i) in item.resRepoList"
            :key="'list3' + i"
          >
            <div>
              <span class="tag tag_1" v-if="v.fileType === 1"></span>
              <span class="tag tag_2" v-if="v.fileType === 2"></span>
              <span class="tag tag_3" v-if="v.fileType === 3"></span>
              <span class="tag tag_4" v-if="v.fileType === 4"></span>
              <span class="tag tag_5" v-if="v.fileType === 5"></span>
              <span class="tag tag_6" v-if="v.fileType === 6"></span>
              <ul class="class_info_item">
                <li
                  v-for="(o, k) in v.dataList"
                  :key="'list3' + k"
                  :style="
                    item.inClass === 0 || item.inClass === 1
                      ? 'color:#333'
                      : 'color:#999'
                  "
                >
                  <span v-if="v.fileType <= 3" class="nameTitle">
                    {{ o.name }}
                  </span>
                  <span v-if="v.fileType > 3" class="nameTitle">
                    {{ o.name }}
                    <span style="color: #999; margin-left: 3px"
                      >{{ o.totalQu > 0 ? `${o.totalQu}个` : "" }}/{{
                        o.duration > 0 ? `${o.duration}分钟` : "不限时间"
                      }}</span
                    >
                  </span>
                  <span class="class_info_item_btns_outline">
                    <span
                      @click="seeView(1, o, v.fileType)"
                      v-if="
                        o.resRepoStatus &&
                        v.fileType !== 2 &&
                        v.fileType !== 4 &&
                        v.fileType !== 5 &&
                        v.fileType !== 6
                      "
                      style="
                        color: #000;
                        font-size: 16px;
                        line-height: 34px;
                        background-color: #2d70f8;
                        color: #fff;
                        border-radius: 4px;
                        text-align: center;
                        display: block;
                        width: 64px;
                      "
                      >查看</span
                    >
                    <span
                      v-if="
                        (v.fileType == 4 ||
                          v.fileType == 5 ||
                          v.fileType == 6) &&
                        o.resRepoStatus !== 0 &&
                        o.resRepoStatus !== 1
                      "
                      style="
                        color: #999;
                        width: 18px;
                        margin-left: -18px;
                        position: relative;
                        left: 18px;
                        line-height: 34px;
                      "
                      @click="
                        goExaminationRecord(item.courseConfId, o, v.fileType)
                      "
                    >
                      <i
                        v-if="o.isTest == 1"
                        class="el-icon-document lefBtn_icon"
                      ></i>
                    </span>
                    <!-- 自测 -->
                    <!-- <span v-if="!o.resRepoStatus && v.fileType === 2" style='color:#2D70F8;' @click="handleOpenTest(o,item.courseConfId)">查看</span> -->
                    <!-- 巩固和考试 -->
                    <!-- <el-button v-if="(v.fileType === 4 || v.fileType === 5) && o.resRepoStatus == 2" style='color:#F04B4A;border-color:#F04B4A;padding: 0 10px;' @click="handleOpenExamDialog(o,item.courseConfId,v.fileType)">开始</el-button> -->
                    <!-- <el-button v-if="(v.fileType === 4 || v.fileType === 5) && o.resRepoStatus == 3" style='color:#2D70F8;border-color:#2D70F8;' @click="handleJumpReview(o,item.courseConfId)">回顾</el-button> -->
                    <span>
                      <!-- <span @click="seeView(1,o, v.fileType)" v-if="v.fileType == 3" style="color:#000;font-size:16px;line-height: 34px;background-color: #2D70F8;color: #fff;border-radius:4px;text-align: center;display:block;width: 64px;">查看</span> -->
                    </span>
                    <!-- <el-button  @click="seeView(2,o, v.fileType,true)" class="class_info_item_btn_play" v-if="v.fileType == 3">播放</el-button> -->
                    <el-button
                      :class="[
                        o.resRepoStatus !== 1
                          ? 'class_info_item_btn_submitover'
                          : 'class_info_item_btn_submit',
                      ]"
                      v-if="o.resRepoStatus !== 0"
                      @click="ReleaseTeaching(o, item, v, j, i, k)"
                      >{{ o.resRepoStatus == 1 ? "发布" : "已发布" }}</el-button
                    >
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </el-timeline-item>
      </div>
    </el-timeline>
    <el-dialog v-model="testdialogVisible" width="437px" height="600px">
      <div class="dialog_main">
        <div>
          <div class="user_box">
            <el-image
              style="width: 50px; height: 50px"
              :src="detailsInfo.headImg"
              v-if="detailsInfo.headImg"
            ></el-image>
            <div class="user_name">
              <h2>姓名：{{ detailsInfo.userName }}</h2>
            </div>
          </div>
          <div class="details_box">
            <div class="detail">所属班级:{{ detailsInfo.gradeName }}</div>
            <div class="detail">试卷名称:{{ detailsInfo.paperName }}</div>
            <div v-if="details.duration === '0'" class="detail">
              考试标准：{{ detailsInfo.totalQu }}题/不限时间
            </div>
            <div v-else class="detail">
              考试标准：{{ detailsInfo.totalQu }}题/{{
                detailsInfo.duration
              }}分钟
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleOpenExam">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 发布弹窗 -->
    <el-dialog
      title="温馨提示"
      v-model="dialogVisible"
      width="580px"
      class="fabuBox"
      :before-close="handleClose"
    >
      <span>确定发布吗?确定后将展示在学员端。</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            style="width: 120px; height: 46px; font-size: 16px"
            @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button
            style="
              width: 120px;
              height: 46px;
              background: #2e7fff;
              font-size: 16px;
            "
            type="primary"
            @click="ReleaseTeachingok"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-dialog
      customClass="file_preview"
      :title="dialogTitle"
      :width="
        (dialogType == 'mp4' || dialogType == 'pptx' || dialogType == 'pdf') &&
        fullscreen == true
          ? ''
          : '1200px'
      "
      id="d-dialog"
      append-to-body
      :fullscreen="fullscreen"
      v-model="showDialog"
      :close-on-press-escape="false"
      @close="close"
      :show-close="!fullscreen"
    >
      <div
        class="dialogCont"
        ref="dialogCont"
        :style="
          (dialogType == 'mp4' ||
            dialogType == 'pptx' ||
            dialogType == 'pdf') &&
          fullscreen == true
            ? ''
            : 'width:1160px;margin:auto'
        "
      >
        <!-- <span class="screen" @click="screen" v-if="isFullScreen == 1">全屏</span> -->
        <div id="d_yuLan">
          <div
            :style="fullscreen == true ? 'height:93vh' : 'min-height:60vh'"
            v-if="dialogType == 'pdf'"
          >
            <iframe
              :style="fullscreen == true ? 'height:93vh' : 'min-height:60vh'"
              :src="url"
              frameborder="0"
              width="100%"
              height="100%"
            ></iframe>
          </div>
          <div
            :style="fullscreen == true ? 'height:93vh' : 'min-height:60vh'"
            v-if="dialogType == 'pptx'"
          >
            <iframe
              :style="fullscreen == true ? 'height:93vh' : 'min-height:60vh'"
              :src="
                'https://view.officeapps.live.com/op/view.aspx?src=' +
                encodeURIComponent(url)
              "
              frameborder="0"
              width="100%"
              height="100%"
            ></iframe>
          </div>
          <div
            :style="fullscreen == true ? 'height:93vh' : 'min-height:60vh'"
            v-if="
              dialogType == 'xls' ||
              dialogType == 'xlsx' ||
              dialogType == 'doc' ||
              dialogType == 'docx'
            "
          >
            <iframe
              :style="fullscreen == true ? 'height:93vh' : 'min-height:60vh'"
              :src="
                'https://view.officeapps.live.com/op/view.aspx?src=' +
                encodeURIComponent(url)
              "
              frameborder="0"
              width="100%"
              height="100%"
            ></iframe>
          </div>
          <div style="text-align: center" v-if="dialogType == 'mp4'">
            <video
              loop="loop"
              style="width: 94%; max-height: 100%; max-width: 100%"
              :src="url"
              controls
              ref="video"
            ></video>
          </div>
          <div
            class="img_box"
            v-if="
              dialogType == 'png' ||
              dialogType == 'jpg' ||
              dialogType == 'jpeg' ||
              dialogType == 'gif'
            "
          >
            <picText
              :url="url"
              :type="bindType"
              :fileType="fileType"
              :describe="describe"
              :imgTextList="imgTextList"
            ></picText>
          </div>
        </div>
      </div>
      <span class="screen_icon" @click="screen">
        <img
          class="full_screen"
          v-if="!fullscreen"
          src="../../../../assets/images/full_screen.png"
        />
        <img
          class="reduction_screen"
          v-else
          src="../../../../assets/images/reduction_screen.png"
        />
      </span>
    </el-dialog>
  </div>
</template>

<script>
import picText from "@/views/Details/components/Details/picText";
import { GRADEPAPER, STUDENTEXAM } from "@/server/details";
export default {
  data() {
    return {
      dialogVisible: false,
      testdialogVisible: false,
      showDialog: false,
      activities: [
        {
          content: "支持使用图标",
          timestamp: "2018-04-12 20:46",
          size: "large",
          type: "primary",
          icon: "el-icon-more",
        },
        {
          content: "支持自定义颜色",
          timestamp: "2018-04-03 20:46",
          color: "#0bbd87",
        },
        {
          content: "支持自定义尺寸",
          timestamp: "2018-04-03 20:46",
          size: "large",
        },
        {
          content: "默认样式的节点",
          timestamp: "2018-04-03 20:46",
        },
      ],
      fullscreen: false,
      // 文件后缀
      dialogType: "",
      // 文件地址
      url: "",
      dialogTitle: "",
      // 文件类型
      bindType: "",
      // 课件类型
      fileType: "",
      gradeId: "",
      courseConfId: "",
      paperId: "",
      // 图文描述
      describe: "",
      // 图文列表
      imgTextList: [],
      detailsInfo: "",
      aiType: "",
      isFullScreen: "",
      listJ: "",
      listI: "",
      listK: "",
    };
  },
  components: {
    picText,
  },
  watch: {},
  props: ["list", "type", "outLineList"],
  created() {
    // console.log(this.list)
    this.gradeId = this.$route.query.id;
    this.$emit("fatherMethod");
    if (this.type == "outline") {
      this.$emit("fatherType", "outline");
    }
  },
  mounted() {
    let that = this;
    this.$nextTick(function () {
      document.addEventListener("keyup", function (e) {
        //此处填写你的业务逻辑即可
        // console.log(that.fullscreen)
        if (e.keyCode == 27) {
          // console.log('按下了esc')
          // console.log(that.fullscreen)
          return;
        }
      });
    });
  },
  methods: {
    // 自测
    handleOpenTest(val, courseConfId) {
      // console.log(this.gradeId)
      let obj = {
        gradeConfId: courseConfId,
        gradeId: this.gradeId,
        paperId: val.id,
        duration: val.duration,
      };
      this.$router.push({ path: "/selftest", query: obj });
    },
    // 考试
    handleOpenExam() {
      this.testdialogVisible = false;
      // console.log(this.obj)
      this.$router.push({ path: "/examination", query: this.obj });
    },
    handleOpenExamDialog(val, courseConfId, fileType) {
      this.testdialogVisible = true;
      this.obj = {
        gradeConfId: courseConfId,
        gradeId: this.gradeId,
        paperId: val.id,
        duration: val.duration,
        fileType: fileType,
      };
      console.log(this.gradeId);
      // 获取用户信息
      STUDENTEXAM({
        courseConfId: courseConfId,
        gradeId: this.gradeId,
        paperId: val.id,
        userId: JSON.parse(window.sessionStorage.getItem("loginInfo")).eId,
        examType: fileType,
      }).then((res) => {
        if (res.data.code === 0) {
          this.detailsInfo = res.data.data;
        }
      });
    },
    // 点击播放
    play() {},
    handleClose() {
      this.dialogVisible = false;
    },
    ReleaseTeachingok() {
      // return
      // this.list[0].resRepoList[this.listI].dataList[this.listK].resRepoStatus = '2'
      // console.log(this.courseConfId);
      // console.log(this.aiType)
      if (this.aiType == 6) {
        this.$http
          .post("/corporate/university/grade/publish/ai/train", {
            id: this.paperId,
          })
          .then((res) => {
            // console.log(res)
            if (res.data.code == 0) {
              this.$message({
                message: "发布成功",
                type: "success",
              });
              if (this.type == "outline") {
                // location.reload()
                this.$emit("fatherType", "outline");
                // this.type = 'outline'
              } else {
                this.$emit("fatherMethod");
              }

              // this.$emit('faterChange')
              this.dialogVisible = false;
            } else {
              this.$message(res.data.msg);
            }
          });
      } else {
        GRADEPAPER({
          gradeId: this.gradeId,
          courseConfId: this.courseConfId,
          paperId: this.paperId,
          fileType: this.aiType,
        }).then((res) => {
          // console.log(res.data)
          if (res.data.code == 0) {
            this.$message({
              message: "发布成功",
              type: "success",
            });
            if (this.type == "outline") {
              // location.reload()
              this.list[this.listJ].resRepoList[this.listI].dataList[
                this.listK
              ].resRepoStatus = "2";
              // this.$emit('fatherType','outline')
              // this.type = 'outline'
            } else {
              this.$emit("fatherMethod");
            }

            // this.$emit('faterChange')
            this.dialogVisible = false;
          } else {
            this.$message(res.data.msg);
          }
        });
      }
    },
    //发布
    ReleaseTeaching(val, item, v, j, i, k) {
      this.listJ = j;
      this.listI = i;
      this.listK = k;
      // this.$emit('fatherType','outline')
      this.aiType = v.fileType;
      // console.log(this.aiType)
      this.courseConfId = item.courseConfId;
      this.paperId = val.id;
      if (val.resRepoStatus == 1) {
        this.dialogVisible = true;
      } else {
        this.dialogVisible = false;
      }
    },
    hideIframe() {
      this.previewBili = false;
    },
    // 点击查看
    seeView(type, obj, fileType, fullscreen) {
      // console.log(type)
      this.isFullScreen = type;
      // console.log(obj)
      // this.dialogTitle = 'obj.name'
      // console.log(fileType)
      if (obj.imgTextList || obj.url) {
        let title = "";
        if (type == 2) {
          title = "确定播放吗？";
        } else {
          title = "确定打开吗？";
        }
        this.$confirm(title, "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            if (obj.type == 3) {
              let url1 = obj.url.replace("//", "https://");
              // // console.log(url1)
              this.$alert(url1, "文件预览", {
                dangerouslyUseHTMLString: true,
                showConfirmButton: false,
                customClass: "iframeBox",
              });
            } else {
              if (obj.url) {
                this.dialogType = obj.url.substring(
                  obj.url.lastIndexOf(".") + 1,
                  obj.url.length
                );
                console.log(this.dialogType);
              }
              this.url = obj.url;
              this.bindType = obj.type;
              this.describe = obj.describe;
              this.imgTextList = obj.imgTextList;
              this.fileType = fileType;
              this.showDialog = true;
              this.fullscreen = fullscreen ? fullscreen : false;
              this.dialogTitle = obj.name;
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else {
        this.$message.warning("暂无内容！");
        return;
      }
    },
    // 关闭弹窗
    close() {
      this.url = "";
      this.bindType = "";
      this.describe = "";
      this.imgTextList = "";
      this.fileType = "";
      if (this.$refs.video) {
        this.$refs.video.pause();
      }

      this.fullscreen = false;
      this.showDialog = false;
    },
    // 全屏展示
    screen() {
      let element = document.documentElement;
      console.log(element);
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    /**
     * 查看考试记录
     */
    goExaminationRecord(id, val, type) {
      console.log(val);
      console.log(type);
      localStorage.setItem("testName", val.name);
      if (type == 6) {
        this.$router.push({
          path: "/teacPracticePartner",
          query: {
            gradeConfId: id,
            gradeId: this.$route.query.id, //
            aiId: val.id, //
          },
        });
      } else {
        this.$router.push({
          path: "/examinationRecord",
          query: {
            gradeConfId: id,
            gradeId: this.$route.query.id, //
            paperId: val.id, //
            type: type, //
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.infoPage {
  .lefBtn_icon {
    width: 64px;
    margin-right: 30px;
    right: -34px;
    position: absolute;
    top: 10px;
  }
}
.d_yuLan > div {
  min-height: 60vh;
  text-align: center;
}
.el-dialog__body {
  // width: 1200px;
  // padding: 0;
  // margin: auto;
}
.img_box {
  .el-image__inner {
    // width: 360px;
  }
}
.img_box {
  .picText {
    display: flex;
    align-items: flex-start;
    text-align: left !important;
    .text {
      margin-left: 30px;
      margin-top: 0;
    }
  }
}
.file_preview {
  // min-height: 670px;
  // width: auto!important;
  .el-dialog__header {
    height: 40px;
    background: #f8f8f8;
    text-align: center;
    line-height: 40px;
    padding: 0;
    .el-dialog__title {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 13px;
    }
  }
  .el-dialog__body {
    padding: 0;
    margin: 0;
  }
}
.el-message-box {
  width: 580px;
  height: 350px;
  .el-message-box__title {
    color: #333;
    font-size: 20px;
    font-weight: 500;
    line-height: 42px;
    margin-left: 20px;
  }
  .el-message-box__message > p {
    font-size: 16px;
    color: #333;
    font-weight: 400;
    margin-top: 20px;
    padding: 0 0 0 20px;
    margin-bottom: 134px;
  }
  .el-message-box__btns > button {
    width: 120px;
    height: 46px;
    font-size: 16px;
  }
  .el-message-box__btns > button:nth-of-type(2) {
    background: #2e7fff;
    margin-left: 30px;
    margin-right: 30px;
  }
}
.infoPage {
  .el-dialog__title {
    color: #333;
    font-size: 20px;
    font-weight: 500;
    line-height: 42px;
    margin-left: 20px;
  }
  .el-dialog__body {
    font-size: 16px;
    color: #333;
    font-weight: 400;
    margin-top: 20px;
    padding: 0 0 0 40px;
    margin-bottom: 134px;
  }
}
.iframeBox {
  width: 76% !important;
  text-align: center;
}
iframe {
  width: 100%;
  // min-height: 60vh;
}
@mixin Tag($color: #2e7fff, $text: "课件") {
  background-color: $color;

  &::after {
    content: $text;
  }
}
.class_info_wrap {
  .el-timeline-item__wrapper {
    padding-left: 9px;
  }

  .el-timeline-item {
    min-height: 100px;
    padding-bottom: 0px;
  }
  .el-timeline-item__node {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border: 1px solid #dddddd;
    top: 5px;
  }

  .el-timeline-item__node--normal {
    left: 118px;
    top: 20px;
    z-index: 99;
  }

  .el-timeline-item__tail {
    left: 124px;
    top: 30px;
    border-left: 1px solid #dddddd;
    z-index: 99;
  }
}

.class_info_left {
  // position: absolute;
  float: left;
  margin-top: 16px;
  width: 104px;
  span {
    display: block;
    width: auto;
  }
  .time {
    color: #9c9c9c;
  }
}
.teaching_program {
  .class_info_left {
    margin-top: 8px;
  }
}
// .class_info_right:last-child{
//   padding-bottom: 27px;
//   // border-bottom: 1px solid #DDDDDD;
// }
// .el-timeline-item:last-child{
//   .class_info_right:last-child{
//     padding-bottom: 27px;
//     border-bottom: none;
//   }
// }
.class_info_right {
  margin-left: 117px;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  // height: 40px;
  display: flex;
  align-items: center;
  padding-left: 23px;
  div {
    display: flex;
    align-items: flex-start;
    margin-block-start: 0;
    margin-block-end: 0;
    justify-content: space-between;
    // align-items: center;
    margin-bottom: 16px;
    color: $font_color_grey_3;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .class_info_item {
    flex: 1;
    li {
      font-size: 16px;
      // margin-top: -7px;
      // margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      .nameTitle {
        width: 760px;
      }
      span {
        // padding-top: 9px;
        box-sizing: border-box;
      }
      .nameTitle {
        font-size: 16px;
        // line-height: 28px;
        // margin-top: 8px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .class_info_item_btns {
    & > span {
      cursor: pointer;
      display: inline-block;
      width: 80px;
    }

    .el-button {
      padding: 0;
      width: 74px;
      height: 34px;
      min-height: 34px !important;
      line-height: 32px;
      font-size: 16px;
    }
  }

  .class_info_item_btns_outline {
    width: auto;
    display: flex;
    & > span {
      cursor: pointer;
      display: inline-block;
      width: auto;
    }

    .el-button {
      padding: 0;
      width: 74px;
      height: 34px;
      line-height: 34px;
      min-height: 34px !important;
      font-size: 16px;
      margin-left: 10px;
    }
  }

  .class_info_item_btn_play {
    background-color: $theme_color;
    color: $white;
    // margin-top: 13px;
  }

  .class_info_item_btn_submit {
    border: 1px solid #f04b4a;
    background: #fff;
    color: #f04b4a;
    // margin-top: 13px;
  }

  .class_info_text {
    display: inline-block;
    position: relative;
    padding-right: 10px;
    margin-right: 10px;
    height: 20px;
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 16px;
      background-color: #262626;
      top: 5px;
      right: 0;
    }

    &:last-child::after {
      display: none;
    }
  }

  .class_info_gray {
    color: #9a9a9a;
  }

  .tag {
    display: inline-block;
    width: 40px;
    height: 24px;
    background-color: #969da8;
    color: $white;
    margin-right: 8px;
    text-align: center;
    line-height: 24px;
    margin-top: 8px;
  }

  .tag_1 {
    @include Tag(#969da8, "预习");
  }

  .tag_2 {
    @include Tag(#969da8, "自测");
  }

  .tag_3 {
    @include Tag();
  }

  .tag_4 {
    @include Tag(#33baf2, "巩固");
  }

  .tag_5 {
    @include Tag(#f04b4a, "考试");
  }

  .tag_6 {
    @include Tag(#ff9419, "陪练");
  }
}
.class_info_right:hover,
.class_title_name:hover {
  background: #f1f1f1;
}
.class_info_right_box .class_title_name {
  margin-left: 115px;
  padding-top: 20px;
  padding-left: 23px;
  height: 40px;
  .class_title {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
  }
  .class_name {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
    margin-left: 10px;
  }
  .name {
    font-size: 16px;
    margin-left: 10px;
  }
}

.dialogCont {
  position: relative;
  .screen {
    position: absolute;
    top: -20px;
    right: 0;
    cursor: pointer;
  }
  .screen_iconfalse {
    position: absolute;
    top: -65px;
    right: 20px;
    cursor: pointer;
  }
  .screen_icontrue {
    position: absolute;
    top: -65px;
    right: -100px;
    cursor: pointer;
  }
}
.screen_icon {
  position: absolute;
  top: 12px;
  right: 40px;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
  .reduction_screen {
    position: absolute;
    right: -30px;
  }
}
.class_detail {
  color: black;
}
.class_info_item_btn_submitover {
  border: 1px solid #dddddd;
  color: #bfbfbf;
}
.name {
  font-size: 16px;
}
.nameDAY {
  width: 180px;
  font-size: 16px;
  word-wrap: break-word;
}
.class_info_wrap .teaching_program {
  .el-timeline-item__node--normal {
    top: 10px;
  }
  .el-timeline-item__tail {
    top: 20px;
  }
}
</style>
