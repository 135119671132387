<!-- 单个课程 -->
<template>
  <div
    :class="['curriculum_wrap', `curriculum_wrap_${classStatus}`]"
    @mouseenter="hover"
    @mouseleave="hover"
    >
    <i v-if="classStatus !== 'end'" class="close el-icon-close" @click="delFn()"></i>

    <div @click="toDetai(item,classStatus)">
      <h4 class="curriculum_title">{{ item.gradeName }}</h4>
      <p class="curriculum_text" style="margin-top:4px">
        <span>{{ item.openGradeTime }} - {{ item.endGradeTime }}</span>
        <span>{{ item.peopleNum }}人</span>
      </p>
      <div v-if="classStatus == 'open'" class="attendClassTwo" style="margin-top:8px">
        <div v-if="item.status=='2' && item.arrangerTeacher=='1' && eId == item.masterId" class="attendClassTwo" >
            <i class="el-icon-warning"></i>
            请前往课程表安排讲师
        </div>
        <div v-if="item.gradeConfId =='' || item.gradeConfId == null" class="attendClassTwo" >
            <i class="el-icon-warning"></i>
            暂无班级属性
        </div>
      </div>
      <!-- <div v-if="classStatus == 'open' && item.gradeConfId =='' || item.gradeConfId == null" class="attendClassTwo">
        <div  class="attendClassTwo" >
            <i class="el-icon-warning"></i>
            暂无班级属性
        </div>
      </div> -->
      <div v-if="classStatus == 'open'" class="curriculum_bottomTwo" style="margin-top:4px">
        <div class="lef">
          {{ item.masterName }}
        </div>
        <div class="rig">
          <div v-if="item.status !== 2" class="">
            <span class="configure" v-if="item.status === 1">课程配置中...</span>  
            <!-- <i class="el-icon-success curriculum_bottom_success"></i> -->
            <span class="configure1" v-else-if="item.status === 3" @click="getRight(item)">配置失败<i class="el-icon-refresh"></i></span>  
          </div>
          <div v-else class="configure1">
            <span 
              v-if="item.inClass === 1 && classStatus !== 'pre'"
              :class="{1: 'attendClass2'}[status]">
              上课中
              <i class="el-icon-s-data"></i>
            </span>
            <span v-else v-show="flag">配置成功<i class="el-icon-success curriculum_bottom_success"></i></span>
          </div>
        </div>
      </div>

      <div v-if="classStatus == 'pre'" class="curriculum_bottom">
        <div >
          <!-- <span class="configure" v-if="item.status=='2' && item.arrangerTeacher =='1'"></span>   -->
          <span  v-if="item.status=='2' && item.arrangerTeacher =='1' && eId == item.masterId" class="attendClass">
            <i class="el-icon-warning"></i>
            请前往课程表安排讲师
          </span>
          <div v-if="item.gradeConfId =='' || item.gradeConfId == null" class="attendClassTwo" >
            <i class="el-icon-warning"></i>
            暂无班级属性
        </div>
        </div>
      </div> 
      <div v-if="classStatus == 'pre'" class="preBox" style="margin-top:4px">
        {{ item.masterName }}
      </div>

    </div>
  </div>
</template>

<script>
import {getRole} from '@/utils/utils'
import { updataStatus ,delClass} from '@/server/class.js'

export default {
  data () {
    return {
      status: 1,
      type:'',
      dialogVisible:false,
      dialogVisible1:false,
      eId:'',
      flag:false
    };
  }, 
  props: ['classStatus', 'item'],
  created(){
    this.type = getRole()
    
    this.eId = JSON.parse(sessionStorage.getItem('loginInfo')).eId
  },
  methods: {
    hover({ target }) {
      if (this.classStatus !== 'end') {
        const display = target.querySelector('.close').style.display
        target.querySelector('.close').style.display = display === 'none' || !display ? 'block' : 'none';
      }
    },
    delFn(){
      // console.log(this.item.masterId);
      console.log(this.item)
      let id = this.item.masterId
      if(this.eId === id){
          console.log('111')
          let title = ''
          if(this.item.gradeStatus == 1){
            this.$message.warning('已经开班，无法删除！')
            return
          }
          this.$confirm('确定删除吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(() => {
            delClass({
              gradeId:this.item.id
            }).then((data)=>{
              // console.log(data)
              if(data.data.code == 0){
                this.$message.success('删除成功！')
                this.$parent.getClassList()
                // location.reload();
              }else{
                this.$message.error(data.data.msg);
              }
            }).catch((data)=>{
              this.$message.error(data.data.msg);
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
          });          
        });
          
      }else{
        this.$message.warning("暂无删除权限！");
      }
    },
    toDetai(val,classStatus) {
      const {aplus_queue} = window;
      //一个简单的demo
      // aplus_queue.push({
      // action: 'aplus.record',
      //   arguments: ['loginClick', 'CLK', {
      //     param1: this.classStatus,
      //     param2: '222',
      //     param3: 333
      //   }]
      // });
      console.log(val)
      // console.log(classStatus)
      console.log(this.item)
      if(val.status=='3') {
        this.$message.warning('该班级配置失败，请重新配置！')
        return
      }
      if(val.gradeStatus == 1 && val.gradeConfId == null){
        this.$message.warning('该班级已开班且无班级属性，暂不支持查看！')
        return
      }
      // if (val.gradeStatus == 2) {
      //   this.$message.warning('该班级已结束，暂不支持查看！')
      //   return
      // }
        if(this.type == 'student') {
            this.$router.push({
              path: '/studentDetails',
              query: {
                id: this.item.id,
                gradeConfId:this.item.gradeConfId,
                timeType:val.timeType
              }
            })
            this.flag = false
        }else{
            localStorage.setItem('clickId',val.masterId)
            localStorage.setItem('classStatus',classStatus)
            this.$router.push({
              path: '/details',
              query: {
                id: this.item.id,
                gradeConfId:this.item.gradeConfId,
                timeType:val.timeType
              }
            })
            this.flag = false
        }
      // }
    },
    getRight(val) {
      // val.status = 2 g
      this.flag = true
      updataStatus({gradeId:val.id,gradeConfId:val.gradeConfId,userId:this.eId}).then(res=>{
        console.log(res.data);
        if(res.data.code==0){
          //  val.status = '2' 
            this.$message({
              message: '重新配置中',
              type: 'success'
          });
          // location.reload() 
          this.$parent.getClassList()
        }
        this.flag = true
      })
    }
  }
}

</script>
<style lang='scss' scoped>
.curriculum_wrap {
  width: 387px;
  background-color: $white;
  position: relative;
  padding: 24px;
  box-sizing: border-box;
  cursor: pointer;
  .close {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: $white;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }

  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $font_color_orange;
  }

  .curriculum_title {
    font-size:16px;
    font-weight: 500;
    
    color: #333;
  }

  .curriculum_text {
    color: $font_color_grey_3;
    font-size:16px;
    

    span:first-child {
      margin-right: 20px;
    }
  }

  .curriculum_bottom {
    margin-top: 8px;
    font-size:16px;
    display: flex;
    justify-content: space-between;
    .lef{
      width: 67px;
      font-size:16px;
    }
    .rig{
      flex: 1;
    }
    i {
      margin-left: 5px;
    }
  }

   .curriculum_bottomTwo {
    // height: 54px;
    
    font-size:16px;
    display: flex;
    justify-content: space-between;
    .lef{
      width: 67px;
      font-size:16px;
    }
    .rig{
      flex: 1;
      // height: 54px;
      
    }
    i {
      margin-left: 5px;
    }
  }

  .attendClass {
    color: $font_color_red;
    height: 28px;
    
    margin-top: 10px;
    font-size:16px;
  }

  .attendClass2 {
    color: $font_color_red;
    height: 28px;
    line-height: 54px;
    margin-top: 10px;
    font-size:16px;
  }

  .attendClassTwo {
    color: $font_color_red;
    height: 28px;
    
    // margin-top: 15px;
    font-size:16px;
  }

  .preBox{
    height: 28px;
    
    font-size:16px;
  }
  .openSure {
    height:40px
  }
  .configure {
    color: $theme_color;
    font-size:16px;
    margin-left: 150px;
  }
  .configure1 {
    color: $font_color_red;
    font-size:16px;
    margin-left: 140px;
    line-height: 30px;
  }
  .curriculum_bottom_success {
    color: $bg_green;
  }
}

.curriculum_wrap_pre::before {
  background-color: $theme_color;
}

.curriculum_wrap_end::before {
  background-color: #CFD4DF;
}
</style>