<template>
  <div class="results 22">
      <div class="wrap">
        <el-image v-if="userImg" style="width: 50px; height: 50px" :src="userImg" class="user_img fffff"></el-image>
        <div v-else class="user_img" style="top:-40px;margin-left:-40px;">
          <img style="width: 80px; height: 80px; border-radius: 50%; border: 2px solid rgb(236, 202, 159);background-color:#fff;" src="../../assets/images/header.png" alt="">
        </div>
        <h2>{{userName}}</h2>
        <div class="fraction">{{score}}分</div>
        <div class="time">用时 {{secondsFormat2(timeSelf)}}</div>
        <!-- {{secondsFormat2(rankingList)}} -->
        <div><el-button type="primary" size="mini" @click="handleBack">回顾试卷</el-button></div>
        <div class="rankList">
          <el-table
              
              :data="rankingList"
              :header-cell-style="{
                  background: '#f8f8f8',
                  color: '#999',
                  fontSize:'20px',
              }"
              :cell-style="{
                  background: '#f8f8f8',
                  fontSize:'20px',
              }"
              style="width: 100%;">
              <el-table-column
                align='center'
                  label="排行"
                  type="index"
                  width="120">
                  <template #default="scope">
                      <span v-if="scope.$index==0"><img src="@/assets/images/icon_first.png" alt=""></span>
                      <span v-if="scope.$index==1"><img src="@/assets/images/icon_second.png" alt=""></span>
                      <span v-if="scope.$index==2"><img src="@/assets/images/icon_third.png" alt=""></span>
                      <span v-if="scope.$index>=3" class="rankStyle">{{scope.$index+1}}</span>
                  </template>  
              </el-table-column>
              <el-table-column
                  label="用户名"
                  width="180">
                  <template #default="scope">
                      <div>
                        <img class="defaultImg" :src="scope.row.headImg || require('@/assets/images/header.png')"  alt="">
                        <span class="userName">{{scope.row.userName}}</span>
                      </div>
                  </template> 
              </el-table-column>
              <el-table-column
                  label="考试成绩"
                  width="180">
                  <template #default="scope">
                     <span style="color:red">{{ scope.row.score }}</span>分
                  </template>    
              </el-table-column>
              <el-table-column
                  label="考试用时"
                  show-overflow-tooltip>
                  <template #default="scope">
                      <div style="font-weight: 400;color: #333333;font-size:20px">
                          {{secondsFormat2(scope.row.costTime)}}
                      </div>
                  </template>  
              </el-table-column>
          </el-table>
        </div>
      </div>
  </div>
</template>

<script>
import {ref} from 'vue'
import {useRoute, useRouter} from 'vue-router';
export default {
    name:'testresults',
    setup(){
      const route = useRoute()
      const router = useRouter()
      const score = ref('')
      const times = ref('')
      const userName = ref('')
      const userImg = ref('')
      const rankingList = ref([])
      const eId = ref('')
      const timeSelf = ref('')
      
      rankingList.value = JSON.parse(localStorage.getItem('examList'));
      score.value = route.query.score
      times.value = route.query.times
      const info = JSON.parse(window.sessionStorage.getItem('loginInfo'))
      userName.value = info.realName
      userImg.value = info.headUrl

      eId.value = JSON.parse(sessionStorage.getItem('loginInfo')).eId
      rankingList.value.forEach(element => {
        if(element.userId == eId.value) {
          timeSelf.value = element.costTime
        }
      });
      console.log(timeSelf.value)

      const secondsFormat = (s) => {
        var day = Math.floor(s / (24 * 3600)); // Math.floor()向下取整
        var hour = Math.floor((s - day * 24 * 3600) / 3600);
        var minute = Math.floor((s - day * 24 * 3600 - hour * 3600) / 60);
        var second = s - day * 24 * 3600 - hour * 3600 - minute * 60;
        if (hour) {
          return hour + ":" + minute + ":" + second;
        } else {
          if (second < 10) {
            if (minute < 10) {
              return "0" + minute + ":" + second + "0";
            } else {
              return minute + ":" + second + "0";
            }
          } else {
            return minute + ":" + second;
          }
        }
      }
      const secondsFormat2 = (s) => {
        // let days = Math.floor(s / (1000 * 60 * 60 * 24))
        let hours = Math.floor((s % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        let minutes = Math.floor((s % (1000 * 60 * 60)) / (1000 * 60))
        let seconds = Math.floor((s % (1000 * 60)) / 1000)
        if(hours < 10) {
          hours = '0' + hours
        } else {
          hours = hours
        }
        if(minutes < 10) {
          minutes = '0' + minutes
        } else {
          minutes = minutes
        }
        if(seconds < 10) {
          seconds = '0' + seconds
        } else {
          seconds = seconds
        }
        return hours + ':' + minutes + ':' + seconds
      }



      const handleBack = () =>{
        router.push({path:'/review',query:{gradeConfId:route.query.gradeConfId,gradeId:route.query.gradeId,paperId:route.query.paperId}})
      }
      return {
        score,
        times,
        userName,
        userImg,
        rankingList,
        secondsFormat,
        secondsFormat2,
        timeSelf,
        handleBack
      }
    }
}
</script>

<style lang="scss" scoped>
.results{
  width: 100%;
  height: 100%;
  background: linear-gradient(132deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  background: #2E7FFF;
  position: fixed;
  top: 0;
  left: 0;
  .rankList{
    width: 90%;
    height: 400px;
    overflow-y: auto;
    margin: 40px auto;
  }
  .wrap{
    width: 840px;
    height: 764px;
    background: #FFFFFF;
    border-radius: 10px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -420px;
    margin-top: -382px;
    h2{
      margin-top: 65px;
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 42px;
    }
    .fraction{
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2D70F8;
      margin-top: 40px;
    }
    .time{
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin: 8px 0 24px 0;
    }
    .user_img{
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: absolute;
      top: -25px;
      left: 50%;
      margin-left: -25px;
    }
  }
  .defaultImg{
      width:30px;
      height:30px;
      border-radius:50%;
      vertical-align: middle;
  }
  .userName{

    vertical-align: middle;
    font-weight: 400;
    color: #333333;
    font-size:20px;
    margin-left:6px
  }
}
</style>