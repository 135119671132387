<!-- 单个课程 -->
<template>
  <div
    :class="['curriculum_wrap', `curriculum_wrap_${classStatus}`]"
    >

    <div @click="toDetai(item)">
      <h4 class="curriculum_title">{{ item.gradeName }}</h4>
      <p class="curriculum_text">
        <span>{{ item.openGradeTime }} - {{ item.endGradeTime }}</span>
        <span>{{ item.peopleNum }}人</span>
      </p>

      <div v-if="classStatus == 'open'" class="curriculum_bottom">
        <div class="lef">
          {{ item.masterName }}
        </div>
        <div class="rig">
          <div v-if="item.status == 2">
            <span 
              v-if="item.inClass === 1 && classStatus !== 'pre'"
              :class="{1: 'attendClass2'}[status]">
              上课中
              <i class="el-icon-s-data"></i>
            </span>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import {getRole} from '@/utils/utils'
export default {
  data () {
    return {
      status: 1,
      type:''
    };
  }, 
  props: ['classStatus', 'item'],
  created(){
    console.log(getRole());
    this.type = getRole()
  },
  methods: {
    toDetai(val) {
      if(val.status=='2') {
        if(this.type == 'student') {
              this.$router.push({
                path: '/studentDetails',
                query: {
                  id: this.item.id,
                  timeType:val.timeType
                }
              })
            }else{
              this.$router.push({
                path: '/details',
                query: {
                  id: this.item.id,
                  timeType:val.timeType
                }
              })
            }
          }
        }
      }
  }

</script>
<style lang='scss' scoped>
.curriculum_wrap {
  width: 387px;
  // height: 160px;
  background-color: $white;
  position: relative;
  padding: 24px 24px 20px 24px;
  box-sizing: border-box;
  cursor: pointer;

  .close {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: $white;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }

  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $font_color_orange;
  }

  .curriculum_title {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }

  .curriculum_text {
    color: $font_color_grey_3;
    font-size: 16px;
    margin-top: 6px;
    line-height: 28px;

    span:first-child {
      margin-right: 20px;
    }
  }

  .curriculum_bottom {
    // width: 339px;
    margin-top: 8px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    .lef{
      width: 67px;
      font-size: 16px;
    }
    .rig{
      flex: 1;
    }
    i {
      margin-left: 5px;
    }
  }

  .attendClass {
    color: $font_color_red;
  }
  .attendClass2 {
    color: $font_color_red;
    height: 28px;
    line-height:20px;
    margin-top: 10px;
    margin-left: 120px;
    font-size: 16px;
  }
  .configure {
    color: $theme_color;
    margin-left: 170px;
    line-height: 30px;
  }
  .configure1 {
    color: $font_color_red;
    margin-left: 170px;
    line-height: 30px;
  }
  .curriculum_bottom_success {
    color: $bg_green;
  }
}

.curriculum_wrap_pre::before {
  background-color: $theme_color;
}

.curriculum_wrap_end::before {
  background-color: #CFD4DF;
}
</style>