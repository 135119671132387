<!--  -->
<template>
  <div>
    <Menu :menuTitle="classInfo.gradeName" />
    <div class="details_wrap">
      <div class="content">
        <ul class="details_class_info">
          <p style="position: absolute; right: 34px; font-size: 16px">
            <img
              v-if="isEdit == true"
              src="../../assets/images/icon_edit.png"
              alt=""
              @click="edit"
              style="width: 19.6px; height: 19.6px; color: #2d70f8"
            />
            <!-- <i v-if="isEdit == 'pre'" class="el-icon-edit-outline" ></i> -->
            <!-- <svg v-if="isEdit == 'pre'" class="icon-svg" aria-hidden="true" @click="edit" style="width:1.5rem;height:1.5rem">
              <use xlink:href="#icon_editor"></use>
              <i class="el-icon-edit-outline"></i> 
            </svg> -->
          </p>
          <li>
            <span class="details_class_info_label">班级名称：</span>
            <span>{{ classInfo.gradeName }}</span>
          </li>
          <li>
            <span class="details_class_info_label">开班时间：</span>
            <span
              >{{ classInfo.openGradeTime }} -
              {{ classInfo.endGradeTime }}</span
            >
          </li>
          <li>
            <span class="details_class_info_label">班主任：</span>
            <span>{{ classInfo.masterName }}</span>
            <!-- <span style="margin-left:30px">
            <svg v-if="inClass == 0" class="icon-svg" aria-hidden="true" @click="edit">
              <use xlink:href="#icon_editor"></use>
            </svg>
          </span> -->
          </li>
          <li>
            <span class="details_class_info_label">班级属性：</span>
            <span>{{ classInfo.gradeConfName }}</span>
          </li>
          <li style="width: auto; max-width: 750px">
            <span class="details_class_info_label">班级讲师：</span>
            <span v-for="(n, i) in classInfo.teaLists" :key="n + i"
              >{{ n.teaName
              }}{{ i == classInfo.teaLists.length - 1 ? "" : "、" }}</span
            >
            <!-- <ul :class="{details_class_info_child: true, height: foldFlag_teacher}"> -->
            <!-- <li v-for="(n, i) in classInfo.teaLists" :key="n + i">{{n.teaName}}{{i == classInfo.teaLists.length -1 ? '' : '、'}}</li> -->
            <!-- <li v-show="foldFlag_teacher" class="details_class_info_btn" @click="fold_teacher">收起</li> -->

            <!-- </ul> -->
            <!-- <p class="details_class_info_btn show" @click="fold_teacher">收起</p> -->
          </li>
          <li class="details_class_info_member">
            <span class="details_class_info_label">班级成员：</span>
            <ul
              :class="{ details_class_info_child: true, height: foldFlag }"
              style="margin-right: 100px"
            >
              <template
                v-if="
                  classInfo.stuLists &&
                  classInfo.stuLists.length > 13 &&
                  foldFlag
                "
              >
                <li
                  v-for="(n, i) in classInfo.stuLists.slice(0, 14)"
                  :key="n + i"
                >
                  {{ n.stuName }}{{ i == 13 ? "" : "、" }}
                  <span v-if="i == 13">{{
                    `等${classInfo.stuLists.length}人`
                  }}</span>
                </li>
              </template>
              <template v-else>
                <li v-for="(n, i) in classInfo.stuLists" :key="n + i">
                  {{ n.stuName
                  }}{{ i == classInfo.stuLists.length - 1 ? "" : "、" }}
                </li>
              </template>
              <!-- <li v-show="foldFlag" class="details_class_info_btn" @click="fold">{{foldFlag?'收起':'展开'}}</li> -->
              <!-- <li class="details_class_info_btn show" @click="fold">{{foldFlag?'收起':'展开'}}</li> -->
            </ul>
            <p
              class="details_class_info_btn show"
              @click="fold"
              v-if="classInfo.stuLists && classInfo.stuLists.length > 13"
            >
              {{ foldFlag ? "展开" : "收起" }}
            </p>
          </li>

          <!-- <li class="details_class_info_edit">
          <svg class="icon-svg" aria-hidden="true" style="color: #8C8C8C;margin-right:17px;">
            <use xlink:href="#icon_editor"></use>
          </svg>
        </li> -->
        </ul>

        <div class="details_content">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="课程详情" name="curriculum">
              <Details
                :type="activeName"
                :list="list"
                :gradeId="gradeId"
                v-if="list.length > 0"
              />
              <div class="kong" style="text-align: center" v-else>
                <img src="@/assets/images/noData.png" alt="" />
                <p
                  style="
                    font-weight: 400;
                    line-height: 22px;
                    color: #bfbfbf;
                    margin-top: 30px;
                    margin-bottom: 100px;
                  "
                >
                  暂无数据
                </p>
              </div>
            </el-tab-pane>
            <el-tab-pane label="教学大纲" name="outline">
              <Details
                :type="activeName"
                :list="teachList"
                :gradeId="gradeId"
                v-if="teachList.length > 0"
                @changefatherType="changeType($event)"
              />
              <div class="kong" style="text-align: center" v-else>
                <img src="@/assets/images/noData.png" alt="" />
                <p
                  style="
                    font-weight: 400;
                    line-height: 22px;
                    color: #bfbfbf;
                    margin-top: 30px;
                    margin-bottom: 100px;
                  "
                >
                  暂无数据
                </p>
              </div>
            </el-tab-pane>
          </el-tabs>

          <div class="details_btns">
            <span @click="goRankingList">
              <img
                src="../../assets/images/icon_ranking.png"
                alt=""
                style="
                  margin-top: -5px;
                  width: 23px;
                  vertical-align: middle;
                  color: #2d70f8;
                "
              />
              排行榜
            </span>

            <span @click="toTimetable">
              <img
                src="../../assets/images/icon_course.png"
                alt=""
                style="
                  margin-top: -5px;
                  width: 21.8px;
                  vertical-align: middle;
                  color: #2d70f8;
                "
              />
              课程表
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Details from "./components/Details/index.vue";
import { TimestampToDate } from "@/utils/utils";
// import data from './data.json'
import { COURSEDAYSTEA, GETCLASSDETAIL, GETCOURSENAME } from "@/server/details";

export default {
  data() {
    return {
      classInfo: {
        arrangerTeacher: 0,
        endGradeTime: "2020.10.28 08:00",
        gradeConfId: 0,
        gradeConfName: "新生班",
        gradeName: "2020年第8期客服",
        id: 0,
        inClass: 0,
        masterId: 0,
        masterName: "田世宣",
        openGradeTime: "2020.10.26 08:00",
        peopleNum: 0,
        stuLists: [],
      },
      foldFlag: true,
      foldFlag_teacher: false,
      activeName: "curriculum",
      list: [],
      teachList: [],
      gradeId: "",
      isEdit: "",
    };
  },
  components: {
    Menu,
    Details,
  },
  methods: {
    changeType(val) {
      console.log(val);
      this.activeName = val;
    },
    edit() {
      this.$router.push({
        path: "../createClass",
        query: {
          id: this.gradeId,
        },
      });
    },
    // 展开 / 收起
    fold() {
      this.foldFlag = !this.foldFlag;
    },
    fold_teacher() {
      this.foldFlag_teacher = !this.foldFlag_teacher;
    },
    // 跳转至课表
    toTimetable() {
      if (this.$route.query.timeType == 0) {
        this.$router.push({
          path: "/timetable",
          query: {
            id: this.gradeId,
            timeType: this.$route.query.timeType,
          },
        });
      } else {
        this.$router.push({
          path: "/customTimetable",
          query: {
            id: this.gradeId,
            timeType: this.$route.query.timeType,
          },
        });
      }
    },
    //去排行榜
    goRankingList() {
      this.$router.push({
        path: "/rankingList",
        query: {
          id: this.$route.query.id,
          endGradeTime: this.classInfo.endGradeTime,
        },
      });
    },
    // 切换tab
    handleClick(tab) {
      const { paneName } = tab;
      this.activeName = paneName;
      if (paneName === "curriculum") {
        console.log(111);
        this.getDetails();
      }
      if (paneName === "outline") {
        console.log(222);
        this.getTCOURSENAME();
      }
    },
    getDetails() {
      COURSEDAYSTEA({ gradeId: this.gradeId }).then((res) => {
        console.log(res.data);
        if (res.data.code === 0) {
          this.list = res.data.data;
          localStorage.setItem("sid", res.data.data[0].sid);
        }
      });
    },
    getTCOURSENAME() {
      console.log("大纲");
      //获取讲师课程名称
      GETCOURSENAME({ gradeId: this.gradeId }).then((res) => {
        if (res.data.code == 0) {
          this.teachList = res.data.data;
        }
      });
    },
  },
  created() {
    // this.list = data.data.list
    this.gradeId = this.$route.query.id;
    console.log(this.gradeId);
    if (
      localStorage.getItem("clickId") ==
      JSON.parse(sessionStorage.getItem("loginInfo")).eId
    ) {
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }

    this.getDetails();
    this.getTCOURSENAME();
    // 获取班级上课天数

    // // 获取班级详情
    GETCLASSDETAIL({ gradeId: this.gradeId }).then((res) => {
      if (res.data.code === 0) {
        console.log(res.data);
        this.classInfo = res.data.data;

        // this.$router.push({path: this.$route.path,query:{...this.$route.query, gradeConfId: res.data.data.gradeConfId}})
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.details_wrap {
  background-color: $white;
  // height: 100%;
  padding-top: 80px;

  .content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 80px;
  }

  .details_class_info {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    margin-bottom: 20px;
    background-color: $bg_grey;
    padding: 10px 34px;
    position: relative;

    & > li {
      font-size: 16px;
      width: 356px;
      margin: 5px 0;
      line-height: 28px;
    }
  }

  .details_class_info_label {
    color: $font_color_grey_3;
  }

  .details_class_info_member {
    width: 100% !important;
    display: flex;
  }

  .details_class_info_child {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    position: relative;

    li {
      font-size: 16px;
      // margin-bottom: 10px;
    }
  }

  .details_class_info_edit {
    position: absolute;
    right: 20px;
    width: 30px !important;
  }

  .height {
    height: 28px;
    overflow: hidden;
  }

  .show {
    // display: none;
    position: absolute;
    right: 0;
  }

  .details_class_info_btn {
    color: $theme_color;
    cursor: pointer;
    font-size: 16px;
    margin-right: 34px;
    width: 100px;
    text-align: right;
  }

  .details_content {
    position: relative;
  }

  .details_btns {
    position: absolute;
    right: 0;
    top: 9px;

    span {
      font-size: 18px;
      color: $font_color_grey_3;
      cursor: pointer;
      margin-left: 40px;

      &:hover,
      &:active {
        background: none;
        color: $font_color_grey_3;
      }

      & > i {
        color: $theme_color;
      }
    }
  }

  /deep/ .el-tabs__nav-wrap::after {
    display: none;
  }

  /deep/ .el-tabs__active-bar {
    display: none;
  }

  /deep/ .el-tabs__item {
    font-size: 18px;
    color: $font_color_grey_3;
  }

  /deep/ .el-tabs__item.is-active {
    color: $theme_color;
  }

  /deep/ .el-tabs__header {
    margin-bottom: 10px;
  }
}
</style>
