<template>
  <div>
    <el-row class="wrap_A" justify="center"  v-if="selectAB == 1" :class="[reverse?'box_reverse':'']">
      <el-col :span="12" class="right">

        <!-- 标题 -->
        <div class="top">
          <span class="tap">排序</span>{{itemIndex}}/{{dataLen}}{{item.name}}
        </div>

        <!-- 回顾 -->
        <div class="connect_box_review" v-if="review">
          <div v-if="this.$route.query.index">
            <div v-for="(v,i) in item.answerList" :key="i" style="display:flex;align-items:center;"  class="itemReriew">
              <span v-if="(rightNum[i] == v.rightNum) && opId.includes(v.id)" style="width:20px;height:20px;background:#00C456;color:white;border-radius: 4px;">{{ v.rightNum }}</span>
              <span v-if="(rightNum[i] == v.rightNum) && opId.includes(v.id)" style="width:20px;height:20px;background:#00C456;color:white;border-radius: 4px;">{{ v.rightNum }}</span>
              <div v-else class="itemReriew">
                <span style="background:#00C456;width:20px;height:20px;border-radius: 4px;color:white;">{{ v.rightNum }}</span>
                <span v-if="(rightNum[i] == v.rightNum) && opId.includes(v.id)" style="border:1px solid;background:#00C456;color:white;">{{ rightNum[i] }}</span>
                <span v-if="!(rightNum[i] == v.rightNum) && opId.includes(v.id)" style="width:20px;height:20px;background:#F04B4A;border-radius:4px;color:white;">{{ rightNum[i]}}</span>
                <span v-if="!(rightNum[i] == v.rightNum) && !opId.includes(v.id)" style="border:1px solid #ddd;width:20px;height:20px;border-radius:4px;"></span>
              </div>
              <p :class="[opId.includes(v.id)?'review_blue':'']" >{{ v.content }}</p>
            </div>
          </div>
          <div v-else>
            <div v-for="(v,i) in item.gradeQuAnswers" :key="i" style="display:flex;align-items:center;"  class="itemReriew">
              <span v-if="(rightNum[i] == v.rightNum) && opId.includes(v.id)" style="width:20px;height:20px;background:#00C456;color:white;border-radius: 4px;">{{ v.rightNum }}</span>
              <span v-if="(rightNum[i] == v.rightNum) && opId.includes(v.id)" style="width:20px;height:20px;background:#00C456;color:white;border-radius: 4px;">{{ v.rightNum }}</span>
              <div v-else class="itemReriew">
                <span style="background:#00C456;width:20px;height:20px;border-radius: 4px;color:white;">{{ v.rightNum }}</span>
                <span v-if="(rightNum[i] == v.rightNum) && opId.includes(v.id)" style="border:1px solid;background:#00C456;color:white;">{{ rightNum[i] }}</span>
                <span v-if="!(rightNum[i] == v.rightNum) && opId.includes(v.id)" style="width:20px;height:20px;background:#F04B4A;border-radius:4px;color:white;">{{ rightNum[i]}}</span>
                <span v-if="!(rightNum[i] == v.rightNum) && !opId.includes(v.id)" style="border:1px solid #ddd;width:20px;height:20px;border-radius:4px;"></span>
              </div>
              <p :class="[opId.includes(v.id)?'review_blue':'']" >{{ v.content }}</p>
            </div>
          </div>
        </div>

        <!-- 自测 -->
        <div class="connect_box" v-if="test">
          <div v-for="(v,i) in item.answerList" :key="i" class="item">
            <div v-if="boolVal == false" style="display:flex;align-items:center;">
              <span class="middle_green">{{v.rightNum}}</span>
              <!-- {{v.rightNum}}-{{answer[i]}}-{{arr2[i]}} -->
              <span :class="[(v.rightNum == answer[i])?'middle_green': answer[i]?'middle_red':'middle_normal']">{{answer[i]}}</span>
              <div :class="[arr2.includes(v.rightNum)?'middle_blue':'']">{{v.content}}</div>
            </div>
            <div style="display:flex;align-items:center;" v-else>
              <input type="number" onkeyup="this.value=this.value.replace(/\D|^0/g,'')" onafterpaste="this.value=this.value.replace(/\D|^0/g,'')" oninput="value=value.replace('-', '')" v-model="answer[i]" min="1" @input="handleChange(v)" />
              <p>{{ v.content }}</p>
            </div>
          </div>
        </div>

        <!-- 考试 -->
        <div class="connect_box" v-if="exam">
          <div v-for="(v,i) in item.answerList" :key="i" class="item">
            <div style="display:flex;align-items:center;">
              <input type="number" onkeyup="this.value=this.value.replace(/\D|^0/g,'')" onafterpaste="this.value=this.value.replace(/\D|^0/g,'')" oninput="value=value.replace('-', '')" v-model="answer[i]" min="1" @change="handleChange(v)" />
              <p>{{ v.content }}</p>
            </div>
          </div>
        </div>

        <!-- 解析 -->
        <div v-if="review" class="review_analy">
          <div v-if="!this.$route.query.index" :class="[item.isAnswerRight ?'content_box1':'content_box2']">{{ item.isAnswerRight?'正确':'错误' }}</div>
            <div class="review_analysis">
            <p style="margin-bottom:6px;">答案解析：</p>
            <p style="line-height: 24px;">{{item.analysis}}</p>
          </div>
        </div>
        <div v-else class="analy">
          <div :class="[valIsRight?'content_box1':'content_box2']" v-if="correct">{{ valIsRight?'正确':'错误' }}</div>
          <div class="analysis" v-if="correct">
            <p style="margin-bottom:6px;">答案解析：</p>
            <p style="line-height: 24px;">{{item.analysis}}</p>
          </div>
        </div>

      </el-col>
      <el-col :span="8" class="left">
        <el-image v-if="item.questionsPic"
          style="width: 400px; height: 400px"
          :src="item.questionsPic"
          fit="cover" 
        ></el-image
      ></el-col>
    </el-row>
    <el-row class="wrap_B single_wrap_B" v-else>

      <!-- 标题 -->
      <div class="top">
        <span class="tap">排序</span>{{itemIndex}}/{{dataLen}}{{item.name}}
      </div>

      <!-- 回顾 -->
      <div class="wrap_middle_review" v-if="review && this.$route.query.index">
          <div v-for="(v,i) in item.answerList" :key="i" class="item">
            <el-image
              style="width: 200px; height: 200px"
              fit="cover"
              :src="v.optionPic"
              :class="[opId.includes(v.id)?'review_blues':'']"
              v-if='v.optionPic'
              ></el-image>
              <p v-else>{{v.content}}</p>
              <!-- {{rightNum}}-{{v.rightNum}} -->
            <div v-if="(rightNum[i] == v.rightNum) && opId.includes(v.id)"  class="middle_content">
              <img style="width:14px;height:14px;margin-left:16px;" src="../../assets/images/radio_right.png" alt="">
              <span style="text-align: center;width: 100%;margin-right: 30px;">{{ rightNum[i] }}</span>
            </div>
            <div v-if="(rightNum[i] == v.rightNum) && opId.includes(v.id)"  class="middle_content">
              <span style="text-algin: center;width: 100%;">正确答案:{{ v.rightNum }}</span>
            </div>
            <div v-else>
              <div v-if="(rightNum[i] == v.rightNum) && opId.includes(v.id)" class="middle_content">
                <i style="width:20px;height:20px;border-radius:50%;background:green;"></i>
                <span>{{ item.studentAnswerRelas[i].rightNum }}</span>
              </div>
              <div  v-if="!(rightNum[i] == v.rightNum) && opId.includes(v.id)"  class="middle_content">
                <!-- <i style="width:20px;height:20px;border-radius:50%;background:red;"></i> -->
                <img style="width:14px;height:14px;margin-left:16px;" src="../../assets/images/radio_error.png" alt="">
                <span v-if="rightNum[i]" style="text-align: center;width: 100%;margin-right: 30px;">{{ rightNum[i] }}</span>
                <span v-else style="text-align: center;width: 100%;margin-right: 30px;">未输入</span>
              </div>
              <div class="middle_content" style="justify-content: center;">正确答案:{{ v.rightNum }}</div>
            </div>
          </div>
      </div>
      <div class="wrap_middle_review" v-if="review && !this.$route.query.index">
          <div v-for="(v,i) in item.gradeQuAnswers" :key="i" class="item">
            <el-image
              style="width: 200px; height: 200px"
              fit="cover"
              :src="v.optionPic"
              :class="[opId.includes(v.id)?'review_blues':'']"
              v-if='v.optionPic'
              ></el-image>
              <p v-else>{{v.content}}</p>
              <!-- {{rightNum}}-{{v.rightNum}} -->
            <div v-if="(rightNum[i] == v.rightNum) && opId.includes(v.id)"  class="middle_content">
              <img style="width:14px;height:14px;margin-left:16px;" src="../../assets/images/radio_right.png" alt="">
              <span style="text-align: center;width: 100%;margin-right: 30px;">{{ rightNum[i] }}</span>
            </div>
            <div v-if="(rightNum[i] == v.rightNum) && opId.includes(v.id)"  class="middle_content">
              <span style="text-algin: center;width: 100%;">正确答案:{{ v.rightNum }}</span>
            </div>
            <div v-else>
              <div v-if="(rightNum[i] == v.rightNum) && opId.includes(v.id)" class="middle_content">
                <i style="width:20px;height:20px;border-radius:50%;background:green;"></i>
                <span>{{ item.studentAnswerRelas[i].rightNum }}</span>
              </div>
              <div  v-if="!(rightNum[i] == v.rightNum) && opId.includes(v.id)"  class="middle_content">
                <!-- <i style="width:20px;height:20px;border-radius:50%;background:red;"></i> -->
                <img style="width:14px;height:14px;margin-left:16px;" src="../../assets/images/radio_error.png" alt="">
                <span v-if="rightNum[i]" style="text-align: center;width: 100%;margin-right: 30px;">{{ rightNum[i] }}</span>
                <span v-else style="text-align: center;width: 100%;margin-right: 30px;">未输入</span>
              </div>
              <div class="middle_content" style="justify-content: center;">正确答案:{{ v.rightNum }}</div>
            </div>
          </div>
      </div>

      <!-- 自测 -->
      <div class="wrap_middle" v-if="test">
        <div v-for="(v,i) in item.answerList" :key="i" :class="[v.optionPic?'item':'item_content']">
          <el-image
            style="width: 280px; height: 280px"
            fit="cover"
            :src="v.optionPic"
            v-if='v.optionPic'
            ></el-image>
          <p v-else>{{v.content}}</p>
          <input onkeyup="this.value=this.value.replace(/\D|^0/g,'')" onafterpaste="this.value=this.value.replace(/\D|^0/g,'')" v-if="boolVal || boolVal === undefined" type="number" placeholder="请输入序号" v-model="answer[i]" min="1" @input="handleChange" class="item_input_test"/>
          <p v-else style="text-align: left;">
            <!-- {{v.rightNum}}-{{answer[i]}} -->
            <span v-if="(v.rightNum == answer[i])" style="display: flex;align-items: center;">
              <img style="width:14px;height:14px;margin-left:16px;" src="../../assets/images/radio_right.png" alt="">
              <span style="text-align: center;width: 100%;margin-right: 30px;">{{ answer[i] }}</span>
            </span>
            <span v-else style="display: flex;align-items: center;">
              <img style="width:14px;height:14px;margin-left:16px;" src="../../assets/images/radio_error.png" alt="">
              <span v-if="answer[i]" style="text-align: center;width: 100%;margin-right: 30px;">{{ answer[i] }}</span>
              <span v-else style="text-align: center;width: 100%;margin-right: 30px;">未输入</span>
            </span>
          </p>
          <p v-if="boolVal == false">正确答案：{{ v.rightNum }}</p>
        </div>
      </div>

      <!-- 考试 -->
      <div class="wrap_middle" v-if="exam">
        <div v-for="(v,i) in item.answerList" :key="i" :class="[v.optionPic?'item':'item_content']">
          <el-image
            style="width: 280px; height: 280px"
            fit="cover"
            :src="v.optionPic"
            v-if='v.optionPic'
            ></el-image>
          <p v-else>{{v.content}}</p>
          <input type="number" onkeyup="this.value=this.value.replace(/\D|^0/g,'')" onafterpaste="this.value=this.value.replace(/\D|^0/g,'')" oninput="value=value.replace('-', '')" placeholder="请输入序号" v-model="answer[i]" min="1" @change="handleChange" class="item_input_exam" />
        </div>
      </div>

      <!-- 解析 -->
      <div v-if="review" class="review_analy">
          <div v-if="!this.$route.query.index" :class="[item.isAnswerRight ?'content_box1':'content_box2']">{{ item.isAnswerRight?'正确':'错误' }}</div>
            <div class="review_analysis">
            <p style="margin-bottom:6px;">答案解析：</p>
            <p style="line-height: 24px;">{{item.analysis}}</p>
          </div>
      </div>
      <div v-else class="analy">
          <div :class="[valIsRight?'content_box1':'content_box2']" v-if="correct">{{ valIsRight?'正确':'错误' }}</div>
        <div class="analysis" v-if="correct">
          <p style="margin-bottom:6px;">答案解析：</p>
          <p style="line-height: 24px;">{{item.analysis}}</p>
        </div>
      </div>

    </el-row>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props:['keys','correct','reverse','item','itemIndex','dataLen','boolVal','test','review','exam','arr2','valIsRight'],
  setup(props,context) {
    const selectAB = ref(1)
    console.log(props.item)
    if(props.item){
      if(props.item.answerList) {
        if(props.item.questionsPic || ((!props.item.questionsPic) && !props.item.answerList[0].optionPic)) {
          selectAB.value = 1
        } else {
          selectAB.value = 2
        }
      }
      if(props.item.gradeQuAnswers) {
        if(props.item.questionsPic || ((!props.item.questionsPic) && !props.item.gradeQuAnswers[0].optionPic)) {
          selectAB.value = 1
        } else {
          selectAB.value = 2
        }
      }
    }
    const selfValContent = ref([])
    const answer = ref([])
    if(localStorage.getItem('selfVal') && JSON.parse(localStorage.getItem('selfVal'))[props.itemIndex - 1]) {
      const selfVal = JSON.parse(localStorage.getItem('selfVal'))
      for (let i = 0; i < selfVal[props.itemIndex - 1].length; i++) {
        selfValContent.value.push(selfVal[props.itemIndex - 1][i].rightNum)
      }
      console.log(selfValContent.value)
      answer.value = selfValContent.value
    }
    
    let rightNum = ref([])
    let opId = ref([])

    const handleChange = ()=>{
      const answerList = {
        quId:props.item.id,
        answerList:props.item.answerList.map((v,j)=>{
        return {
          "content": v.content,
          "id": v.id,
          "opId": v.sid,
          "rightNum": answer.value[j] || '',
          'itemIndex': props.itemIndex
        }
       })
      }
      context.emit('handleChange',{bool:true,answerList,id:props.item.id,type:props.item.type})
    }
    const handleIndex=()=>{
      context.emit('handleIndex',props.itemIndex)
    }
    handleIndex()
    if(props.review){
      if(props.item.studentAnswerRelas && props.item.gradeQuAnswers){
        props.item.gradeQuAnswers.forEach((n, i) => {
          props.item.studentAnswerRelas.forEach((m, j) => {
            if(n.id === m.opId) {
              opId.value.push(m.opId)
              rightNum.value.push(m.rightNum)
            }
          });
        });
      }
    }
    console.log(props)
    return {
      answer,
      rightNum,
      opId,
      handleChange,
      selfValContent,
      selectAB,
      handleIndex
    };
  },
};
</script>


<style scoped lang="scss">
* {
  box-sizing: border-box;
}
.box_reverse{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.content_box1 {
  border-radius: 4px;
  border: 1px solid #00c456;
  color: #00c456;
  display: inline-block;
  padding: 2px 6px;
}

.content_box2{
  // width: 30px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid red;
  color: red;
  font-size: 12px;
  line-height: 20px;
  padding: 0 4px;
  display: inline-block;
}

.tap {
  width: 60px;
  height: 30px;
  background: #2e7fff;
  border-radius: 15px;
  color: white;
  margin-right: 10px;
  font-size: 12px;
  padding: 2px 6px;
}

.middle_green{
  display: block;
  font-size: 14px;
  width:20px;
  height:20px;
  background: #00C456;
  margin-right: 10px;
  border-radius:4px;
  line-height: 20px;
  text-align: center;
}
.middle_red{
  display: block;
  font-style: none;
  width:20px;
  height:20px;
  background: #F04B4A;
  margin-right: 10px;
  border-radius:4px;
  line-height: 20px;
  text-align: center;
}
.middle_normal{
  display: block;
  font-style: none;
  width:18px;
  height:18px;
  border: 1px solid #fff;
  margin-right: 10px;
  border-radius:4px;
  text-align: center;
}
.middle_blue{
  // color: blue;
}

.middle_blue_img{
  // border: 2px solid blue;
}

.wrap_A {
  width: 100%;
  height: 100%;
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .right {
    // padding-left:138px;
    .top{
      font-family: PingFangSC-Medium,PingFang SC;
      font-weight: bold;
    }
    .connect_box {
      margin-left: 44px;
      margin-top: 30px;
      .item{
        margin-top: 20px;
        input{
          width:50px;
          // margin:10px;
          border:none;
          border: 1px solid #ddd;
          margin-right: 10px;
        }
      }
      .item:first-child{
        margin-top: 0;
      }
      .itemReriew{
        display: flex;
        align-items: center;
        span{
          width: 20px;
          height: 20px;
          margin: 10px;
          text-align: center;
          line-height: 20px;
        }
      }
    }
    .analy{
      margin-left: 44px;
      margin-top: 40px;
    }
    .connect_box_review{
      margin-left: 40px;
      margin-top: 20px;
      .item{
        input{
          width:50px;
          margin:20px;
          border:none;
          border: 1px solid #ddd;
        }
      }
      .itemReriew{
        display: flex;
        align-items: center;
        margin: 5px 0px;
        span{
          width: 20px;
          height: 20px;
          margin-right: 10px;
          text-align: center;
          line-height: 20px;
        }
      }
      .review_blue{
        color: #333333;
      }
    }
    .review_analy{
      margin-left:40px;
      margin-top: 40px;
      .review_analysis{
        width: 80%;
        // height: 100px;
        background: white;
        border: 1px solid #DDDDDD;
        padding: 10px;
        margin-top: 20px;
        & > p:first-child {
          color: #2e7fff;
        }
      }
    }
    .analy{
      .analysis {
        width: 400px;
        height: auto;
        background: #23243c;
        border: 1px solid #2c2e4c;
        color: white;
        padding: 10px;
        margin-top: 20px;
        & > p:first-child {
          color: #2e7fff;
        }
      }
    }
  }
}
.wrap_B {
  width: 100%;
  height: 100%;
  min-height: 403px;
  padding-top: 80px;
  .top {
    width: 100%;
    // padding-bottom: 20px;
    // padding-left: 138px;
    font-family: PingFangSC-Medium,PingFang SC;
    font-weight: bold;
  }
  .wrap_middle {
    width: 100%;
    height: 100%;
    display: flex;
    // margin-left: 68px;
    text-align: center;
    display: flex;
    text-align: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-left: 40px;
    >div{
      // margin-left: 68px;
    }
    .item_content{
      width: 280px;
    }
    .item,.item_content{
      .item_input_test{
        display: block;
        border: none;
        width: 280px;
        background: #23243c;
        height: 34px;
        color: white;
        text-align: center;
      }
      .item_input_exam{
        display: block;
        border: none;
        width: 100%;
        border: 1px solid #ddd;
        height: 34px;
        color: #333;
        text-align: center;
        margin-top: 10px;
      }
      input::placeholder{
        color: #999;
        text-align: center;
      }
      p {
        display: block;
        border: none;
        width: 280px;
        line-height: 28px;
        background: #23243c;
        text-align: center;
        color: white;
        margin: 10px 0;
        // height: 34px;
      }
    }
  }
  .wrap_middle_review{
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 20px;
    text-align: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: 40px;
    .item{
      width: 280px;
    }
    >div{
      // margin-left: 68px;
    }
    .item {
      input::placeholder{
        color: #999;
        text-align: center;
      }
      p {
        display: block;
        border: none;
        width: 100%;
        height: 28px;
        line-height: 28px;
        text-align: center;
        margin: 10px 0;
        height: 34px;
        border: 1px solid ;
        border-radius: 4px;
      }
    }
    .middle_content{
      width: 100%;
      height: 34px;
      border-radius: 4px;
      border: 1px solid #DDDDDD;
      margin-top: 10px;
      display: flex;
      align-items: center;
      // justify-content: center;
      justify-content: flex-start;
      i{
        font-style: none;
        display: inline-block;
        transform: translateX(-70px);
      }
    }
    .review_blues{
      // border: 1px solid blue;
    }
  }
  .review_analy{
    width:100%;
    margin-left: 40px;
    margin-top: 40px;
    .review_analysis{
      width: 100%;
      background: white;
      border: 1px solid #DDDDDD;
      padding: 10px;
      margin-top: 20px;
      & > p:first-child {
        color: #2e7fff;
      }
    }
  }
  .analy{
    width: 100%;
    margin-top: 40px;
    .analysis {
      width: 100%;
      height: 100px;
      margin-bottom: 20px;
      background: #23243c;
      border: 1px solid #2c2e4c;
      color: white;
      padding: 10px;
      margin-top: 20px;
      & > p:first-child {
        color: #2e7fff;
      }
    }
  }
}
</style>