import { classAttr, createClassApi, getClassListApi, updateClassApi, organList, graderoleuser,getStudentListApi,getTeacherListApi,getTeacherStatus,deleteClass} from './api/class'
import http from '../utils/request';

// 获取班级属性
export const getClassAttr = () => {
  return http.get(classAttr)
}

// 创建班级
export const createClass = (data) => {
  return http.post(createClassApi, data)
}

// 获取班级列表
export const getClassList = (params) => {
  return http.get(getClassListApi, { params })
}

// 更新班级 

export const updateClass = (data) => {
  return http.get(updateClassApi, data)
}

// 获取组织架构
export const getOrganList = (data) => {
  return http.get(organList, data)
}

// graderoleuser
export const getraderoleuser = (data) => {
  return http.get(graderoleuser, data)
}

//获取学员登录班级详情
export const getStudentList = (params) => {
  return http.get(getStudentListApi,{ params })
}

//获取老师登录班级详情
export const getTeacherList = (params) => {
  return http.get(getTeacherListApi,{ params })
}

//配置失败刷新
export const updataStatus = (data) => {
  return http.post(getTeacherStatus,data)
}

//删除班级
export const delClass = (data) => {
  return http.post(deleteClass,data)
}
