<!-- 登陆表单 -->
<template>
  <el-form ref="form" :model="form" class="login_form" :rules="rules">
    <el-form-item prop="username">
      <el-input v-model="form.username" placeholder="请输入手机号" clearable />
    </el-form-item>

    <el-form-item prop="password" class="login_form_password">
      <el-input v-model="form.password" placeholder="输入短信验证码"></el-input>
      <el-button type="primary" class="login_form_captcha" @click="sendMessage">
        <span v-if="sendMessageFlag">
          {{ captchaText }}
        </span>
        <span v-else> {{ timeCounter }}S </span>
      </el-button>
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        :class="{
          login_form_submit: true,
          login_form_submit_disabled: !submitFlag,
        }"
        @click="onSubmit"
        :disabled="isDisabled"
        >登录</el-button
      >
      <!-- <el-button class="login_form_wechat" type="primary" @click="addNewFrame"
        >使用微信登录</el-button
      > -->
    </el-form-item>
  </el-form>
</template>

<script>
import { getRole } from "@/utils/utils";
import { sendMessage, getUserRole, login } from "@/server/login";
// import wxLogin from "../../../utils/wechartLogin/wechartLogin";
import loginAxiosInstance from "../../../utils/wechartLogin/wechatRequest";

export default {
  data() {
    return {
      isDisabled: false,
      form: {
        username: "",
        password: "",
        grantType: "mobile_sms",
        systemCode: "EMPLOYEE",
      },
      rules: {
        username: [
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
          { min: 1, max: 11, message: "请输入正确的手机号", trigger: "blur" },
          { pattern: /^1[3-9]\d{9}$/, message: "请输入正确的手机号" },
        ],
        password: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 1, max: 11, message: "请输入正确的手机号", trigger: "blur" },
        ],
      },
      sendMessageFlag: true,
      captchaText: "获取验证码",
      timeCounter: 59,
      timer: null,
    };
  },
  computed: {
    submitFlag() {
      return this.form.username.length === 11 && this.form.password;
    },
  },

  methods: {
    async addNewFrame() {
      try {
        const wxLogin = await import(
          "../../../utils/wechartLogin/wechartLogin"
        );
        const params = await wxLogin.default();
        params.systemCode = "EMPLOYEE";
        loginAxiosInstance
          .post("/auth/login/in/wechat", this.convertFormData(params))
          .then((res) => {
            this.wechartLogin(res, params);
          });
      } catch (error) {
        this.$message.error("请打包之后使用微信登录！");
      }
    },
    convertFormData(data) {
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      return formData;
    },
    // 更新发送短信秒数
    updateCount() {
      this.timer = setInterval(() => {
        this.timeCounter--;
        if (this.timeCounter === 0) {
          clearInterval(this.timer);
          this.sendMessageFlag = true;
          this.timeCounter = 59;
        }
      }, 1000);
    },
    // 发送短信
    sendMessage() {
      localStorage.clear();
      if (!this.sendMessageFlag) return;
      const reg = /^1[3-9]\d{9}$/;

      if (!this.form.username || !reg.test(this.form.username)) {
        this.$message.warning("请填写正确的手机号码！");
        return;
      }

      sendMessage({ smsCode: 1001, mobile: this.form.username })
        .then(({ data }) => {
          if (data.code === 0) {
            this.$message.success("获取验证码成功， 请注意查收");
            this.sendMessageFlag = false;
            this.updateCount();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch((data) => {
          console.log(data);
          this.$message.error(data.data.msg);
        });
    },

    getRole() {
      getUserRole()
        .then(({ data }) => {
          // console.log(data);
          if (data.code === 0) {
            const arr = [];
            const role = getRole();
            data.data.forEach(({ roleCode }) => {
              arr.push(roleCode);
            });
            // console.log(role)
            // console.log(arr)
            if (role === "teacher") {
              if (arr.indexOf(role) > -1 || arr.indexOf("mater") > -1) {
                this.$router.push({
                  path: "/teachlogin",
                });
                sessionStorage.setItem("role", JSON.stringify(arr));
                localStorage.setItem("role", JSON.stringify(arr));
              } else {
                this.$message.warning("暂无权限");
                sessionStorage.clear();
                localStorage.clear();
              }
            } else {
              if (arr.indexOf(role) > -1 || arr.indexOf("mater") > -1) {
                this.$router.push({
                  path: "/studentlogin",
                });
                sessionStorage.setItem("role", JSON.stringify(arr));
                localStorage.setItem("role", JSON.stringify(arr));
              } else {
                this.$message.warning("暂无权限");
                sessionStorage.clear();
                localStorage.clear();
              }
            }
          }
        })
        .catch((data) => {
          // console.log(data);
          sessionStorage.clear("access_token");
          sessionStorage.clear("loginInfo");
          this.$message.error(data.data.msg);
        });
    },

    // 登录
    onSubmit() {
      this.isDisabled = true;
      setTimeout(() => {
        this.isDisabled = false;
      }, 3000);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.mobile = this.form.username;
          this.form.smsCode = this.form.password;
          this.form.clientId = "renrenio";
          this.form.clientSecret = "renrenio";
          login(this.convertFormData(this.form))
            .then(({ data }) => {
              // console.log(data);
              if (data.code === 0) {
                this.getRole();
                sessionStorage.setItem("access_token", data.data.access_token);
                sessionStorage.setItem("loginInfo", JSON.stringify(data.data));
                localStorage.setItem("access_token", data.data.access_token);
                localStorage.setItem("loginInfo", JSON.stringify(data.data));
              } else {
                this.$message.error(data.msg);
                this.isDisabled = false;
              }
            })
            .catch((data) => {
              // console.log(data);
              this.$message.error(data.data.msg);
            });
        }
      });
    },
  },
  created() {
    sessionStorage.clear();
    localStorage.clear();
    // getRole();
  },
  inject: ["wechartLogin"],
};
</script>
<style lang="scss" scoped>
.login_form {
  width: 300px;
  margin: 0 auto;

  .login_form_submit {
    width: 300px;
    height: 40px;
    margin-top: 58px;
    border-radius: 2px;
  }
  .login_form_wechat {
    width: 300px;
    height: 40px;
    margin-top: 20px;
    border-radius: 2px;
    margin-left: 0;
    background: #07c160;
  }
  .el-form-item {
    margin-bottom: 20px;
  }

  /deep/ .el-input__inner {
    border-radius: 0;
    border: none;
    @include border_item(bottom);
    padding-left: 0;
    padding-right: 0;
  }
  /deep/ .el-input__inner::placeholder {
    color: $font_color_grey;
  }

  .login_form_submit_disabled {
    opacity: 0.5;
  }

  .login_form_password {
    position: relative;

    /deep/ .el-input__inner {
      padding-right: 100px;
    }

    /deep/ .el-input__suffix {
      right: 100px;
    }
  }

  .login_form_captcha {
    position: absolute;
    right: 0;
    padding: 0;
    background: none;
    color: $font_color_grey;
    border: none;
  }

  /deep/ .el-form-item__error {
    left: auto;
    right: 0;
  }
}
</style>
