<!-- 登陆表单 -->
<template>
  <el-form ref="form" :model="form" class="login_form" :rules="rules">
    <el-form-item prop="username">
      <el-input v-model="form.username" placeholder="请输入手机号" clearable />
    </el-form-item>

    <el-form-item prop="password" class="login_form_password">
      <el-input
        v-model="form.password"
        placeholder="输入短信验证码"
      ></el-input>
      <el-button type="primary" class="login_form_captcha" @click="sendMessage">
        <span v-if="sendMessageFlag">
          {{ captchaText }}
        </span>
        <span v-else> {{ timeCounter }}S </span>
      </el-button>
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        :class="{
          login_form_submit: true,
          login_form_submit_disabled: !submitFlag,
        }"
        @click="onSubmit"
        >登录</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { getRole, getHost } from "@/utils/utils";
import { sendMessage, getUserRole } from "@/server/login";
import loginAxiosInstance from "../../../utils/wechartLogin/wechatRequest"
// 不是登录  
const wxlogin = (params) => loginAxiosInstance.post('/auth/login/wechatBind', params)
export default {
  props:['state','code'],
  data() {
    return {
      form: {
        username: "",
        password: "",
        grantType: "mobile_sms",
        systemCode: 'EMPLOYEE',
      },
      rules: {
        username: [
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
          { min: 1, max: 11, message: "请输入正确的手机号", trigger: "blur" },
          { pattern: /^1[3-9]\d{9}$/, message: "请输入正确的手机号" },
        ],
        password: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 1, max: 11, message: "请输入正确的手机号", trigger: "blur" },
        ],
      },
      sendMessageFlag: true,
      captchaText: "获取验证码",
      timeCounter: 59,
      timer: null,
      smsCode:''
    };
  },
  computed: {
    submitFlag() {
      return this.form.username.length === 11 && this.form.password;
    },
  },

  methods: {
    convertFormData(data) {
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      return formData;
    },
    // 更新发送短信秒数
    updateCount() {
      this.timer = setInterval(() => {
        this.timeCounter--;
        if (this.timeCounter === 0) {
          clearInterval(this.timer);
          this.sendMessageFlag = true;
          this.timeCounter = 59;
        }
      }, 1000);
    },
    // 发送短信
    sendMessage() {
      if (!this.sendMessageFlag) return;
      const reg = /^1[3-9]\d{9}$/;

      if (!this.form.username || !reg.test(this.form.username)) {
        this.$message.warning("请填写正确的手机号码！");
        return;
      }

      sendMessage({ smsCode: 1001, mobile: this.form.username }).then(
        ({ data }) => {
          console.log(data)
          if (data.code === 0) {
            this.$message.success("获取验证码成功， 请注意查收");
            this.sendMessageFlag = false;
            this.updateCount();
          } else {
            this.$message.error(data.msg);
          }
        }
      ).catch((data) => {
        this.$message.error(data.data.msg);
      })
    },

    getRole() {
      getUserRole().then(({ data }) => {
        console.log(data)
        if (data.code === 0) {
          const arr = [];
          const role = getRole();
          data.data.forEach(({ roleCode }) => {
            arr.push(roleCode);
          });

          if (
            arr.indexOf(role) > -1 ||
            (role === "teacher" && arr.indexOf("mater") > -1)
          ) {
            if (role === "teacher") {
              this.$router.push({
                path: "/teachlogin",
              });
            } else {
              this.$router.push({
                path: "/studentlogin",
              });
            }

            sessionStorage.setItem("role", JSON.stringify(arr));
          } else {
            if (arr.length) {
              let message =
                arr.indexOf("teacher") > -1 || arr.indexOf("mater") > -1
                  ? "请使用老师端登录"
                  : "";
              if (!message && arr[0] === "student")
                message = "请使用学员端登录";
              this.$message.warning(message);
            } else {
              if (role === "teacher") {
                this.$message.warning("请使用学员端登录");
              } else {
                this.$router.push({
                  path: "/studentlogin",
                });
              }
            }
          }
        }
      });
    },

    // 登录
    onSubmit() {
      let that = this
      this.$refs.form.validate((valid) => {
        if (valid) {
          let formDatas = {
            mobile:that.form.username,
            smsCode:that.form.password,
            systemCode:'EMPLOYEE',
            state:that.state,
            unionId:that.code
          }
          wxlogin(that.convertFormData(formDatas)).then(({ data }) => {
            console.log(data)
            if (data.code === 0) {
              sessionStorage.setItem("access_token", data.data.access_token);
              sessionStorage.setItem("loginInfo", JSON.stringify(data.data));

              that.getRole();
            } else {
              that.$message.error(data.msg);
            }
          });
        }
      });
    },
  },
  created() {
    sessionStorage.clear();
    getRole()
  },
};
</script>
<style lang='scss' scoped>
.login_form {
  width: 300px;
  margin: 0 auto;

  .login_form_submit {
    width: 300px;
    height: 40px;
    margin-top: 58px;
    border-radius: 2px;
  }

  .el-form-item {
    margin-bottom: 20px;
  }

  /deep/ .el-input__inner {
    border-radius: 0;
    border: none;
    @include border_item(bottom);
    padding-left: 0;
    padding-right: 0;
  }
  /deep/ .el-input__inner::placeholder {
    color: $font_color_grey;
  }

  .login_form_submit_disabled {
    opacity: 0.5;
  }

  .login_form_password {
    position: relative;

    /deep/ .el-input__inner {
      padding-right: 100px;
    }

    /deep/ .el-input__suffix {
      right: 100px;
    }
  }

  .login_form_captcha {
    position: absolute;
    right: 0;
    padding: 0;
    background: none;
    color: $font_color_grey;
    border: none;
  }

  /deep/ .el-form-item__error {
    left: auto;
    right: 0;
  }
}
</style>