<template>
  <div class="class_details_wrap">
    <div class="class_details_title" v-if="type === 'curriculum'">
      <span class="date" style="font-size: 16px">日期</span>
      <span style="font-size: 16px">教材内容</span>
    </div>
    <div class="class_details_title class_details_outline" v-else>
      <span style="color: #999; font-size: 16px" class="num">序号</span>
      <span style="color: #999; font-size: 16px" class="name">课程名称</span>
      <span style="color: #999; font-size: 16px; width: 400px" class="name"
        >授课老师</span
      >
      <span style="color: #999; font-size: 16px" class="count">课时</span>
      <span style="color: #999; font-size: 16px" class="name">上课状态</span>

      <span class="do">操作</span>
    </div>
    <el-collapse
      v-model="daySId"
      accordion
      class="class_details_collapse"
      @change="change"
    >
      <el-collapse-item
        v-for="(item, idx) in list"
        :key="item.id"
        :name="item.sid"
      >
        <template #title>
          <span class="theday" v-if="type === 'curriculum'"
            >第{{ item.days }}天
            <span class="that_date">{{ item.date }}</span>
          </span>
          <div
            v-else
            class="class_details_collapse_title"
            @click="getTeachingList(item.name)"
          >
            <span class="num">{{ idx + 1 }}</span>
            <span class="name">
              <el-tooltip
                class="item course_name"
                effect="dark"
                :content="item.name"
                placement="top-start"
              >
                <p style="font-size: 16px; color: #333">
                  {{ String(item.name).substring(0, 20) }}
                </p>
              </el-tooltip>
            </span>
            <div class="teacher" style="width: 400px">
              <template v-if="item.teaList.length > 6">
                <span
                  class="teacherson"
                  v-for="(v, i) in item.teaList"
                  :key="i"
                >
                  <!-- <el-tooltip :content="v.teaName" placement="top-start" class="item" effect="dark"> -->
                  <span v-if="i < 6">{{ v.teaName }}{{ " " }}</span>
                  <!-- </el-tooltip> --> </span
                >...
              </template>
              <template v-else>
                <span
                  class="teacherson"
                  v-for="(v, i) in item.teaList"
                  :key="i"
                >
                  <!-- <el-tooltip :content="v.teaName" placement="top-start" class="item" effect="dark"> -->
                  <span>{{ v.teaName }}{{ " " }}</span>
                  <!-- </el-tooltip> -->
                </span>
              </template>
            </div>
            <span class="count">{{ item.count }}</span>
            <span class="status">
              <span v-if="item.status == '已完课'">{{ item.status }}</span
              ><span v-if="item.status == '上课中'" style="color: red"
                >{{ item.status
                }}<img
                  src="../../../../assets/images/classing.png"
                  style="margin-left: 5px" /></span
              ><span v-if="item.status == '待上课'" style="color: #ff7c02">{{
                item.status
              }}</span>
            </span>

            <span @click="getTeachingList(item.name)" class="do">课程资料</span>
          </div>
        </template>
        <Info
          v-if="type === 'curriculum' && dayList.length > 0"
          :list="dayList"
          :type="type"
          :outLineList="item.infos"
          @fatherMethod="getClassDetail"
        />
        <Info
          v-show="type === 'outline' && teachingList.length > 0"
          :type="type"
          :list="teachingList"
          :outLineList="item.infos"
          @fatherType="getTabType($event)"
        />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
const WEEK_CN = {
  1: "一",
  2: "二",
  3: "三",
  4: "四",
  5: "五",
  6: "六",
  7: "七",
  8: "八",
  9: "九",
  10: "十",
  11: "十一",
  12: "十二",
  13: "十三",
  14: "十四",
  15: "十五",
  16: "十六",
  17: "十七",
  18: "十八",
  19: "十九",
  20: "二十",
  21: "二十一",
  22: "二十二",
  23: "二十三",
  24: "二十四",
  25: "二十五",
  26: "二十六",
  27: "二十七",
  28: "二十八",
  29: "二十九",
  30: "三十",
  31: "三十一",
  32: "三十二",
  33: "三十三",
  34: "三十四",
  35: "三十五",
  36: "三十六",
  37: "三十七",
  38: "三十八",
  39: "三十九",
  40: "四十",
  41: "三十一",
  42: "三十二",
  43: "三十三",
  44: "三十四",
  45: "三十五",
  46: "三十六",
  47: "三十七",
  48: "三十八",
  49: "三十九",
  50: "五十",
};

import Info from "./Info.vue";
import { DAYDETAILTEA, GETCOURSEDETAIL, GETCOURSENAME } from "@/server/details";
// import data from './data.json'
export default {
  data() {
    return {
      dayId: "",
      gradeConfId: 0,
      WEEK_CN,
      dayList: [],
      daySId: "",
      teachingList: [],
      userId: "",
    };
  },
  components: {
    Info,
  },
  props: ["list", "day", "id", "type"],
  watch: {
    day() {
      if (this.day.length) {
        this.dayList = this.day;
        this.dayId = this.id;
      }
    },
    id() {
      this.dayId = this.id;
    },

    list() {
      if (this.type === "outline") this.dayId = this.list[0].id;
    },
  },
  methods: {
    getTabType(type) {
      let name = localStorage.getItem("name");
      this.getTeachingList(name);
      this.$emit("changefatherType", type);
      // this.type = type
    },
    change(e) {
      // console.log(e)

      if (this.type == "curriculum") {
        // console.log(this.daySId)
        if (this.daySId) {
          this.getClassDetail();
        } else {
          console.log("11111");
          return;
        }
      } else {
        // console.log(111);
        // this.getTeachingList()
      }
    },
    getTeachingList(name) {
      this.teachingList = [];
      GETCOURSEDETAIL({ gradeId: this.$route.query.id, name: name })
        .then((res) => {
          // console.log("details components index: ",res.data.data);
          if (res.data.code == 0) {
            this.teachingList = res.data.data;
          }
        })
        .catch((res) => {
          // this.$message.error(res.data.msg)
        });
    },
    // 根据天的ID 取详情
    getClassDetail() {
      console.log(this.daySId);
      if (this.daySId == "" || this.daySId == undefined) {
        // console.log('111')
        this.daySId = localStorage.getItem("sid");
        // return
      }
      DAYDETAILTEA({ daySId: this.daySId, gradeId: this.$route.query.id }).then(
        (res) => {
          if (res.data.code === 0) {
            this.dayList = res.data.data;
          }
        }
      );
      //   GETCOURSENAME({gradeId:this.$route.query.id}).then(res=>{
      //     console.log('111')
      //   if(res.data.code==0) {
      //     this.teachingList = res.data.data
      //   }
      // })
    },
    getTCOURSENAME() {
      console.log("大纲");
      //获取讲师课程名称
      GETCOURSENAME({ gradeId: this.$route.query.id }).then((res) => {
        if (res.data.code == 0) {
          this.teachingList = res.data.data;
        }
      });
    },
  },
  watch: {
    type(val, oldVal) {
      if (val == "outline" && oldVal == "curriculum") {
        this.getTeachingList(this.list[0].name);
      }
    },
  },
  mounted() {
    this.daySId = this.list[0] ? this.list[0].sid : localStorage.getItem("sid");
    this.userId = JSON.parse(sessionStorage.getItem("loginInfo")).eId;
    this.getClassDetail();
    // }
  },
  created() {
    console.log(this.type);
    // console.log(this.list[0].sid)
    // this.daySId = localStorage.getItem('sid')
    // this.getClassDetail()
    // this.gradeConfId = this.$route.query.id || JSON.parse(sessionStorage.getItem('gradeConfInfo')).id
    // this.dayList = data.data
  },
};
</script>

<style lang="scss" scoped>
.class_details_wrap {
  background-color: #fafafa;

  .class_details_title {
    height: 60px;
    line-height: 60px;
    padding-left: 40px;
    font-size: 18px;
    @include border();
    border-bottom: none;
    color: $font_color_grey_3;

    span {
      margin-right: 108px;
    }
  }

  .el-collapse-item.is-active {
    .el-collapse-item__header,
    .el-collapse-item__wrap {
      background-color: #fafafa !important;
      border-bottom: 1px solid #fff;
    }

    .el-collapse-item__arrow {
      transform: rotate(-90deg);
    }
  }

  .el-collapse-item {
    @include border_item();
    @include border_item(right);

    .el-collapse-item__header,
    .el-collapse-item__wrap {
      padding-left: 40px;
      padding-right: 40px;
      // padding-top: 28px;
    }

    .el-collapse-item__header {
      height: 60px;
      line-height: 60px;
      font-size: 16px;
      font-weight: 500;
    }

    .el-collapse-item__header.is-active {
      color: #333;
    }

    .el-collapse-item__content {
      padding-bottom: 15px;
      padding-top: 28px;
    }
  }

  .el-collapse-item__arrow {
    margin: 0 10px;
    line-height: 52px !important;
    transform: rotate(90deg);
    color: #aaaaaa;
    font-size: 14px;
  }

  .class_details_outline {
    span {
      margin-right: 0;
    }
  }

  .class_details_outline,
  .class_details_collapse_title {
    display: flex;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    .num {
      width: 90px;
    }

    .name {
      width: 190px;
      font-size: 16px;
      display: flex;
      align-items: center;
      .course_name {
        line-height: 26px;
        color: #333;
      }
    }

    .count {
      width: 100px;
    }

    .date {
      width: 165px;
    }
    .teacher {
      width: 200px;
      font-size: 16px;
    }
    .status {
      width: 190px;
    }
  }
  .theday {
    margin-left: 10px;
    .that_date {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      background: #f8f8f8;
      padding: 0 4px;
      height: 20px;
      line-height: 20px;
      margin-left: 5px;
    }
  }
}
</style>
