<template>
  <div>
    <el-row class="wrap" justify="center" :class="[reverse?'box_reverse':'']">
      <el-col :span="12" class="right">

        <!-- 标题 -->
        <div class="top">
          <span class="tap">简答</span>{{itemIndex}}/{{dataLen}}{{item.name}}
        </div>
        
        <!-- 回顾 -->
        <div v-if="review" class="review_middle review_tea">
          <el-input v-if="this.$route.query.index" type="textarea" class="textarea_input" :placeholder="item.answerList[0].content"></el-input>
          <el-input v-else type="textarea" v-model="desc.value" class="textarea_input" placeholder="请输入答案"></el-input>
        </div>

        <!-- 自测 -->
        <div v-if="test" class="middle">
            <el-input type="textarea" maxlength="1000" v-model="desc.value" @input="handleChange" class="textarea_input_test"
          placeholder="请输入答案"></el-input>
        </div>

        <!-- 考试 -->
        <div v-if="exam" class="middle">
            <el-input type="textarea" v-model="desc.value" @change="handleChange" class="textarea_input" placeholder="请输入答案"></el-input>
        </div>

        <!-- 解析 -->
        <div v-if="review" class="review_analy">
          <div v-if="!this.$route.query.index" :class="[item.isAnswerRight ?'content_box1':'content_box2']">{{ item.isAnswerRight?'正确':'错误' }}</div>
          <div v-if="!this.$route.query.index" class="review_analysis">
            <p style="margin-bottom:6px;">答案：</p>
            <p style="line-height: 24px;">{{item.gradeQuAnswers[0].content}}</p>
            <!-- <p>{{item.answerList[0].content}}</p> -->
          </div>
          <div class="review_analysis">
            <p style="margin-bottom:6px;">答案解析：</p>
            <p style="line-height: 24px;">{{item.analysis}}</p>
          </div>
        </div>
        <div v-else class="analy">
          <div :class="[valIsRight?'content_box1':'content_box2']" v-if="correct">{{ valIsRight?'正确':'错误' }}</div>
          <div class="analysis" v-if="correct">
            <p style="margin-bottom:6px;">答案：</p>
            <p style="line-height: 24px;">{{item.answerList[0].content}}</p>
          </div>
          <div class="analysis" v-if="correct">
            <p style="margin-bottom:6px;">答案解析：</p>
            <p style="line-height: 24px;">{{item.analysis}}</p>
          </div>
        </div>
        
      </el-col>
      <el-col :span="8" class="left">
        <el-image v-if="item.questionsPic"
          style="width: 400px; height: 400px"
          :src="item.questionsPic"
          fit="cover"
        ></el-image>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import { defineComponent,reactive,ref } from "vue";
export default defineComponent({
  props:['correct','reverse','item','itemIndex','dataLen','boolVal','test','review','exam','valIsRight'],
  setup(props,context) {

    const selfValContent = ref()
    if(localStorage.getItem('selfVal') && JSON.parse(localStorage.getItem('selfVal'))[props.itemIndex - 1]) {
      const selfVal = JSON.parse(localStorage.getItem('selfVal'))
      selfValContent.value = selfVal[props.itemIndex - 1].content
      console.log(selfValContent.value)
    }

    let desc = reactive({value:""});
    desc.value = selfValContent;
    const handleChange = ()=>{
      const answerList = {
        quId: props.item.id,
        answerList:[
          {
            "content": desc.value,
            "id": props.item.answerList[0].id,
            "opId": props.item.answerList[0].sid,
            "rightNum": null,
            'itemIndex': props.itemIndex
          }]
        }
      context.emit('handleChange',{bool:true,answerList,id:props.item.id,type:props.item.type})
    }
    const handleIndex=()=>{
      context.emit('handleIndex',props.itemIndex)
    }
    handleIndex()
    // review
    if((props.review)){
      if(props.item.studentAnswerRelas){
       desc.value = String(props.item.studentAnswerRelas[0].content)
      }
    } 
    return {
      desc,
      handleChange,
      selfValContent,
      handleIndex
    };
  },
});
</script>
<style lang='scss'>
.review_tea{
  textarea::-webkit-input-placeholder {
    color: #00C456 !important;
  }
  textarea::-moz-input-placeholder {
    color: #00C456 !important;
  }
  textarea::-ms-input-placeholder {
    color: #00C456 !important;
  }
}
</style>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
input {
  border: none;
  border-bottom: 1px solid;
  width: 80px;
}
.box_reverse{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.content_box1 {
  border-radius: 4px;
  border: 1px solid #00c456;
  color: #00c456;
  margin: 20px 0;
  display: inline-block;
  padding: 2px 6px;
}
.content_box2 {
  // width: 30px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid red;
  color: red;
  // margin: 20px 0;
  font-size: 12px;
  line-height: 20px;
  padding: 0 4px;
  display: inline-block;
}
.wrap {
  width: 100%;
  height: 100%;
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .right {
    // padding-left:138px;
    .top{
      font-family: PingFangSC-Medium,PingFang SC;
      font-weight: bold;
    }
    .textarea_input{
      padding-left: 40px;
      /deep/.el-textarea__inner,/deep/.el-textarea{
        margin-top: 20px;
        border: none;
        width: 100%;
        height: 150px;
        background: #F8F8F8;
        width: 400px;
        color: #333;
      }
    }
    .analy{
      margin-left: 44px;
      margin-top: 40px;
    }
    .textarea_input_test{
      margin-left: 44px;
      /deep/.el-textarea__inner,/deep/.el-textarea{
        margin-top: 20px;
        border: none;
        width: 100%;
        height: 150px;
        background: #23243c;
        width: 400px;
        color: #fff;
      }
    }
    /deep/.el-textarea__inner::-webkit-input-placeholder {
      color: #999999;
    }
    .tap {
      width: 60px;
      height: 30px;
      background: #2e7fff;
      border-radius: 15px;
      color: white;
      margin-right: 10px;
      font-size: 12px;
      padding: 2px 6px;
    }
    .analy{
      .analysis {
        width: 400px;
        height: 100px;
        background: #23243c;
        border: 1px solid #2c2e4c;
        color: white;
        padding: 10px;
        margin-top: 20px;
        & > p:first-child {
          color: #2e7fff;
        }
      }
    }
    .review_analy{
      padding-left: 40px;
      margin-top: 40px;
      .review_analysis{
        width: 400px;
        // height: 100px;
        border: 1px solid #ddd;
        padding: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
        & > p:first-child {
          color: #2e7fff;
        }
      }
    }
  }
}
</style>