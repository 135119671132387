import { class_date, class_time, class_day, teacher, update_teacher ,extend_course,unuse_day,move_day } from './api/timetable'
import http from '../utils/request';

// 获取课表日期
export const getClassDates = (data) => {
  return http.post(class_date, data)
}

// 获取课表日期
export const getClassTimes = (data) => {
  return http.post(class_time, data)
}

export const getClassDay = data => {
  return http.post(class_day, data)
}

export const getTeacherList = data => {
  return http.post(teacher, data)
}

export const updateTeacher = data => {
  return http.post(update_teacher, data)
}

export const extendCourse = data => {
  return http.post(extend_course, data)
}

export const unuseDay = data => {
  return http.post(unuse_day, data)
}

export const moveDay = data => {
  return http.post(move_day, data)
}