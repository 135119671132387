<template>
  <div class="timetable_wrap">
    <!-- 课程表头部 - 展示时间 -->
    <div class="timetable_header">
      <!-- 向前翻页 -->
      <el-button
        :class="{timetable_text_btn: true, timetable_header_icon: true, timetable_header_icon_disabled: weekPrev}" 
        @click="() => changeWeekBtn(1)"
        icon="el-icon-arrow-left"></el-button>

      <!-- 时间展示 -->
      <b class="timetable_title">{{ title.startTime }} - {{ title.endTime }}</b>

      <!-- 向后翻页 -->
      <el-button
        :class="{timetable_text_btn: true, timetable_header_icon: true, timetable_header_icon_disabled: !weekNext}"
        @click="() => changeWeekBtn(2)"
        icon="el-icon-arrow-right"></el-button>
    </div>

    <!-- 课表星期日期 -->
    <ul class="timetable_days">
      <li class="timetable_days_time" style="line-height: 80px;">时间</li>
      <!-- 每周的时间 - 星期 / 日期 -->
      <li
        v-for="(item, idx) in dateList[page.page]"
        :key="`days_item_${idx}`"
        class="timetable_days_item">
        <p class="timetable_days_item_week" style="font-size:14px;margin-top: 20px;">{{ item.week }}</p>
        <span class="timetable_days_item_date">{{ item.date }}</span>
      </li>
    </ul>

    <ul
      v-for="(items, idx) in timeList"
      :key="`timetable_content_${idx}`"
      :class="{ timetable_content: true, 
      border_bottom_none: items.timeSeg === '晚上' }"
    >
      <li class="timetable_content_item border_right_none">
        <span class="timetable_item_text" v-if="items.timeSeg == 'morning'">上午</span>
        <span class="timetable_item_text" v-if="items.timeSeg == 'afternoon'">下午</span>
        <span class="timetable_item_text" v-if="items.timeSeg == 'night'">晚上</span>
        <div class="timetable_right ">
          <!-- items.timeSegList  上午添加的时间段 -->
          <div class="timetable_content_right border_right_none">
            <!-- 横向表格 -->
            <div
              class="timetable_item border_bottom_none"
              v-for="(item,idx2) in items.timeSegList"
              :key="idx2"
            >
              <div class="day_content" 
                :style="`background-color:${obj.color}`" 
                :class="{'day_content_last':item.list.length==(idx3+1)}" 
                v-for="(obj, idx3) in item.list" 
                :key="obj.id">
                <div
                  class="timetable_item_con border_bottom_none border_right_none"
                  v-if="obj.name">
                <p>{{obj.timeRange}}</p>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="obj.name"
                    placement="top-start"
                  >
                    <p>{{ obj.name.substring(0, 20) }}</p>
                  </el-tooltip>
                  <div v-if="role !== 'student'">
                    <p style="margin-top:4px;color: #3a8ee6;" v-if="obj.teaId " @click="addTeacher(obj)">{{ obj.teaName }}</p>
                    <p style="margin-top:10px" v-else @click="addTeacher(obj)"><i class="el-icon-plus"></i>请添加老师</p>
                  </div>
                  <div v-else>
                    <p style="margin-top:4px;color: #3a8ee6;" v-if="obj.teaId ">{{ obj.teaName }}</p>
                  </div></div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <el-dialog
      title="添加老师"
      v-model="dialogVisible"
      width="40%"
      :before-close="handleClose">
      <el-select v-model="teacName" placeholder="请选择" @change="changeTeac">
        <el-option
          v-for="item in teacherList"
          :key="item.userId"
          :label="item.username"
          :value="item.userId">
        </el-option>
      </el-select>
      <template #footer>
      <span  class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateTeach">确 定</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getRole } from '@/utils/utils.js'
export default {
  name: 'timeTable',
  data() {
    return {
      timeInfo: {}, // 用于保存修改时间的元素信息
      timeType: '', // 保存修改时间 / 添加时间
      days: [], // 用于存放展示的时间 - 第几天
      timeList: [], // 存放请求回的时间数据
      newTimeList: {},
      
      
      timeSeg: '', // 用于存放选择上午下午晚上 
      dayIds: [], // 横向日期id
      dayIdsIndex: {
        id:{},
        idx: {}
      }, // 横向日期id 的下标
      times: [], // 存放时间
      timesObj: {}, // 存放时间 id 的坐标 
      dateList: [],
      page: {
        limit: 7,
        page: 0,
        total: 0
      },
      gradeId: 0,
      title: {
        startTime: '',
        endTime: '',
      },
      role: getRole(),
      getRole: getRole(),
      teacherList: [],
      dialogVisible:false,
      teacName:'',
      teaId:'',
      courseId:'',
      creatorList:[]
    }
  },
  computed: {
    weekPrev () {
      const { total, page, limit } = this.page;

      return (
        page == 0 || 
        total < limit || 
        total == limit || 
        total < limit * (page - 1))
    },
    weekNext () {
      const {total, page, limit} = this.page;

      if (total < limit || total == limit) return false;

      return  total > limit * (page + 1)
    },
  },
  methods: {
    handleClose(){
      this.dialogVisible = false

    },
    changeTeac(e){
      // console.log(e)
      this.teaId = e
    },
    addTeacher(val){
      // console.log(val)
       if(this.teacherList.length<1){
        this.$message.warning('创建班级时未添加老师，暂无老师可添加！');
        return
      }
      this.$http.post('/corporate/university/teacher/grade/role/userGradeRole',{
        gradeId:this.gradeId
      }).then(({ data }) => {
        // console.log(data)
          if (data.code == 0) {
            let arr = []
             for(let i=0;i<data.data.length;i++){
              if(data.data[i].roleCode == 'mater'){
                arr.push(data.data[i].roleCode)
              }
            }
            // console.log(arr)
            if(arr.length>0){
              this.courseId = val.id
              this.teacName = val.teaName
              this.dialogVisible = true
            }else{
              this.$message.warning('暂无权限分配讲师！');
              return
            }
            
          }else{
            this.$message.warning(data.msg);
          }
        })
    },
    updateTeach() {
      this.$http.post('/corporate/university/course/tea/grade/course',{ 
         "id": this.courseId,
          "teaId": this.teaId
       })
        .then(({ data }) => {
          if (data.code === 0) {
            this.$message.success('添加成功！')
            this.getCourseDate()
          }else{
            this.$message.warning(data.msg);
          }
          this.dialogVisible = false
        })
    },
    

    // 点击表格元素跳转至添加课程和编辑课程
    editItem(item) {
      sessionStorage.setItem('timeInfo', JSON.stringify({...item, gradeId: this.gradeId }))

      this.$router.push({
        path: '/addclass'
      })
    },

    // 查询课程日期 横向日期
    getCourseDate() {
      const { page } = this.page
      this.$http.post('/corporate/university/course/find/grade/course/day',{ gradeId: this.gradeId })
      .then(({ data }) => {
        // console.log(data)
        const { data: list } = data
        const dateList = []
        let arr = []
        list.forEach((item) => {
          if (arr.length < 7) {
            arr.push(item)

            if (arr.length === 7) {
              dateList.push(arr)

              arr = []
            }
          }
        })
        let index = this.page.page +1
        let newList = []
        if(index == 1){
          newList = list.slice(0,7)
        }else{
          newList = list.slice((index -1)*7,index*7+1)
        }
        this.dayIds = newList.map(({ sid }, idx) => {
            this.dayIdsIndex.id[sid] = idx;
            this.dayIdsIndex.idx[idx] = sid;
            return sid;
          });
        this.page.total = list.length
        const week = dateList[page]
        this.dateList = dateList
        this.title.startTime = week[0].date
        this.title.endTime = week.slice(-1)[0].date
      
        setTimeout(this.getCourseTimes, 0)
      })
      // 
    },

    // 获取时间项 竖向时间
    getCourseTimes() {
      this.timeList = [
        { timeSeg: "morning", timeSegList: [] },
        { timeSeg: "afternoon", timeSegList: [] },
        { timeSeg: "night", timeSegList: [] },
      ];
      this.times = [
        { timeSeg: "morning", timeSegList: [] },
        { timeSeg: "afternoon", timeSegList: [] },
        { timeSeg: "night", timeSegList: [] },
      ];
      if (this.dayIds.length) {
        this.getCourseList();
      } else {
        this.initTable({});
      }
    },
    // 清空表格中数据
    clear() {
      this.times.forEach(item => {
        // console.log(item)
        if (item.timeSegList.length) {
          item.timeSegList.forEach(o => {
            o.list = []
          })
        }
      })
    },
    // 修改点击切换星期 type: 1: 上一周 / 2: 下一周
    changeWeekBtn(type) {
      if (type === 1 && !this.weekPrev) {
        this.page.page--;
        this.getCourseDate()
      }

      if (type === 2 && this.weekNext) {
        this.page.page++;
        this.getCourseDate()
      }
    },
    
    // 根据日期查询一天的课程详情 组合竖向时间以及每个方格中的内容
    getCourseList() {
      const { page } = this.page
      const gradeId = this.gradeId
      const week = this.dateList[page]
     const arr = []
      week.forEach(item => {
        const data = {
          gradeId,
          dayId: item.sid,
        }
        const timeSegObj = {};
          timeSegObj.dayId = item.sid;
        this.$http
            .post(
              "/corporate/university/course/customerGradeConf/find/grade/course/info/day",
              data
            )
            .then(({ data }) => {
              // console.log(data)
              if (data.code === 0) {
                const resultData = data.data;
                timeSegObj.list = resultData;
              } else {
                this.timeList = [
                  { timeSeg: "morning", timeSegList: [] },
                  { timeSeg: "afternoon", timeSegList: [] },
                  { timeSeg: "night", timeSegList: [] },
                ];
              }
              this.initTable(timeSegObj);
            });
      })
    },
    getClassDayInfo(data) {
      this.$http.post('/corporate/university/course/find/grade/course/info/day',{
        "dayId": data.dayId,
        "gradeId": this.gradeId 
      }).then(({ data }) => {
          if(data.code === 0) {
            let arr = []
            for(let i=0;i<data.data.length;i++){
              if(data.data[i].creator !== ''){
                arr.push(data.data[i])
              }
            }
            this.teacherList = data.data
          }
        })
    },
    // 获取当前班级的讲师
    getTeacherList() {
      this.$http.post('/corporate/university/teacher/grade/role/teacher',{ gradeId: this.gradeId })
        .then(({ data }) => {
          if(data.code === 0) {
            this.teacherList = data.data
          }
        })
    },

    // 初始化表格数据，补齐
    initTable(timeSegObj) {
      // console.log(timeSegObj)
      // console.log(this.timeList)
      if (JSON.stringify(timeSegObj) == "{}") {
        return;
      } 
      this.timeList.forEach((item) => {
        // console.log(item)
        let obj = {};
        obj.day = timeSegObj.dayId;
        obj.dayOrder = this.dayIdsIndex.id[timeSegObj.dayId]
        obj.list = [];
        timeSegObj.list.forEach((timeItem) => {
          if (item.timeSeg === timeItem.timeSegType) {
            obj.list.push(...timeItem.timeSegList);
          }
        });
        item.timeSegList.push(obj);
        item.timeSegList.sort(this.compare('dayOrder'))
      });
      // console.log(this.timeList)
    },
    //数组排序
    compare(property){
      return function(a,b){
        var value1= a[property];
        var value2 = b[property];
        return value1- value2
      }
    },

  },

  created() {
    this.gradeId = this.$route.query.id
    const roles = JSON.parse(sessionStorage.getItem('role'))

    if (getRole() !== 'student' && roles.indexOf('mater') > -1) {
      this.role = 'mater'
      this.getTeacherList()
    }
    
    this.getCourseDate()
    
  }
}
</script>

<style lang="scss" scoped>
.el-select-dropdown__item{
  padding: 0 20px;
}
@mixin border($value: 1px, $lineType: solid, $color: #e6e6e6) {
  border: $value $lineType $color;
}
@mixin border_item(
  $border: left,
  $value: 1px,
  $lineType: solid,
  $color: #e6e6e6
) {
  border-#{$border}: $value $lineType $color;
}
@mixin borderNone($border: bottom) {
  border-#{$border}: none;
}

$white: #fff;
$bg_grey_6: #FAFAFA;
$font_color_grey1: #999;
$font_color_grey2: #BFBFBF;
$theme_color:#2D70F8;
$font_color_black: #333; 

$height: 40px;
.timetable_wrap {
  width: 1136px;
  margin: 20px auto;
  @include border;

  .timetable_header {
    width: 100%;
    height: $height;
    line-height: $height;
    background-color: $bg_grey_6;
    text-align: center;
    font-size: 14px;
    box-sizing: border-box;
  }

  .timetable_header_icon {
    color: $font_color_grey2 !important;
  }

  .timetable_header_icon_disabled {
    opacity: .3;
  }

  .timetable_title {
    margin-right: 9px;
    margin-left: 9px;
  }

  .timetable_days {
    height: 80px;
    @include border_item(top);
    @include border_item(bottom);
    display: flex;
    background-color: $bg_grey_6;
    box-sizing: border-box;

    // li:last-child {
    //   border-right: none;
    // }
  }

  .timetable_days_time {
   width: 121px;
    @include border_item(right);
    text-align: center;
    box-sizing: border-box;
    
  }

  .timetable_days_item {
    position: relative;
    width: 145px;
    text-align: center;
    border-right: 1px solid #e6e6e6;
    box-sizing: border-box;
    &:last-child {
      border-right: none;
    }
  }

  .timetable_days_item_week {
    font-size: 20px;
    margin-bottom: 4px;
  }

  .timetable_days_item_date {
    color: #999;
  }

  .timetable_text_btn {
    padding: 0;
    line-height: 1;
    min-height: auto;
    background: none;
    border: none;
    color: $theme_color;
  }
  
  .timetable_content {
    @include border_item(bottom);
    min-height: 98px;
  }

  .timetable_content_item {
    width: 100%;
    min-height: 98px;
    display: flex;
    // @include border_item(right);
    // @include border_item(bottom);
    
    &:last-child {
      border-bottom: none;
    }
  }

  .timetable_item_text {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 214px;
    // min-height: 98px;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    border-right: 1px solid #e6e6e6;
    // @include border_item(right);
    background-color: $bg_grey_6;
  }

  .timetable_right {
    width: 100%;
  }

  .timetable_content_set {
    width: 97px;
    background-color: $bg_grey_6;
    @include border_item(right);
  }

  .timetable_item {
    width: 144px;
    border-right: 1px solid #e6e6e6;
    .day_content{
      min-height: 98px;
      flex:1;
      @include border_item(bottom);
      .timetable_item_con{
        height: 98px;
        width: 145px;
        position:absolute;
        .timetable_item_con_dropdown{
          position: absolute;
          right: 10px ;
          bottom:10px;
        }
      }
    }
  }

  .timetable_content_item_set {
    width: 137px;
    background-color: $bg_grey_6;
  }

  .timetable_content_item_set_text {
    color: $font_color_black;
  }

  .timetable_content_right {
    width: 100%;
    flex: 1;
    display: flex;
    @include border_item(bottom);
    &:last-child {
      border-bottom: none
    }

    li:last-child {
      border-right: none
    }
  }

  .border_bottom_none {
    @include borderNone();
    min-height: 98px;
  }

  .border_right_none {
    @include borderNone(right);
  }

  .timetable_item_con {
    align-items: unset;
    justify-content: unset;
    // padding: 10px;
    position: relative;
    display: unset;

    p {
      margin-block-start: 0;
      margin-block-end: 0;
      margin-left: 10px;
      &:first-child {
      margin-top: 10px;
    }
    }
  }

  .timetable_item_add_teacher {
    cursor: pointer;
    color: #999;
    // padding-top: 10px;
  }

  .timetable_item_child {
    padding: 10px;
    cursor: pointer;
  }
}
</style>