<template>
  <div>
    <el-row class="wrap_A" justify="center" v-if="keys == 1" :class="[reverse?'box_reverse':'']">
      <el-col :span="12" class="right">

        <!-- 标题 -->
        <div class="top">
          <span class="tap">多选</span>{{itemIndex}}/{{dataLen}}{{item.name}}
        </div>
        <!-- 回顾 -->
        <div v-if="review" class="review_middle">
          <div v-if="this.$route.query.index">
            <div v-for="(v,i) in item.answerList" :key="i" class="reviewMultBox">
              <p :class="[v.isRight==1?'review_green':opId.includes(v.id)&&!v.isRight?'review_red':'reviewMult']"></p>
              <p style="margin:10px 0 10px 10px;"
              :class="[opId.includes(v.id)?'review_blue':'']"
              >{{v.content}}</p>
            </div>
          </div>
          <div v-else>
            <div v-for="(v,i) in item.gradeQuAnswers" :key="i" class="reviewMultBox">
              <p :class="[v.isRight==1?'review_green':opId.includes(v.id)&&!v.isRight?'review_red':'reviewMult']"></p>
              <p style="margin:10px 0 10px 10px;"
              :class="[opId.includes(v.id)?'review_blue':'']"
              >{{v.content}}</p>
            </div>
          </div>
        </div>

        <!-- 自测 -->
        <div v-if="test" class="middle">
          <div v-if='boolVal ===  false && arr2'>
            <div v-for="(v,i) in item.answerList" :key='i' class="minddle_item">
              <i v-if="v.isRight == 1"  class="middle_green"></i>
              <i v-else-if="arr2.includes(v.content) && v.isRight == 0"  class="middle_red"></i>
              <i v-else class="middle_normal"></i>
              <p :class="[arr2.includes(v.content)?'middle_blue':'']">{{v.content}}</p>
            </div>
          </div>
          <el-checkbox-group v-model="checkList" @change="handleChange" v-else class="click_before_che">
            <el-checkbox v-for="(v,i) in item.answerList" :key='i' :label="v" :value='v.content'>{{v.content}}</el-checkbox>
          </el-checkbox-group>
        </div>

        <!-- 考试 -->
        <div v-if="exam" class="middle">
          <el-checkbox-group v-model="checkList" @change="handleChange">
            <el-checkbox v-for="(v,i) in item.answerList" :key='i' :label="v" :value='v.content'>{{v.content}}</el-checkbox>
          </el-checkbox-group>
        </div>

        <!-- 解析 -->
        <div v-if="review" class="review_analy">
          <div v-if="!this.$route.query.index" :class="[item.isAnswerRight ?'content_box1':'content_box2']">{{ item.isAnswerRight?'正确':'错误' }}</div>
          <div class="analysis_review">
            <p style="margin-bottom:6px;">答案解析：</p>
            <div style="line-height: 24px;">{{item.analysis}}</div>
          </div>
        </div>
        <div v-else class="analy">
          <div :class="[valIsRight?'content_box1':'content_box2']" v-if="correct">{{ valIsRight?'正确':'错误' }}</div>
          <div class="analysis" v-if="correct">
            <p style="margin-bottom:6px;">答案解析：</p>
            <div style="line-height: 24px;">{{item.analysis}}</div>
          </div>
        </div>

      </el-col>
      <el-col :span="8" class="left">
        <el-image v-if="item.questionsPic"
          style="width: 400px; height: 400px"
          fit="cover"
          :src="item.questionsPic"
        ></el-image
      ></el-col>
    </el-row>
    <el-row class="wrap_B single_wrap_B" v-else>
      
      <!-- 标题 -->
      <div class="top">
        <span class="tap">多选</span>{{itemIndex}}/{{dataLen}}{{item.name}}
      </div>
      
      <!-- 回顾 -->
      <div class="wrapB_middle_review" v-if="review && this.$route.query.index">
        <div v-for="(v, i) in item.answerList" :key="i" class="reviewMultBoxB">
            <el-image
              style="width: 200px; height: 200px;"
              fit="cover"
              :src="v.optionPic"
              :class="[opId.includes(v.id)?'review_blue_img':'']"
              v-if="v.optionPic"
            ></el-image>
            <div class="content_check">
              <p class="title">{{v.content}}</p>
              <p class="reviewMult" :class="[v.isRight==1?'review_green':opId.includes(v.id)&&!v.isRight?'review_red':'reviewMult']"></p>
            </div>
        </div>
      </div>
      <div class="wrapB_middle_review" v-if="review">
        <div v-for="(v, i) in item.gradeQuAnswers" :key="i" class="reviewMultBoxB">
            <el-image
              style="width: 200px; height: 200px;"
              fit="cover"
              :src="v.optionPic"
              :class="[opId.includes(v.id)?'review_blue_img':'']"
              v-if="v.optionPic"
            ></el-image>
            <p class="title" v-else>{{v.content}}</p>
            <p class="reviewMult" :class="[v.isRight==1?'review_green':opId.includes(v.id)&&!v.isRight?'review_red':'reviewMult']"></p>
        </div>
      </div>

      <!-- 自测 -->
      <div v-if="test">
        <div v-if='boolVal ===  false && arr2' class="wrapB_middle">
          <div v-for="(v, i) in item.answerList" :key="i">
            <el-image
              style="width: 200px; height: 200px"
              fit="cover"
              :src="v.optionPic"
              v-if="v.optionPic"
              :class="[arr2.includes(v.content)?'middle_blue_img':'']"
            ></el-image>
            <p v-else>{{v.content}}</p>
            <i v-if="v.isRight == 1"  class="middle_green"></i>
            <i v-else-if="arr2.includes(v.content) && v.isRight == 0"  class="middle_red"></i>
            <i v-else class="middle_normal"></i>
          </div>
        </div>
        <el-checkbox-group v-model="checkList" @change="handleChange" v-else class="click_before_che">
            <div class="wrapB_middle speckMultip">
                <div v-for="(v, i) in item.answerList" :key="i" class="multips">
                  <el-image
                    style="width: 200px; height: 200px"
                    fit="cover"
                    :src="v.optionPic"
                    v-if="v.optionPic"
                  ></el-image>
                  <p v-else>{{v.content}}</p>
                  <el-checkbox :label="v" class="checkbox">{{""}}</el-checkbox>
                </div>
            </div>
        </el-checkbox-group>
      </div>

      <!-- 考试 -->
      <div v-if="exam">
        <el-checkbox-group v-model="checkList" @change="handleChange">
            <div class="wrapB_middle">
              <div v-for="(v, i) in item.answerList" :key="i">
                <el-image
                  style="width: 200px; height: 200px"
                  fit="cover"
                  :src="v.optionPic"
                  v-if="v.optionPic"
                ></el-image>
                <p v-else>{{v.content}}</p>
                <el-checkbox :label="v" class="checkbox">{{""}}</el-checkbox>
              </div>
            </div>
        </el-checkbox-group>
      </div>

      <!-- 解析 -->
      <div v-if="review" class="review_analy">
        <div v-if="!this.$route.query.index" :class="[item.isAnswerRight ?'content_box1':'content_box2']">{{ item.isAnswerRight?'正确':'错误' }}</div>
        <div class="analysis_review">
          <p>答案解析：</p>
          <div style="line-height: 24px;">{{item.analysis}}</div>
        </div>
      </div>
      <div v-else class="analy">
        <div :class="[valIsRight?'content_box1':'content_box2']" v-if="correct">{{ valIsRight?'正确':'错误' }}</div>
        <div class="analysis" v-if="correct">
          <p>答案解析：</p>
          <div style="line-height: 24px;">{{item.analysis}}</div>
        </div>
      </div>

    </el-row>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
  props:['keys','correct','reverse','item','itemIndex','dataLen','boolVal','arr2','review','exam','test','valIsRight'],
  setup(props,context) {
    const checkList = ref([])
    const opId = ref([])
    const selfValContent = ref([])
    if(localStorage.getItem('selfVal') && JSON.parse(localStorage.getItem('selfVal'))[props.itemIndex - 1]) {
      const selfVal = JSON.parse(localStorage.getItem('selfVal'))
      for (let i = 0; i < selfVal[props.itemIndex - 1].length; i++) {
        selfValContent.value.push(selfVal[props.itemIndex - 1][i].id)
      }
    }
    console.log(selfValContent.value)
    const handleChange = ()=>{
       const answerList = {
         quId: props.item.id,
         answerList:checkList.value.map(v=>{
          return {
            "content": v.content,
            "id": v.id,
            "opId": v.sid,
            "rightNum": null,
            'itemIndex': props.itemIndex
          }
        })  
       }
      context.emit('handleChange',{bool:true,answerList,id:props.item.id,type:props.item.type})
    }
    if(props.item.answerList) {
      for(let i = 0; i < selfValContent.value.length; i++) {
        for(let j = 0; j < props.item.answerList.length; j++) {
          if(selfValContent.value[i] === props.item.answerList[j].id) {
            checkList.value.push(props.item.answerList[j])
          }
        }
      }
    }
    const handleIndex=()=>{
      context.emit('handleIndex',props.itemIndex)
    }
    handleIndex()
    if (props.review) {
      if(props.item.studentAnswerRelas){
        opId.value = props.item.studentAnswerRelas.map(v=>{
          return v.opId
        })
      }
    }
    console.log(props)
    return {
      checkList,
      opId,
      handleChange,
      selfValContent,
      handleIndex
    };
  },
});
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}
.box_reverse{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.content_box1 {
    border-radius: 4px;
    border: 1px solid #00c456;
    color: #00c456;
    margin: 20px 0;
    display: inline-block;
    padding: 2px 6px;
}
.content_box2 {
  // width: 30px;
  display: inline-block;
  height: 20px;
  border-radius: 4px;
  border: 1px solid red;
  color: red;
  font-size: 12px;
  line-height: 20px;
  padding: 0 4px;
  margin-top: 20px;
}
.reviewMult{
  width:16px;
  height:16px;
  // border:1px solid #DDDDDD;
  background: url('../../assets/images/checkbox_def_grey.png') no-repeat;
  background-size: 16px 16px;
}
.review_red{
  width:16px;
  height:16px;
  // background: red;
  background: url('../../assets/images/checkbox_error.png') no-repeat;
  background-size: 16px 16px;
  border: none;
}
.review_green{
  width:16px;
  height:16px;
  // background: green;
  background: url('../../assets/images/checkbox_right.png') no-repeat;
  background-size: 16px 16px;
  border: none;
}

.middle_green{
  display: block;
  font-style: none;
  width:16px;
  height:16px;
  background: green;
  margin-right: 10px;
  background: url('../../assets/images/checkbox_right.png') no-repeat;
  background-size: 16px 16px;
  border: none;
}
.middle_red{
  display: block;
  font-style: none;
  width:16px;
  height:16px;
  background: red;
  margin-right: 10px;
  background: url('../../assets/images/checkbox_error.png') no-repeat;
  background-size: 16px 16px;
  border: none;
}
.middle_normal{
  display: block;
  font-style: none;
  width:16px;
  height:16px;
  border: 1px solid #fff;
  margin-right: 10px;
  border-radius: 4px;
}
.middle_blue{
  color: blue;
}

.wrap_A {
  width: 100%;
  height: 100%;
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .middle{
    margin-top: 30px;
  }
  .right {
    // padding-left:138px;
    .top{
      font-family: PingFangSC-Medium,PingFang SC;
      font-weight: bold;
    }
    .el-checkbox {
      display: block;
      margin-top: 20px;
    }
    .el-checkbox:first-child{
      margin-top: 0;
    }
    .right_bottom {
      padding-top: 50px;
      .time {
        padding: 0 50px 0 20px;
      }
    }
    .tap {
      width: 60px;
      height: 30px;
      background: #2e7fff;
      border-radius: 15px;
      color: white;
      margin-right: 10px;
      font-size: 12px;
      padding: 2px 6px;
    }
    .review_middle{
      margin-left:40px;
      margin-top:20px;
      .reviewMultBox{
        display: flex;
        align-items: center;
      }
      .review_blue{
        color: blue;
      }
    }
    .middle{
      margin-left: 44px;
      .minddle_item{
        margin: 20px 0;
        display: flex;
        align-items: center;
      }
    }
    .review_analy{
      width:100%;
      margin-left:40px;
      .analysis_review{
        width: 80%;
        height: 100px;
        background: white;
        border: 1px solid #DDDDDD;
        padding: 10px;
        margin-top: 20px;
        & > p:first-child {
          color: #2e7fff;
        }
      }
    }
    .analy{
      width:400px;
      margin-left: 44px;
      .analysis {
        width: 100%;
        height: 100px;
        background: #23243c;
        border: 1px solid #2c2e4c;
        color: white;
        padding: 10px;
            margin-top: 20px;
        & > p:first-child {
          color: #2e7fff;
        }
      }
    }
  }
}
.wrap_B {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  padding-top: 80px;
  .content_check{
    display: flex;
    align-items: center;
    .title{
      // margin-top: 10px;
      margin-right: 10px;
    }
  }
  .top {
    width: 100%;
    padding-bottom: 20px;
    // padding-left: 138px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    .tap{
      width: 60px;
      height: 30px;
      background: #2e7fff;
      border-radius: 15px;
      color: white;
      margin-right: 10px;
      font-size: 12px;
      padding: 2px 6px;
    }
  }
  .wrapB_middle{
    width: 1200px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    // margin-left: 68px;
    justify-content: space-between;
    >div{
      margin-left: 68px;
    }
    .checkbox{
      width: 100%;
      display: block;
      text-align: center;
      margin-top: 10px;
    }
    i{
      margin: 0  auto;
    }
    .middle_blue_img{
      border: 2px solid blue;
    }
    &.speckMultip{
      // flex-direction: column;
      .multips{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        // flex-direction: row-reverse;
        // justify-content: flex-end;
        // align-items: flex-start;
        // margin-bottom: 20px;
        .checkbox{
          width: 50px;
        }
        p{
          flex: 1;
        }
      }
    }
  }
  .analy{
    width:100%;
    .analysis {
      width: 100%;
      height: 100px;
      background: #23243c;
      border: 1px solid #2c2e4c;
      color: white;
      padding: 10px;
      margin: 20px;
      & > p:first-child {
        color: #2e7fff;
      }
    }
    .content_box2{
      margin-left: 20px;
    }
  }
  .wrapB_middle_review {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: 40px;
    justify-content: space-between;
    .reviewMultBoxB{
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title{
        text-align: center;
      }
    }
    >div{
      // margin-left: 68px;
    }
    .checkbox{
      width: 100%;
      display: block;
      text-align: center;
    }
    .review_blue_img{
      // border: 1px solid blue; // bugID1001931说有蓝边，所以注解
    }
    .reviewMultBoxB{
      .reviewMult{
        margin: 10px auto;
      }
    }
  }
  .review_analy{
    width:100%;
    margin-left:40px;
    margin-top: 20px;
    .analysis_review{
      background: white;
      border: 1px solid #DDDDDD;
      padding: 10px;
      margin-top: 20px;
      & > p:first-child {
        color: #2e7fff;
      }
    }
  }
}
</style>