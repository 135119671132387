<template>
  <div id="wxCodeContainer"></div>
</template>

<script>
import "@/utils/wxLogin.js";
import { getWechatQrCode } from "@/server/login";
export default {
  data() {
    return {
      appid: "",
      redirect_uri: "",
      state: "",
    };
  },
  mounted() {
    this.getCode();
  },
  methods: {
    wxInit() {
      console.log(encodeURIComponent(this.redirectUri));
      const obj = new WxLogin({
        self_redirect: false,
        id: "wxCodeContainer", // 需要显示的容器id
        appid: this.appid, // 公众号appid wx*******
        scope: "snsapi_login", // 网页默认即可
        redirect_uri: encodeURIComponent(this.redirectUri), // 授权成功后回调的
        state: this.state, // 可设置为简单的随机数加session用来校验
        style: "black", // 提供"black"、"white"可选。二维码的样式
        href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE5OHB4OyBoZWlnaHQ6IDE5OHB4O21hcmdpbi10b3A6MDt9DQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6IG5vbmU7fQ0KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9DQoud3JwX2NvZGUge2Rpc3BsYXk6IGZsZXh9DQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7fQ==", // 外部css文件url，需要https
      })
    },
    getCode() {
      getWechatQrCode({ systemCode: "EMPLOYEE" }).then((data) => {
        let res = data.data;
        if (res.code === 0) {
          console.log(JSON.stringify(data.data))
          let {appId,state, redirectUri} = res.data
          this.appid = appId;
          this.state = state;
          this.redirectUri = redirectUri;
          this.wxInit();
        }
      });
    },
  },
};
</script>

<style lang="scss">
#wxCodeContainer {
  text-align: center;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  overflow: hidden;
}
</style>