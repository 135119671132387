<template>
  <ul class="index_wrap">
    <li class="index_item">
      <h3 class="index_title">开班中</h3>
      <div class="index_item_content">
        <div v-if="openClass && openClass.length">
          <Curriculum
            v-for="item in openClass"
            :key="item.id"
            classStatus="open"
            :item="item" />
        </div>
        <div v-else>暂无课程</div>
      </div>
    </li>

    <li class="index_item">
      <h3 class="index_title">预开班</h3>
      <div class="index_item_content">
        <div v-if="preOpenClass && preOpenClass.length">
          <Curriculum
            v-for="item in preOpenClass"
            :key="item.id"
            classStatus="pre"
            :item="item" />
        </div>
        <div v-else>暂无课程</div>
      </div>
    </li>

    <li class="index_item">
      <h3 class="index_title">
        已结束
        <span>（{{ endClass ? endClass.length : 0 }}）</span>
        <i :class="[foldFlag ? 'el-icon-arrow-down' : 'el-icon-arrow-up', 'index_item_icon']" @click="fold" v-if="endClass.length>0"></i>
      </h3>
      <div v-show="foldFlag" class="index_item_content">
        <div v-if="endClass && endClass.length">
          <Curriculum
            v-for="item in endClass"
            :key="item.id"
            classStatus="end"
            :item="item" />
        </div>
        <div v-else>暂无课程</div>
      </div>
    </li>
  </ul>
</template>

<script>
import Curriculum from '../Home/componets/Curriculum.vue'
import {
  getTeacherList
} from '@/server/class.js'
export default {
  data() {
    return {
      foldFlag: true,
      eId: '',
      endClass: [],
      openClass: [],
      preOpenClass: [],
    }
  },
  components: {
    Curriculum
  },
  methods: {
    // 展开收起已结束课程
    fold() {
      this.foldFlag = !this.foldFlag
    },
    getClassList(){
      getTeacherList({userId: JSON.parse(sessionStorage.getItem('loginInfo')).eId}).then((res)=>{
        // console.log(res.data);
        if(res.data.code == 0){
           this.preOpenClass = res.data.data.preOpenClass
           this.endClass = res.data.data.endClass
           this.openClass = res.data.data.openClass
          //  console.log(this.preOpenClass);
        }
    })
    }
  },
  created() {
    this.getClassList()
    // this.eId = JSON.parse(sessionStorage.getItem('loginInfo')).eId
    
  }
}
</script>

<style lang="scss" scoped>
.index_wrap {
  margin-top: 20px;
  .index_title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #333;
    display: flex;
    align-items: center;
    line-height: 33px;
  }

  .index_item_icon {
    width: 24px;
    height: 24px;
    background-color: $bg_grey_4;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    color: $theme_color;
    font-size: 12px;
  }

  .index_item {
    width: 1200px;
    margin-bottom: 20px;
  }

  .index_item_content > div {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    & > div:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>