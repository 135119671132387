<template>
  <div class="v-table">
    <!-- 考试成绩 -->
    <el-table
          v-if="questionType == '1'"
          :data="studentList"
          >
          <el-table-column prop="date" label="选项" width="80">
            <template #default="scope">
               <span>{{ getLetter(scope.$index) }}</span>
             </template>
          </el-table-column>

          <el-table-column prop="name" label="" width="130">
             <template v-slot="scope">
                <span v-if="users[scope.$index]">
                  <svg-icon v-if="studentList[scope.$index].isRight == 1" :iconClass="'check'" class="icon icon_r"></svg-icon>
                  <svg-icon v-else :iconClass="'error'" class="icon icon_e"></svg-icon>
                </span>
             </template>
          </el-table-column>
          <el-table-column prop="usersLength" label="选择人数" width="130">
            <template v-slot="scope">
              <span v-if="users[scope.$index]">
                {{ users[scope.$index].length }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="学员">
            <template v-slot="scope">
              <span v-if="users[scope.$index]">
                {{ users[scope.$index].toString() }}
              </span>
            </template>
          </el-table-column>
          
    </el-table>
    <!-- 回顾题目 -->
     <el-table
          v-else
          :empty-text='"暂无数据"'
          :data="studentList">
          <el-table-column prop="date" label="序号" width="80" >
            <template #default="scope">
               <span>{{ scope.$index + 1 }}</span>
               <!-- <span>{{ getLetter(scope.$index) }}</span> -->
             </template>
          </el-table-column>
          <el-table-column prop="date" label=""  width="130">
            <template #default="scope">
               <svg-icon :iconClass="'check'" class="icon icon_r" v-if='scope.row.isAnswerRight == 1'></svg-icon>
               <svg-icon :iconClass="'error'" class="icon icon_e" v-else></svg-icon>
               <!-- <span >{{ scope.row.isAnswerRight }}</span> -->
             </template>
          </el-table-column>
          <el-table-column prop="userName" label="学员" width="130" />
          <el-table-column prop="contents" label="答案" />
          
    </el-table>
  </div>
  
  
</template>

<script>
import http from "@/utils/request";

export default {
  data() {
    return {
      first:require("@/assets/images/icon_first.png"),
      second:require("@/assets/images/icon_second.png"),
      third:require("@/assets/images/icon_third.png"),
      studentList:[],
      questionType: '',
      users: []
    };
  },
  props: ['list', 'visivle', 'endIndex', 'nextID'],
  computed: {
    getLetter(){
      return (num)=>{
        return String.fromCharCode(65+num)
      }
    }
  },
  watch: {
  },
  methods: {
    viewTestQuInfo(parme) {
      const datas ={
        "paperId": this.$route.query.paperId,
        "quId": this.list[parme].id
      };
      http.post("/corporate/university/teacher/exam/viewTestQuInfo", datas).then((res) => {
        if (res.data.code === 0) {
          this.studentList = res.data.data
          console.log(this.studentList)
          for (let i = 0; i < this.studentList.length; i++) {
            this.users[i] = []
            if (this.studentList[i].users && this.studentList[i].users.length > 0) {
              for (let j = 0; j < this.studentList[i].users.length; j++) {
                this.users[i].push(this.studentList[i].users[j].userName)
              }
            } else {
              // this.users[i].push('')
            }

          }
          
          console.log(this.users)
        }
      }).catch((e) => {});
    }
  },
  created() {
    this.viewTestQuInfo(this.nextID)
    if(this.list[this.nextID].type == 0) {
      this.questionType = '1'
    } else {
      this.questionType = '2'
    }
  }
}
</script>

<style lang="scss" scope>
.v-table {
  width: 100%;
  .el-table{
    th{
      background-color: #fafafa;
    }
  }
  .icon-yes{
    display: block;
    width: 8px;
    height: 16px;
    border-color: red;
    border-style: solid;
    border-width: 0 3px 5px 0;
    transform: rotate(45deg);
  }
  .icon{
    font-size: 28px;
    display: block;
  }
  .icon_r{
    width: 26px;
    height: 26px;
    color: #00C456;
    margin-left: 2px;
  }
  .icon_e{
    width: 30px;
    height: 30px;
  }
  .bage{
     width: 32px;
    height: 32px;
    border-radius: 32px;
    text-align: center;
    line-height: 32px;
    color: #999999;
    background-color: #DDDDDD;
  }
}
.v-table{
  border: 1px solid #ddd !important;
}
</style>