<template>
  <div>
    <el-row class="wrap single_wrap_B" justify="center" :class="[reverse?'box_reverse':'']">
      <el-col :span="12" class="right">
        
        <!-- 标题 -->
        <div class="top">
          <span class="tap">判断</span>{{itemIndex}}/{{dataLen}}{{item.name}}
        </div>

        <!-- 回顾 -->
        <div v-if="review && this.$route.query.index" class="review_middle">
          <div v-for="(v,i) in item.answerList" :key="i" class="reviewMultBox">
            <p :class="[v.isRight==1?'review_green':opId.includes(v.id)&&!v.isRight?'review_red':'reviewMult']"></p>
            <p style="margin:10px 0 10px 10px;"
             :class="[opId.includes(v.id)?'review_blue':'']">{{v.content}}</p>
          </div>
        </div>
        <div v-if="review && !this.$route.query.index" class="review_middle">
          <div v-for="(v,i) in item.gradeQuAnswers" :key="i" class="reviewMultBox">
            <p :class="[v.isRight==1?'review_green':opId.includes(v.id)&&!v.isRight?'review_red':'reviewMult']"></p>
            <p style="margin:10px 0 10px 10px;"
             :class="[opId.includes(v.id)?'review_blue':'']">{{v.content}}</p>
          </div>
        </div>

        <!-- 自测 -->
        <div v-if="test" class="middle">
          <div v-if="!boolVal">
            <div v-for="(v,i) in item.answerList" :key="i" class="middle_itemlist_test">
              <img src="../../assets/images/radio_right.png" v-if="v.isRight" alt="">
              <img src="../../assets/images/radio_error.png" v-else alt="">
              <!-- <p style="width:15px;height:15px;border:1px solid;background:green;border-radius:50%;margin:10px 20px;color:white;" v-if="v.isRight"></p> -->
              <!-- <p style="width:15px;height:15px;border:1px solid;background:red;border-radius:50%;margin:10px 20px;color:white;" v-else></p> -->
              <p>{{v.content}}</p>
            </div>
          </div>
          <div v-else class="click_before">
            <el-radio-group v-model="radio" @change="handleChange">
              <el-radio :label="v" style="display: block" v-for="(v,i) in item.answerList" :key="i">{{v.content}}</el-radio>
            </el-radio-group>
          </div>
        </div>

        <!-- 考试 -->
        <div v-if="exam" class="middle">
          <el-radio-group v-model="radio" @change="handleChange">
            <el-radio :label="v" style="display: block" v-for="(v,i) in item.answerList" :key="i">{{v.content}}</el-radio>
          </el-radio-group>
        </div>

        <!-- 解析 -->
        <div v-if="review" style="" class="review_analy">
          <div v-if="!this.$route.query.index" :class="[item.isAnswerRight ?'content_box1':'content_box2']">{{ item.isAnswerRight?'正确':'错误' }}</div>
          <div class="analysis_review">
            <p style="margin-bottom:6px;">答案解析：</p>
            <div style="line-height: 24px;">{{item.analysis}}</div>
          </div>
        </div>
        <div v-else class="analy">
          <div :class="[valIsRight?'content_box1':'content_box2']" v-if="correct">{{ valIsRight?'正确':'错误' }}</div>
          <div class="analysis" v-if="correct">
            <p style="margin-bottom:6px;">答案解析：</p>
            <div style="line-height: 24px;">{{item.analysis}}</div>
          </div>
        </div>

      </el-col>
      <el-col :span="8" class="left">
        <el-image style="width: 400px; height: 400px" fit="cover" v-if="item.questionsPic"
        :src="item.questionsPic"></el-image
      ></el-col>
    </el-row>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
  props:['correct','reverse','item','itemIndex','dataLen','test','review','exam','valIsRight'],
  setup(props,context) {
    const radio = ref()
    const boolVal= ref(true)
    const opId = ref([])
    boolVal.value = props.correct?false:true
    const selfValContent = ref()
    if(localStorage.getItem('selfVal') && JSON.parse(localStorage.getItem('selfVal'))[props.itemIndex - 1]) {
      const selfVal = JSON.parse(localStorage.getItem('selfVal'))
      selfValContent.value = selfVal[props.itemIndex - 1].id
    }
    console.log(selfValContent.value)
    const handleChange = (val)=>{
       const answerList = {
         quId: props.item.id,
         answerList:[
           {
            "content": val.content,
            "id": val.id,
            "opId": val.sid,
            "rightNum": null,
            'itemIndex': props.itemIndex
          }
         ]
       }
       
      if(val.isRight === 1){
        if(props.test){
          boolVal.value = true
        }
        context.emit('handleChange',{bool:true,answerList,id:props.item.id,type:props.item.type})
      }else{
        if(props.test){
          boolVal.value = false
        }
        context.emit('handleChange',{bool:false,answerList,id:props.item.id,type:props.item.type})
      }
    }
    console.log(radio.value)
    console.log(props)
    if(props.item.answerList) {
      for(let j = 0; j < props.item.answerList.length; j++) {
        if(selfValContent.value === props.item.answerList[j].id) {
          radio.value = props.item.answerList[j]
        }
      }
    }
    const handleIndex=()=>{
      context.emit('handleIndex',props.itemIndex)
    }
    handleIndex()
    if (props.review) {
      if(props.item.studentAnswerRelas){
        opId.value = props.item.studentAnswerRelas.map(v=>{
          return v.opId
        })
      }
    }
    return {
      radio,
      boolVal,
      opId,
      handleChange,
      selfValContent,
      handleIndex
    };
  },
});
</script>
<style lang="scss">
.click_before{
  .el-radio__inner{
    background: url('../../assets/images/radio_def.png') no-repeat;
    background-size: 16px 16px;
    border: none;
    width: 16px;
    height: 16px;
  }
}
.click_before_che{
  .el-checkbox__inner{
    background: url('../../assets/images/checkbox_def.png') no-repeat;
    background-size: 16px 16px;
    border: none;
    width: 16px;
    height: 16px;
  }
  .el-checkbox__inner::after{
    left: 6px;
    top: 3px;
  }
}
</style>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
.box_reverse{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.wrap {
  width: 100%;
  height: 100%;
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .content_box1 {
    border-radius: 4px;
    border: 1px solid #00c456;
    color: #00c456;
    display: inline-block;
    padding: 2px 6px;
    margin-top: 20px;
  }
  .content_box2 {
    // width: 30px;
    display: inline-block;
    height: 20px;
    border-radius: 4px;
    border: 1px solid red;
    color: red;
    font-size: 12px;
    line-height: 20px;
    padding: 0 4px;
    margin-top: 20px;
  }
  .right {
    // padding-left:138px;
    .top{
      font-family: PingFangSC-Medium,PingFang SC;
      font-weight: bold;
    }
    .el-radio-group {
      // padding: 0px 30px;
    }
    .el-radio {
      margin-top: 20px;
    }
    .el-radio:first-child{
      margin-top: 0;
    }
    .tap {
      width: 60px;
      height: 30px;
      background: #2e7fff;
      border-radius: 15px;
      color: white;
      margin-right: 10px;
      font-size: 12px;
      padding: 2px 6px;
    }
    .review_middle{
      margin-top: 20px;
      .reviewMultBox{
        display: flex;
        align-items: center;
        padding-left: 40px;
      }
      .reviewMult{
        width:16px;
        height:16px;
        // border:1px solid;
        // border-radius: 50%;
        background: url('../../assets/images/radio_def_grey.png') no-repeat;
        background-size: 16px 16px;
      }
      .review_red{
        width:16px;
        height:16px;
        // border-radius: 50%;
        // background: red;
        background: url('../../assets/images/radio_error.png') no-repeat;
        background-size: 16px 16px;
      }
      .review_green{
        width:16px;
        height:16px;
        // border-radius: 50%;
        // background: green;
        background: url('../../assets/images/radio_right.png') no-repeat;
        background-size: 16px 16px;
      }
      .review_blue{
        color: blue;
      }
    }
    .middle{
      margin-top: 30px;
      margin-left: 44px;
      .middle_itemlist_test{
        display: flex;
        margin-top: 20px;
        img{
          margin-right: 10px;
          width:16px;
          height:16px;
          color:white;
        }
      }
      .middle_itemlist_test:first-child{
        margin-top: 0;
      }
    }
    .analy{
      margin-left: 44px;
      margin-top: 40px;
    }
    .review_analy{
        width:100%;
        padding-left: 40px;
        .analysis_review{
          width: 60%;
          background: white;
          border: 1px solid #DDDDDD;
          padding: 10px;
          margin-top: 20px;
          & > p:first-child {
            color: #2e7fff;
          }
        }
    }
    .analy{
      .analysis {
        width: 100%;
        height: 100px;
        background: #23243c;
        border: 1px solid #2c2e4c;
        color: white;
        padding: 10px;
        margin-top: 20px;
        & > p:first-child {
          color: #2e7fff;
        }
      }
    }
  }
}
</style>