<template>
  <div class="dialog">
    <el-dialog v-if="beforeClose !== 7"
      :title="dialogTitle"
      width="30%"
      v-model="visible.value">
      <div>
        <slot name="content"></slot>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button  @click="handleCancel">{{btnNormal}}</el-button>
          <el-button v-if="btnPrimary != 'false'" type="primary" @click="handleConfirm">{{btnPrimary}}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-else
      :before-close="handleClose"
      :title="dialogTitle"
      width="30%"
      v-model="visible.value">
      <div>
        <slot name="content"></slot>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button  @click="handleCancel">{{btnNormal}}</el-button>
          <el-button v-if="btnPrimary != 'false'" type="primary" @click="handleConfirm">{{btnPrimary}}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {defineComponent,reactive} from 'vue'
export default defineComponent({
  props:{
    dialogTitle:String,
    dialogVisible:Boolean,
    btnNormal:String,
    btnPrimary:String,
    beforeClose:String
  },
  setup(props,content){
    const visible = reactive(props.dialogVisible)
    const handleCancel = () => {
      content.emit('handleCancel')
    }
    const handleConfirm = () => {
      content.emit('handleConfirm')
    }
    const handleClose = () => {
      console.log('1222222222')
    }
    console.log(props)
    return {
      visible,
      handleConfirm,
      handleClose,
      handleCancel
    }
  }
})
</script>

<style>
.dialog{
  z-index: 100;
}
</style>