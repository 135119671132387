<template>
  <div>
    <el-row class="wrap_A" justify="center" v-if="keys == 1" :class="[reverse?'box_reverse':'']">
      <el-col :span="12" class="right">
        
        <!-- 标题 -->
        <div class="top">
          <span class="tap">单选</span>{{itemIndex}}/{{dataLen}}{{item.name}}
        </div>
        <!-- 回顾 -->
        <div v-if="review && this.$route.query.index" style="margin-left:40px;margin-top:20px" class="review_middle">
          <div v-for="(v,i) in item.answerList" :key="i" class="reviewMultBox">
            <p :class="[v.isRight==1?'review_green':opId.includes(v.id)&&!v.isRight?'review_red':'reviewMult']"></p>
            <p style="margin:10px 0 10px 10px;"
             :class="[opId.includes(v.id)?'review_blue':'']">{{v.content}}</p>
          </div>
        </div>
        <div v-if="review" style="margin-left:40px;margin-top:20px" class="review_middle">
          <div v-for="(v,i) in item.gradeQuAnswers" :key="i" class="reviewMultBox">
            <p :class="[v.isRight==1?'review_green':opId.includes(v.id)&&!v.isRight?'review_red':'reviewMult']"></p>
            <p style="margin:10px 0 10px 10px;"
             :class="[opId.includes(v.id)?'review_blue':'']">{{v.content}}</p>
          </div>
        </div>

        <!-- 自测 -->
        <div v-if='test' class="middle">
          <div v-if="!boolVal">
            <div v-for="(v,i) in item.answerList" :key="i" style="display:flex;align-items:center;" class="middle_itemlist_test">
              <!-- <p :class="[v.isRight?'middle_green':radio&&radio.id == v.id?'middle_red':'middle_normal']"></p> -->
              <p v-if="selfValId" :class="[v.isRight?'middle_green':(selfValId == v.id)?'middle_red':'middle_normal']"></p>
              <p v-else :class="[v.isRight?'middle_green':(radio&&radio.id == v.id)?'middle_red':'middle_normal']"></p>
              <p :class="[radio&&radio.id == v.id?'middle_blue':'']">{{v.content}}</p>
            </div>
          </div>
          <div v-else>
            <el-radio-group v-model="radio" @change="handleChange">
              <el-radio :label="v" style="display: block" v-for="(v,i) in item.answerList" :key="i">{{v.content}}</el-radio>
            </el-radio-group>
          </div>
        </div>

        <!-- 考试 -->
        <div v-if='exam' class="middle">
          <el-radio-group v-model="radio" @change="handleChange">
            <el-radio :label="v" style="display: block" v-for="(v,i) in item.answerList" :key="i">{{v.content}}</el-radio>
          </el-radio-group>
        </div>

        <!-- 解析 -->
        <div v-if="review" class="review_anal">
          <div v-if="!this.$route.query.index" :class="[item.isAnswerRight ?'content_box1':'content_box2']">{{ item.isAnswerRight?'正确':'错误' }}</div>
          <div class="analysis_review">
            <p style="margin-bottom:4px;">答案解析：</p>
            <div style="line-height: 24px;">{{item.analysis}}</div>
          </div>
        </div>
        <div v-else class="analy">
          <div :class="[valIsRight?'content_box1':'content_box2']" v-if="correct">{{ valIsRight?'正确':'错误' }}</div>
          <div class="analysis" v-if="correct">
            <p style="margin-bottom:4px;">答案解析：</p>
            <div style="line-height: 24px;">{{item.analysis}}</div>
          </div>
        </div>

      </el-col>
      <el-col :span="8" class="left">
        <el-image v-if="item.questionsPic"
          style="width: 400px; height: 400px"
          :src="item.questionsPic"
          fit="cover"
        ></el-image
      ></el-col>
    </el-row>


    <el-row class="wrap_B single_wrap_B" v-else>
      <!-- 标题 -->
      <div class="top">
        <span class="tap">单选</span>{{itemIndex}}/{{dataLen}}{{item.name}}
      </div>

      <!-- 回顾 -->
      <div v-if="review && this.$route.query.index" class="wrapB_middle_review">
        <div v-for="(v, i) in item.answerList" :key="i" class="reviewMultBoxB">
          <el-image
            style="width: 200px; height: 200px;"
            fit="cover"
            :src="v.optionPic"
            :class="[opId.includes(v.id)?'review_blue_img':'']"
            v-if="v.optionPic"
          ></el-image>
          <div class="content_check">
            <p class="title">{{String.fromCharCode(65 + i)}}</p>
            <p class="reviewMult" :class="[v.isRight==1?'review_green':opId.includes(v.id)&&!v.isRight?'review_red':'reviewMult']"></p>
          </div>
        </div>
      </div>
      <div v-if="review" class="wrapB_middle_review">
        <div v-for="(v, i) in item.gradeQuAnswers" :key="i" class="reviewMultBoxB">
          <el-image
            style="width: 200px; height: 200px;"
            fit="cover"
            :src="v.optionPic"
            :class="[opId.includes(v.id)?'review_blue_img':'']"
            v-if="v.optionPic"
          ></el-image>
          <p v-else>{{v.content}}</p>
          <p class="reviewMult" :class="[v.isRight==1?'review_green':opId.includes(v.id)&&!v.isRight?'review_red':'reviewMult']"></p>
        </div>
      </div>

      <!-- 自测 -->
      <div v-if="test" class="wrapB_middle">
        <div  v-for="(v,i) in item.answerList" :key="i" style="display:flex;align-items:center;">
          <div v-if="!boolVal">
            <el-image
              style="width: 200px; height: 200px"
              fit="cover"
              :src="v.optionPic"
              :class="[radio&&radio.id == v.id?'middle_blue_img':'']"
              v-if="v.optionPic"
            ></el-image>
            <p v-else>{{v.content}}</p>
            <p v-if="selfValId" :class="[v.isRight?'middle_green':(selfValId == v.id)?'middle_red':'middle_normal']"></p>
            <p v-else :class="[v.isRight?'middle_green':(radio&&radio.id == v.id)?'middle_red':'middle_normal']"></p>
            <!-- <p v-if="valIsRight" :class="[v.isRight?'middle_green':(radio&&radio.id == v.id)?'middle_red':'middle_normal']"></p> -->
            <!-- <p v-else :class="[v.isRight?'middle_green':'middle_red']"></p> -->
          </div>
          <div v-else class="click_before">
            <el-image
              style="width: 200px; height: 200px"
              fit="cover"
              :src="v.optionPic"
             v-if="v.optionPic"
            ></el-image>
            <p v-else>{{v.content}}</p>
            <el-radio v-model="radio" :label="v"  @change="handleChange(v)">{{ '' }}</el-radio>
          </div>
        </div>
      </div>

      <!-- 考试 -->
      <div v-if="exam" class="wrapB_middle">
        <div  v-for="(v,i) in item.answerList" :key="i">
          <el-image
            style="width: 200px; height: 200px"
            fit="cover"
            :src="v.optionPic"
            v-if="v.optionPic"
          ></el-image>
          <p v-else>{{v.content}}</p>
          <el-radio v-model="radio" :label="v"  @change="handleChange(v)">{{ '' }}</el-radio>
        </div>
      </div>
      
      <!-- 解析 -->
      <div v-if="review" class="review_anal">
        <div v-if="!this.$route.query.index" :class="[item.isAnswerRight ?'content_box1':'content_box2']">{{ item.isAnswerRight?'正确':'错误' }}</div>
        <div class="analysis_review">
          <p>答案解析：</p>
          <div style="line-height: 24px;">{{item.analysis}}</div>
        </div>
      </div>
      <div v-else  class="analy">
          <div :class="[valIsRight?'content_box1':'content_box2']" v-if="correct">{{ valIsRight?'正确':'错误' }}</div>
        <div class="analysis" v-if="correct">
          <p>答案解析：</p>
          <div style="line-height: 24px;">{{item.analysis}}</div>
        </div>
      </div>

    </el-row>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
  props:['keys','correct','reverse','item','itemIndex','dataLen','test','review','exam','valIsRight','qusionList'],
  setup(props, context) {
    const radio = ref()
    if(props.qusionList) {
     let idsArr = Object.keys(props.qusionList)
     idsArr && idsArr.length >0 && idsArr.forEach(item=>{
       if(item == props.item.id){
        let checkid = props.qusionList[item].answerList[0].id
        props.item.answerList.forEach(ans => {
          if(ans.id == checkid){
             radio.value = ans
          }
        })

       }
     })
    }
    
    
    const selfValId = ref()
    if(localStorage.getItem('selfVal') && JSON.parse(localStorage.getItem('selfVal'))[props.itemIndex - 1]) {
      const selfVal = JSON.parse(localStorage.getItem('selfVal'))
      selfValId.value = selfVal[props.itemIndex - 1].id
      console.log(selfValId.value)
    }

    const boolVal= ref(true)
    const opId = ref([])
    boolVal.value = props.correct?false:true
    const handleChange = (v)=>{
       const answerList = {
         quId:props.item.id,
         answerList:[
           {
            "content": v.content,
            "id": v.id,
            "opId": v.sid,
            "rightNum": null,
            'itemIndex': props.itemIndex
          }
         ]
       }
      boolVal.value = v.isRight
      context.emit('handleChange',{bool:v.isRight,answerList,id:props.item.id,type:props.item.type,checkRadio:v})
    }
    const handleIndex=()=>{
      context.emit('handleIndex',props.itemIndex)
    }
    handleIndex()
    if (props.review) {
      if(props.item.studentAnswerRelas){
        opId.value = props.item.studentAnswerRelas.map(v=>{
          return v.opId
        })
      }
    }

    console.log(props);
    return {
      radio,
      boolVal,
      opId,
      handleChange,
      selfValId,
      handleIndex
    };
  },
});
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}
.box_reverse{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.content_box2 {
  // width: 30px;
  display: inline-block;
  height: 20px;
  border-radius: 4px;
  border: 1px solid red;
  color: red;
  // margin: 20px 0;
  font-size: 12px;
  line-height: 20px;
  padding: 0 4px;
  margin-top: 20px;
}
.content_box1 {
  border-radius: 4px;
  border: 1px solid #00c456;
  color: #00c456;
  margin-top: 20px;
  display: inline-block;
  padding: 2px 6px;
}
.tap {
  width: 60px;
  height: 30px;
  background: #2e7fff;
  border-radius: 15px;
  color: white;
  margin-right: 10px;
  font-size: 12px;
  padding: 2px 6px;
}
.reviewMult{
  width:16px;
  height:16px;
  // border:1px solid;
  border-radius: 50%;
  background: url('../../assets/images/radio_def_grey.png') no-repeat;
  background-size: 16px 16px;
}
.review_red{
  width:16px;
  height:16px;
  // border-radius: 50%;
  background: red;
  background: url('../../assets/images/radio_error.png') no-repeat;
  background-size: 16px 16px;
  border: none;
}
.review_green{
  width:16px;
  height:16px;
  // border-radius: 50%;
  background: green;
  background: url('../../assets/images/radio_right.png') no-repeat;
  background-size: 16px 16px;
  border: none;
}


.middle_green{
  display: block;
  font-style: none;
  width:16px;
  height:16px;
  background: green;
  margin-right: 10px;
  background: url('../../assets/images/radio_right.png') no-repeat;
  background-size: 16px 16px;
  border: none;
}
.middle_red{
  display: block;
  font-style: none;
  width:16px;
  height:16px;
  background: red;
  margin-right: 10px;
  background: url('../../assets/images/radio_error.png') no-repeat;
  background-size: 16px 16px;
  border: none;
}
.middle_normal{
  display: block;
  font-style: none;
  width:16px;
  height:16px;
  border: 1px solid;
  margin-right: 10px;
  border-radius:50%;
}
.middle_blue{
  color: blue;
}

.middle_blue_img{
  border: 2px solid blue;
}

.wrap_A {
  width: 100%;
  height: 100%;
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .right {
    // padding-left:138px;
    .top{
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
    }
    .el-radio-group {
      // padding: 10px 30px;
    }
    .el-radio {
      margin-top: 20px;
    }
    .el-radio:first-child{
      margin-top: 0;
    }
    .review_middle{
      .reviewMultBox{
        display: flex;
        align-items: center;
      }
      .review_blue{
        color: blue;
      }
    }
    .middle{
      margin-left: 44px;
      margin-top: 30px;
      .middle_itemlist_test{
        margin-top: 20px;
      }
      .middle_itemlist_test:first-child{
        margin-top: 0;
      }
    }
    .review_anal{
      width:100%;
      margin-left:40px;
      margin-top: 20px;
      .analysis_review{
        width: 60%;
        background: white;
        border: 1px solid #DDDDDD;
        padding: 10px;
        margin-top: 20px;
        & > p:first-child {
          color: #2e7fff;
        }
      }
    }
    .analy{
      width:400px;
      margin-left: 44px;
      margin-top: 40px;
      .analysis {
        width: 100%;
        height: 100px;
        background: #23243c;
        border: 1px solid #2c2e4c;
        color: white;
        padding: 10px;
        margin-top: 20px;
        & > p:first-child {
          color: #2e7fff;
        }
      }
    }
  }
}

.wrap_B {
  width: 100%;
  height: 100%;
  // padding: 80px;
  padding-top: 80px;
  // min-height: 403px;
  .content_check{
    display: flex;
    align-items: center;
    justify-content: center;
    .title{
      margin-right: 10px;
    }
    .reviewMult {
      margin: 10px 0 !important;
    }
  }
  .top {
    width: 100%;
    padding-bottom: 20px;
    // padding-left: 138px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
  }
  .wrapB_middle {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 44px;
    >div{
      // margin-left: 68px;
    }
    p{
      margin: 0 auto;
    }
  }
  .wrapB_middle_review{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    // margin-left: 68px;
    >div{
      margin-left: 68px;
    }
    .review_blue_img{
      border: 1px solid blue;
    }
    .reviewMultBoxB{
      .reviewMult{
        margin: 10px auto;
      }
    }
  }
  .review_anal{
    width:100%;
    margin-left:40px;
    margin-top: 20px;
    .analysis_review{
      margin-top: 20px;
      width: 100%;
      background: white;
      border: 1px solid #DDDDDD;
      padding: 10px;
      & > p:first-child {
        color: #2e7fff;
      }
    }
  }
  .analy{
    width:100%;
    margin-left: 44px;
    .analysis {
      width: 100%;
      height: 100px;
      background: #23243c;
      border: 1px solid #2c2e4c;
      color: white;
      padding: 10px;
      margin-top: 20px;
      & > p:first-child {
        color: #2e7fff;
      }
    }
    .content_box2{
      // margin-left: 20px;
    }
  }
  .el-radio{
    display: flex;
    justify-content: center;
  }
}

</style>
<style lang="scss">
.single_wrap_B, .wrap{
  .el-image{
    text-align: center;
    .el-image__inner{
      width: 100%;
      object-fit: contain !important;
    }
  }
}
</style>