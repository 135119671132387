<!--  -->
<template>
<div>
  <Menu :menuTitle="title"/>
  <div class="details_wrap">
    <div class="content">

      <div class="details_content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="tabName" name="curriculum">
            <Details :type="activeName" :list="list" :parameType='parameType' />
          </el-tab-pane>
          <el-tab-pane label="回顾题目" name="outline">
            <Details :type="activeName" :list="teachList"/>
          </el-tab-pane>
        </el-tabs>

      </div>
    </div>
  </div>
</div>

</template>

<script>
import Menu from '@/components/Menu'
import Details from './components/Details/index.vue'
// import {COURSEDAYSTEA, GETCLASSDETAIL, GETCOURSENAME} from '@/server/details'
import http from "@/utils/request";

export default {
  data () {
    return {
      title: '礼仪知识学习考试',
      foldFlag: false,
      foldFlag_teacher:false,
      activeName: "curriculum",
      list:  [],//考试成绩列表
      teachList:[],//回顾题目列表
      gradeId:'',
      tabName: '',
      parameType: ''
    };
  },
  components: {
    Menu,
    Details
  },
  methods: {
    // 展开 / 收起
    fold() {
      this.foldFlag = !this.foldFlag
    },
    fold_teacher() {
      this.foldFlag_teacher = !this.foldFlag_teacher
    },
    // 跳转至课表
    toTimetable() {
      this.$router.push({
        path: '/timetable',
        query: {
          id: this.gradeId
        }
      })
    },
    //去排行榜
    goRankingList() {
      this.$router.push({path:'/rankingList',query:{id:this.$route.query.id}})
    },
    // 切换tab
    handleClick(tab) {
      const { paneName } = tab
      this.activeName = paneName
      // if (paneName === 'curriculum') {
      //   console.log(999)
      // }

      // if (paneName === 'outline') {
      //   console.log(999)
      // }
    },
    getList(){
      // 获取数据
      const datas ={
        "examType": 4,
        "gradeConfId": this.$route.query.gradeConfId,
        "gradeId": this.$route.query.gradeId,
        "paperId": this.$route.query.paperId,//
      };
      const datas1 ={
        "examType": this.$route.query.type,
        "gradeConfId": this.$route.query.gradeConfId,
        "gradeId": this.$route.query.gradeId,
        "paperId": this.$route.query.paperId,//
      };
      http.post("/corporate/university/teacher/exam/reviewTestByPaperId", datas).then((res) => {
        if (res.data.code === 0) {
         this.teachList = res.data.data
         
        }
      });
      let typeParme
      if(this.$route.query.type == '4') {
        typeParme = 'findStrongResultByPaperId'
        this.tabName = '巩固记录'
      } else if(this.$route.query.type == '5') {
        typeParme = 'findTestResultByPaperId'
        this.tabName = '考试成绩'
      }
      http.post("/corporate/university/teacher/exam/" + typeParme, datas1).then((res) => {
        if (res.data.code === 0) {
        this.list = res.data.data
        }
      });


    }
  },
  created () {
    // this.list = data.data.list
    this.title = localStorage.getItem('testName')
    this.gradeId = this.$route.query.id
    console.log(this.gradeId);
    this.getList();
    this.parameType = this.$route.query.type
  }
}

</script>
<style lang='scss' scoped>
.details_wrap {
  background-color: $white;
  // height: 100%;
  padding-top: 80px;

  .content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 80px;
  }

  .details_class_info {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    margin-bottom: 20px;
    background-color: $bg_grey;
    padding: 24px 24px 0;
    position: relative;

    & > li {
      font-size: 18px;
      width: 356px;
      margin-bottom: 10px;
    }
  }

  .details_class_info_label {
    color: $font_color_grey_3;
  }

  .details_class_info_member {
    width: 100% !important;
    display: flex;
    
  }

  .details_class_info_child {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    position: relative;

    li {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }


  .details_class_info_edit {
    position: absolute;
    right: 20px;
    width: 30px !important;
  }

  .height {
    height: 28px;
    overflow: hidden;
  }

  .show {
    // display: none;
    position: absolute;
    right: 0;
  }

  .details_class_info_btn {
    color: $theme_color;
    cursor: pointer;
  }

  .details_content {
    position: relative;
    margin-top: 40px;
  }

  .details_btns {
    position: absolute;
    right: 0;
    top: 9px;

    span {
      font-size: 16px;
      color: $font_color_grey_3;
      cursor: pointer;
      margin-left: 40px;

      &:hover,
      &:active {
        background: none;
        color: $font_color_grey_3;
      }

      & > i {
        color: $theme_color;
      }
    }
  }
/deep/.el-tabs__item{
    padding:  0 30px;
}
/deep/.el-tabs__header{
    margin: 0;
    margin-bottom: 24px;
}
  /deep/ .el-tabs__nav-wrap::after {
    display: none;
  }

  /deep/ .el-tabs__active-bar {
    display: none;
  }

  /deep/ .el-tabs__item {
    font-size: 18px;
    color: $font_color_grey_3;
  }

  /deep/ .el-tabs__item.is-active {
    color: $theme_color;
  }
  /deep/.el-table th{
    height: 60px;
  }
}
</style>