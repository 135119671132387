<template>
  <div>
    <Menu :menuTitle="fileType == 4 ? '巩固' : '考试'" @closeTest='closeTest' :test='true'/>
  <div class="wrap">
    <!-- keys 1 === 单选按钮 2 === 单选图片 -->
    <div style="position: relative;height: 500px;" v-if="loadingTrue"><img style="width:160px;position: absolute;left: 50%; top:200px" src="../../assets/images/loading2.gif"></div>
    <div v-for="(v, i) in data" :key="i" style="width:1200px;margin:0 auto;">
      <div v-if="i == nextID">
        <Single
          v-if="v.type === 0"
          :keys="[v.questionsPic?1:2]"
          @handleChange="handleChange"
          @handleIndex="handleIndex"
          :reverse="true"
          :item="v"
          :qusionList="qusionList"
          :dataLen="data.length"
          :itemIndex="i + 1"
          :exam="true"
        ></Single>
        <Multiple
          v-if="v.type === 1"
          :keys="[v.questionsPic?1:2]"
          @handleChange="handleChange"
          @handleIndex="handleIndex"
          :reverse="true"
          :item="v"
          :qusionList="qusionList"
          :dataLen="data.length"
          :itemIndex="i + 1"
          :exam="true"
        ></Multiple>
        <Judge
          v-if="v.type === 2"
          @handleChange="handleChange"
          @handleIndex="handleIndex"
          :reverse="true"
          :item="v"
          :qusionList="qusionList"
          :dataLen="data.length"
          :itemIndex="i + 1"
          :exam="true"
        ></Judge>
        <Completion
          v-if="v.type === 3"
          @handleChange="handleChange"
          @handleIndex="handleIndex"
          :reverse="true"
          :item="v"
          :qusionList="qusionList"
          :dataLen="data.length"
          :itemIndex="i + 1"
          :exam="true"
        ></Completion>
        <Answer
          v-if="v.type === 4"
          @handleChange="handleChange"
          @handleIndex="handleIndex"
          :reverse="true"
          :item="v"
          :qusionList="qusionList"
          :dataLen="data.length"
          :itemIndex="i + 1"
          :exam="true"
        ></Answer>
        <Sort
          v-if="v.type === 6"
          :keys="[v.questionsPic?1:2]"
          @handleChange="handleChange"
          @handleIndex="handleIndex"
          :reverse="true"
          :item="v"
          :qusionList="qusionList"
          :dataLen="data.length"
          :itemIndex="i + 1"
          :exam="true"
        ></Sort>
      </div>
    </div>
    <div class="bottom">
      <div class="right_bottom">
      <el-button
        size="mini"
        type="primary"
        @click="handleNext"
        v-if="endIndex !== data.length"
        >下一个</el-button
      >
      <el-button size="mini" class="question_board" @click="handleOpen">{{ openText }}</el-button>
      <el-button size="mini" class="question_board" @click="handleSubmit" style="margin-right:10px">交卷</el-button>
      剩余时间<span v-if="this.$route.query.duration === '1'" class="time"> {{timetext}}秒</span><span v-else class="time"> {{timetext}}</span>
    </div>
    <div class="table" v-show="tabShow">
      <p
        class="table_item"
        v-for="(v,i) in data"
        :key="i"
        @click="handleTab(v,i)"
        :class="[activeTab.includes(v.id) ? 'active_tab' : activer ==  i?'activer':'']"
      >
        {{ i+1 }}
      </p>
    </div>
    </div>
    <div :class="[isTimeOut == true ?'isTimeOut examination_loading':'examination_loading']">
      <Loading
        v-model="dialogVisible"
        :dialogVisible="dialogVisible"
        :dialogTitle="dialogTitle.value"
        @handleCancel="handleCancel"
        @handleConfirm="handleConfirm"
        :btnNormal="btnNormalText"
        :btnPrimary="btnPrimaryText"
        :beforeClose="dislogType"
      >
        <template #content>
          <div v-if="dislogType == 1">
            <p>还有{{ num ? num :0 }}道题未做</p>
            <p>点击[确定交卷],将提交考试成绩结束考试!</p>
          </div>
          <div v-if="dislogType == 2">
            <p>还有{{ num ? num :0 }}道题未做</p>
            <p>退出后将不记录本次考试成,确定要直接退出考试吗？</p>
          </div>
          <!-- 巩固 -->
          <!-- <div v-if="dislogType == 3">
            <p>共有{{50}}道题,预计{{40}}分钟</p>
          </div> -->
          <div v-if="dislogType == 4">
            <p>还有{{ num ? num :0 }}道题未做,确定退出吗?</p>
          </div>
          <div v-if="dislogType == 5">
            <p>本次巩固有{{ wrongNum }}道错题，正确率{{ (rightRate * 100).toFixed(2) }}%</p>
          </div>
          <div v-if="dislogType == 7">
            <p>时间已到</p>
          </div>
        </template>
      </Loading>
    </div>
  </div>
  </div>
</template>

<script>
import Single from "@/components/AnswerComponents/single.vue"; // 单选
import Multiple from "@/components/AnswerComponents/multiple.vue"; // 多选
import Judge from "@/components/AnswerComponents/judge.vue"; // 判断
import Completion from "@/components/AnswerComponents/completion.vue"; // 填空
import Sort from "@/components/AnswerComponents/sort.vue"; // 排序
import Answer from "@/components/AnswerComponents/answer.vue"; // 简答
import Loading from "@/components/AnswerComponents/loading.vue"; // 退出框
import Menu from '@/components/Menu'
import http from "@/utils/request";
import { ref, reactive,onBeforeUnmount,toRaw, computed } from "vue";
import {useRouter,useRoute} from 'vue-router';
import { ElMessage } from 'element-plus'
import {StartStudy,EndStudy} from '@/server/details'

export default {
  props: {
    type: Number,
  },
  components: {
    Single,
    Multiple,
    Judge,
    Completion,
    Sort,
    Answer,
    Loading,
    Menu,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const nextID = ref(0);
    const loadingTrue = ref(true);
    const tabShow = ref(false);
    const data = ref([]);
    const openText = ref("展开题板");
    // 弹出框
    const dialogVisible = reactive({ value: false });
    const isTimeOut = ref(false);
    const dialogTitle = reactive({value:"交卷确认"});
    const btnNormalText = ref('取消')
    const btnPrimaryText = ref('确定')
    const activeTab = ref([]);
    const qusionList = ref([]);
    const num = ref(0);
    const list = ref([])
    const times = ref('')
    const timetext = ref('不限时')
    const activer = ref(0)
    const dislogType = ref(1)
    const rightRate = ref(0)
    const wrongNum = ref(0)
    const fileType = ref(0)
    const fileType1 = ref(0)
    const checkRadio = ref()
    let que = toRaw(route.query)
    const gradeId = que.gradeId
    const gradeConfId = que.gradeConfId
    const selfVal = ref()
    localStorage.removeItem('selfVal')

    fileType.value = route.query.fileType
    // 获取数据
    const datas = { paperId: route.query.paperId, gradeId: route.query.gradeId, gradeConfId: route.query.gradeConfId,examType: route.query.fileType};
    http.post("/corporate/university/exam/startExam", datas).then((res) => {
      loadingTrue.value = false
      if (res.data.code === 0) {
        data.value = res.data.data.map((v) => {
          if (v.answerList) {
            v.answerList = v.answerList.sort((x, y) => {
              return x.opSeq - y.opSeq;
            });
          }
          return v;
        });
      }
    }).catch((res)=>{
        ElMessage.error(res.data.msg)
    })
    // 开始学习
      StartStudy({
        "bussId": route.query.paperId,
        "bussType": fileType.value,//学习类型(1预习 2自测 3课件 4巩固 5考试)	
        "gradeConfId": route.query.gradeConfId,
        "gradeId": route.query.gradeId,//班级id	
        // "studyTime": 0
      }).then(res=>{
        if(res.data.code === 0){
          //this.details = res.data.data
        }
      })
    // 下一个
    const handleNext = () => {
      nextID.value++;
      activer.value=nextID.value;
    };
    // 题板
    const handleOpen = () => {
      tabShow.value = !tabShow.value;
      openText.value = tabShow.value ? "收起题板" : "展开题板";
    };
    // 数据下标
    const endIndex = ref();
    const handleIndex = (val) => {
      endIndex.value = val;
    };
    // 触发题时
    const obj = {};
    const handleChange = (val) => {
      console.log(val)
      if(localStorage.getItem('selfVal') ) {
        selfVal.value = JSON.parse(localStorage.getItem('selfVal'))
      } else {
        selfVal.value = []
      }
      if(val.answerList.answerList.length > 1) {
        selfVal[val.answerList.answerList[0].itemIndex - 1] = val.answerList.answerList
      } else {
        if(val.type == 1) {
          selfVal[val.answerList.answerList[0].itemIndex - 1] = val.answerList.answerList
        } else {
          selfVal[val.answerList.answerList[0].itemIndex - 1] = val.answerList.answerList[0]
        }
      }
      console.log(selfVal)
      localStorage.setItem('selfVal',JSON.stringify(selfVal))
      obj[val.id] = val.answerList;
      qusionList.value = obj;
      Object.values(qusionList.value).forEach(v => {
        activeTab.value.push(v.quId);
      });
      // debugger
      // checkRadio = val.checkRadio
      // console.log('checkRadio',checkRadio.value)
    };
    // 交卷
    const handleSubmit = () => {
      // 4巩固 5考试
      if(fileType.value ==  4){
        
        dialogVisible.value = true
        // dislogType.value = data.value.length - list.value.length===0?5:4

        if( data.value.length == Object.keys(obj).length ){
          dislogType.value =   5
          // 请求接口
          list.value = Object.values(qusionList.value);
          let anserData = {
            qusionList: list.value,
            duration: route.query.duration,
            gradeConfId: route.query.gradeConfId,
            gradeId: route.query.gradeId,
            paperId: route.query.paperId,
            score: null,
            examType: 4
          };
          http
            .post("/corporate/university/exam/handPaper", anserData)
            .then((res) => {
              if (res.data.code === 0) {
                rightRate.value = res.data.data.rightRate
                wrongNum.value = res.data.data.wrongNum
              }
            });
        }else{
          dislogType.value =   4
          num.value  = data.value.length - Object.keys(obj).length
        }



        btnNormalText.value = dislogType.value==5?'返回课程':'取消'
        // btnPrimaryText.value = dislogType.value==5?'查看错题(开发中)':'确定'
      }else{
        list.value = Object.values(qusionList.value);
        if (data.value.length - list.value.length !== 0) {
          dialogVisible.value = true;
          dislogType.value = 1
          btnNormalText.value = '继续答题'
          btnPrimaryText.value = '确定交卷'
          num.value = data.value.length - list.value.length;
        } else {
          list.value = Object.values(qusionList.value);
          let anserData = {
            qusionList: list.value,
            duration: route.query.duration,
            gradeConfId: route.query.gradeConfId,
            gradeId: route.query.gradeId,
            paperId: route.query.paperId,
            score: null,
            examType: 5
          };
          const reviewObj = {
            gradeConfId: route.query.gradeConfId,
            gradeId: route.query.gradeId,
            paperId: route.query.paperId,
          }
          http
            .post("/corporate/university/exam/handPaper", anserData)
            .then((res) => {
              if (res.data.code === 0) {
                stop()
                localStorage.setItem('examList',JSON.stringify(res.data.data.testResults))
                router.push({path:'/testresults',query:{score:res.data.data.score,times:times.value,gradeConfId:route.query.gradeConfId,gradeId:route.query.gradeId,paperId:route.query.paperId}})
                // router.push({path:'/review',query:reviewObj})
              }
            });
        }
      }
    };
    const handleCancel = ()=> {
      // dialogVisible.value = false;
      if( btnNormalText.value == '继续答题' || btnNormalText.value == '取消'){
        dialogVisible.value = false;
        return false;
      }
      router.push({
        path: '/studentDetails',
        query: {
          id: gradeId,
          gradeConfId: gradeConfId
        }
      })
    }
    // 没做完题确定交卷
    const handleConfirm = () => {
      EndStudy({
        "bussId": route.query.paperId,
        "bussType": fileType.value,//学习类型(1预习 2自测 3课件 4巩固 5考试)	
        "gradeConfId": route.query.gradeConfId,
        "gradeId": route.query.gradeId,//班级id	
        // "studyTime": 0
      }).then(res=>{
        if(res.data.code === 0){
          //this.details = res.data.data
        }
      })
      if(dislogType.value == 1){
        btnNormalText.value = '继续答题'
        btnPrimaryText.value = '确定交卷'
        list.value = Object.values(qusionList.value);
        let anserData = {
          qusionList: list.value,
          duration: route.query.duration,
          gradeConfId: route.query.gradeConfId,
          gradeId: route.query.gradeId,
          paperId: route.query.paperId,
          score: null,
          examType: 5
        };
        http
          .post("/corporate/university/exam/handPaper", anserData)
          .then((res) => {
            if (res.data.code === 0) {
              stop()
              localStorage.setItem('examList',JSON.stringify(res.data.data.testResults))
              router.push({path:'/testresults',query:{score:res.data.data.score,times:times.value,gradeConfId:route.query.gradeConfId,gradeId:route.query.gradeId,paperId:route.query.paperId}})
            }
          });
      }else if(dislogType.value == 4){
        dialogVisible.value = false
        dislogType.value =   5
        dialogVisible.value = true
        // dialogVisible.value = true
        console.log('11111')
        // return
        list.value = Object.values(qusionList.value);
        let anserData = {
          qusionList: list.value,
          duration: route.query.duration,
          gradeConfId: route.query.gradeConfId,
          gradeId: route.query.gradeId,
          paperId: route.query.paperId,
          score: null,
          examType: 4
        };
        http
          .post("/corporate/university/exam/handPaper", anserData)
          .then((res) => {
            if (res.data.code === 0) {
              rightRate.value = res.data.data.rightRate
              wrongNum.value = res.data.data.wrongNum
            }
          });
          // router.go(-1)
      }else if (dislogType.value == 7) {
        list.value = Object.values(qusionList.value);
        let anserData = {
          qusionList: list.value,
          duration: route.query.duration,
          gradeConfId: route.query.gradeConfId,
          gradeId: route.query.gradeId,
          paperId: route.query.paperId,
          score: null,
          examType: route.query.fileType
        };
        http
        .post("/corporate/university/exam/handPaper", anserData)
        .then((res) => {
          if (res.data.code === 0) {
            router.push({
              path:'/testresults',
              query:{
                score:res.data.data.score,
                times:times.value,
                gradeConfId:route.query.gradeConfId,
                gradeId:route.query.gradeId,
                paperId:route.query.paperId
              }
            })
            stop()
          }
        });
      } else {
          router.go(-1)
      }
    };
    // 题板点击
    const handleTab = (val,i) => {
      nextID.value = i;
      activer.value=i;
    };
    var endTime = ''
    if(Number(route.query.duration)>0){
      // 倒计时
      let hr = ''
      let min = ''
      let sec = ''
      let date = new Date();
      let h = date.getHours()
      let m = date.getMinutes()+Number(route.query.duration);
   
      if(m>60){
        h = Number(h)+Math.floor(m/60) + ':'
        m = Math.floor(m%60)
      } else{
        h = date.getHours()+':'
      }
      if(Number(route.query.duration) === 1) {
        timetext.value = 60
          var countdown1 = () => {
            if(timetext.value > 0) {
              timetext.value--
              setTimeout(function () {
                countdown1()
              }, 1000)
            } else {
              if(fileType.value ==  5) {
                dislogType.value = 7
                dialogVisible.value = true
                btnPrimaryText.value = '查看结果'
                isTimeOut.value = true


                list.value = Object.values(qusionList.value);
                let anserData = {
                  qusionList: list.value,
                  duration: route.query.duration,
                  gradeConfId: route.query.gradeConfId,
                  gradeId: route.query.gradeId,
                  paperId: route.query.paperId,
                  score: null,
                  examType: route.query.fileType
                };
                http
                .post("/corporate/university/exam/handPaper", anserData)
                .then((res) => {
                  if (res.data.code === 0) {
                    localStorage.setItem('examList',JSON.stringify(res.data.data.testResults))
                    stop()
                  }
                });
                
                return
              } else if(fileType.value ==  4) {
                list.value = Object.values(qusionList.value);
                let anserData = {
                  qusionList: list.value,
                  duration: route.query.duration,
                  gradeConfId: route.query.gradeConfId,
                  gradeId: route.query.gradeId,
                  paperId: route.query.paperId,
                  score: null,
                  examType: 4
                };
                http
                  .post("/corporate/university/exam/handPaper", anserData)
                  .then((res) => {
                    if (res.data.code === 0) {
                      rightRate.value = res.data.data.rightRate
                      wrongNum.value = res.data.data.wrongNum
                      isTimeOut.value = false
                      dialogVisible.value = true
                      dislogType.value = fileType.value == 4?5:1
                      btnNormalText.value = dislogType.value == 1?'继续答题':dislogType.value==5?'返回课程':'取消'
                    }
                  });
                return
              } else {
                isTimeOut.value = false
                dialogVisible.value = true
                dislogType.value = fileType.value == 4?5:1
                btnNormalText.value = dislogType.value == 1?'继续答题':dislogType.value==5?'返回课程':'取消'
                return
              }
            }
          };countdown1()
      } else {
        endTime = Date.parse(new Date()) + Number(route.query.duration)*60*1000
        
        countdown()
      }
    }else{
      timetext.value = '不限时'
    }
    function countdown () {
      const end = endTime
      const now = Date.parse(new Date())
      const msec = end - now
      // console.log(msec)
      if(msec<0) return;
      let hrs = parseInt(msec / 1000 / 60 / 60 % 24)
       let mins = parseInt(msec / 1000 / 60 % 60)
       let secs = parseInt(msec / 1000 % 60)
       let hr = hrs > 9 ? hrs : '0' + hrs
       let min = mins > 9 ? mins : '0' + mins
       let sec = secs > 9 ? secs : '0' + secs
      //  console.log(hr+':'+min+':'+sec)
      timetext.value = hr+':'+min+':'+sec
      // console.log(timetext.value)
       if(mins>=0 && secs>=0){
          //倒计时结束
          if(mins==0 && secs==0){
            if(fileType.value ==  5) {
              dislogType.value = 7
              dialogVisible.value = true
              btnPrimaryText.value = '查看结果'
              isTimeOut.value = true
              
              list.value = Object.values(qusionList.value);
              let anserData = {
                qusionList: list.value,
                duration: route.query.duration,
                gradeConfId: route.query.gradeConfId,
                gradeId: route.query.gradeId,
                paperId: route.query.paperId,
                score: null,
                examType: route.query.fileType
              };
              http
              .post("/corporate/university/exam/handPaper", anserData)
              .then((res) => {
                if (res.data.code === 0) {
                  localStorage.setItem('examList',JSON.stringify(res.data.data.testResults))
                  stop()
                }
              });

              return
            } else if(fileType.value ==  4) {
              list.value = Object.values(qusionList.value);
              let anserData = {
                qusionList: list.value,
                duration: route.query.duration,
                gradeConfId: route.query.gradeConfId,
                gradeId: route.query.gradeId,
                paperId: route.query.paperId,
                score: null,
                examType: 4
              };
              http
                .post("/corporate/university/exam/handPaper", anserData)
                .then((res) => {
                  if (res.data.code === 0) {
                    rightRate.value = res.data.data.rightRate
                    wrongNum.value = res.data.data.wrongNum
                    isTimeOut.value = false
                    dialogVisible.value = true
                    dislogType.value = fileType.value == 4?5:1
                    btnNormalText.value = dislogType.value == 1?'继续答题':dislogType.value==5?'返回课程':'取消'
                  }
                });
            }else {
              isTimeOut.value = false
              dialogVisible.value = true
              dislogType.value = fileType.value == 4?5:1
              btnNormalText.value = dislogType.value == 1?'继续答题':dislogType.value==5?'返回课程':'取消'
              return
            }
              // dialogVisible.value = true
              // dislogType.value = fileType.value == 4?5:1
              // btnNormalText.value = dislogType.value == 1?'继续答题':dislogType.value==5?'返回课程':'取消'
              // // btnPrimaryText.value = dislogType.value == 1?'确定交卷':dislogType.value==5?'查看错题(开发中)':'确定'
              // handleConfirm()
              // return
          }
          setTimeout(function () {
            countdown()
          }, 1000)
      }
    }
    // 计时器
    var hour,minute,second;//时 分 秒
    hour=minute=second=0;//初始化
    var int;
    //开始函数
    function start()
    {
      int=setInterval(timer,1000);//每隔50毫秒执行一次timer函数
    }start()
    //计时函数
    function timer()
    {
      second=Number(second)+1;
      if(second>=60)
      {
        second=0;
        minute=minute+1;
      }

      if(minute>=60)
      {
        minute=0;
        hour=hour+1;
      }
      if(hour>=24)
      {
        handleConfirm()
      }
      times.value = hour+':'+minute+':'+second
    }
    //暂停函数
    function stop()
    {
      window.clearInterval(int);
    }
    const closeTest = () => {
      btnNormalText.value = '取消'
      // btnPrimaryText.value = '确定'
      dialogVisible.value = true
      // 4巩固 5考试
      if(fileType.value ==  4){
        dislogType.value = 4
        num.value  = data.value.length - Object.keys(obj).length
      }else{
        dislogType.value = 2
        list.value = Object.values(qusionList.value);
        num.value =  list.value?data.value.length - list.value.length:data.value.length;
      }
    }
    // 4巩固 5考试
    // if(fileType.value ==  4){
    //   dialogVisible.value = true
    //   dislogType.value = 3
    //   dialogTitle.value = '温馨提示'
    // }
    onBeforeUnmount(()=>{
      clearTimeout(countdown)
      countdown = null
    })
    return {
      fileType,
      nextID,
      tabShow,
      loadingTrue,
      endIndex,
      data,
      openText,
      dialogVisible,
      isTimeOut,
      dialogTitle,
      activeTab,
      qusionList,
      num,
      list,
      times,
      timetext,
      activer,
      dislogType,
      rightRate,
      wrongNum,
      btnNormalText,
      btnPrimaryText,
      handleNext,
      handleOpen,
      handleChange,
      handleIndex,
      handleSubmit,
      handleTab,
      closeTest,
      handleConfirm,
      handleCancel
    };
  },
};
</script>
<style lang="scss">
.isTimeOut {
  .el-dialog__close {
    display: none;
  }
  .el-button--default{
    display: none;
  }
}
// .examination_loading{
//   .el-dialog{
//     width: 580px !important;
//     height: 350px;
//     background: #FFFFFF;
//     border-radius: 8px;
//     border: 1px solid #DDDDDD;
//   }
//   .el-dialog__header{
//     padding: 40px 40px 0 40px;
//     .el-dialog__title{
//       font-size: 30px;
//       font-weight: 500;
//       color: #333333;
//       line-height: 42px;
//     }
//   }
//   .el-dialog__body{
//     padding: 0 40px;
//     margin-top: 20px;
//     p{
//       font-size: 20px;
//       font-weight: 400;
//       color: #333333;
//       line-height: 28px;
//     }
//   }
// }
</style>
<style lang="scss" scoped>
*{
  box-sizing: border-box;
}
.wrap {
  width: 100%;
  height: 100%;
  background: white;
  position: fixed;
  top: 80px;
  left: 0;
  overflow: hidden auto;
  padding-bottom: 100px;
}
.bottom{
  width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
.right_bottom {
  // position: fixed;
  // left: 300px;
  // bottom: 200px;
  margin-left: 44px;
  margin-bottom: 50px;
  .time {
    padding-left: 6px;
    color: red;
  }
}
.table {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  // position: fixed;
  // left: 200px;
  // bottom: 130px;
  margin-left: 0px;
  margin-bottom: 100px;
  .table_item {
    width: 48px;
    height: 40px;
    border: 1px solid #CCCCCC;
    text-align: center;
    line-height: 40px;
    margin-right: -1px;
  }
  .active_tab {
    background: #e8e8e8;
  }
  .activer{
    background: #2D70F8;
    color: white;
    margin-left: -1px;
    margin-right: -1px;
  }
}
}
.question_board{
  border: 1px solid #ddd;
}
</style>