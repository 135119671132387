<!-- 考试排行 -->
<template>
  <div>
    <div v-if="isKong == false">
      <div class="ranking_wrap">
        <div class="content">
          <img src="../../../assets/images/bg_ranking.png" alt="" />
          <div v-for="(v, i) in rankingList" :key="i">
            <div class="timeRanking timeRankingFirst" v-if="i == 0">
              {{ v.score?v.score+'分':'-' }}
            </div>
            <div class="timeRanking timeRankingSecond" v-if="i == 1">
              {{ v.score?v.score+'分':'-' }}
            </div>
            <div class="timeRanking timeRankingThird" v-if="i == 2">
              {{ v.score?v.score+'分':'-' }}
            </div>
            <dl class="personInformation firstPerson" v-if="i == 0">
              <dt>
                <img
                  :src="v.headImg?v.headImg:headImg"
                  style="
                    width: 76px;
                    height: 76px;
                    border-radius: 50%;
                    border: 2px solid #ecca9f;
                  "
                />
              </dt>
              <dd>
                {{ v.userName }}
              </dd>
            </dl>
            <dl class="personInformation secondPerson" v-if="i == 1">
              <dt>
                <img
                  :src="v.headImg?v.headImg:headImg"
                  style="
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                    border: 2px solid #ecca9f;
                  "
                />
              </dt>
              <dd>
                {{ v.userName }}
              </dd>
            </dl>
            <dl class="personInformation thirdPerson" v-if="i == 2">
              <dt>
                <img
                  :src="v.headImg?v.headImg:headImg"
                  style="
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                    border: 2px solid #ecca9f;
                  "
                />
              </dt>
              <dd>
                {{ v.userName }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="content_table">
        <el-table
          :data="dataList"
          :header-cell-style="{
            background: '#f8f8f8',
            color: '#999',
            fontSize: '16px',
          }"
          :cell-style="{
            background: '#f8f8f8',
            fontSize: '16px',
          }"
          style="width: 100%;"
        >
          <el-table-column label="排行" width="120" type="index">
            <template #default="scope">
              <span v-if="scope.$index == 0"
                ><img src="../../../assets/images/icon_first.png" alt=""
              /></span>
              <span v-if="scope.$index == 1"
                ><img src="../../../assets/images/icon_second.png" alt=""
              /></span>
              <span v-if="scope.$index == 2"
                ><img src="../../../assets/images/icon_third.png" alt=""
              /></span>
              <span v-if="scope.$index >= 3" class="rankStyle">{{
                scope.$index + 1
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="用户名" width="180">
            <template #default="scope">
              <div style="display: flex">
                <img
                  :src="scope.row.headImg?scope.row.headImg:headImg"
                  style="width: 30px; height: 30px; border-radius: 50%"
                  alt=""
                /><span
                  style="
                    margin-top: 4%;
                    font-weight: 400;
                    color: #333333;
                    font-size: 16px;
                    margin-left: 6px;
                  "
                  >{{ scope.row.userName }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-for="col in tableHeader"
            :key="col.prop"
            :label="col.paperName?col.paperName + '(' +col.totalScore + '分)':col.label"
            width="180px"
          >
            <template #default="scope">
              <!-- {{col}} -->
              <span v-if="col.label" style="color:#F04B4A">{{getScore(col.id,scope.row.userName)}}</span>
              <span v-else>{{getScore(col.id,scope.row.userName)}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-else style="text-align: center;height: 66vh;">
      <img src="@/assets/images/noData.png" alt="" style="margin-top:12%;margin-bottom:30px;">
      <p style="font-weight: 400;line-height: 22px;color: #BFBFBF;">暂无数据</p>
    </div>
  </div>
</template>

<script>
import { GETEXAMRANKE,getfindPapersByGrader } from "@/server/details";
export default {
  components: {},
  data() {
    return {
      score: "",
      dataList:[],
      courseScore: [],
      courseScore_first: [],
      tableHeader:[],
      rankingList: [],
      headImg:require('@/assets/images/header.png'),
      isKong:false
    };
  },
  computed:{
    getScore(){
      return (id,userName)=>{
        if( !userName ){
          return 0;
        }
        let filter1 = this.dataList.filter((item)=>item.userName == userName);
        if( filter1.length ){

          let filter2 = filter1[0]['courseScore'].filter((item)=>item.id == id);
          return filter2.length ?  filter2[0].score + '分': '-';
        }
        return 0;
      }
    }
  },
  created() {
    this.getList1();
  },
  methods: {
    getList1() {
      getfindPapersByGrader({gradeId:this.$route.query.id}).then((res) => {
        console.log(res)
        if (res.data.code == 0) {
          this.tableHeader = res.data.data;
          // console.log(this.tableHeader)
          this.getList();
        }
      });
    },
    getList() {
      GETEXAMRANKE({gradeId:this.$route.query.id}).then((res) => {
        console.log(res)
        if (res.data.code == 0) {
          if(res.data.data.length == 0){
            console.log('111')
            this.isKong = true
          }
          let responseData = res.data.data;
          // console.log(this.courseScore,'...............');
          // console.log("tableList：" + JSON.stringify(responseData));
          for(let dataItem of responseData){
                let rowData = {}
                let rankObj = {};
                rankObj.userName = dataItem.userName;
                rowData.userName = dataItem.userName
                rowData.headImg = dataItem.headImg
              for(let courseScoreItem of dataItem.courseScore){
                  
              if (courseScoreItem.couresName == "平均分") {
                rankObj.score = courseScoreItem.score;
              }
                rowData[courseScoreItem.couresName] = courseScoreItem.score;

              }
              // console.log(rowData)
                this.rankingList.push(rankObj);
                this.dataList.push(rowData)
                
          }
          this.dataList = responseData;
          // console.log(this.dataList)
          let firstRowData = responseData[0];
          for(let courseScoreItem of firstRowData.courseScore){
            if(courseScoreItem.couresName == '平均分'){
              let cloumn = {
                  prop: courseScoreItem.courseName,
                  label :courseScoreItem.couresName + "\n" + "(" + courseScoreItem.totalScore + "分)",
                  score:courseScoreItem.score
              }
            
              // console.log(cloumn)
              this.tableHeader.unshift(cloumn)
            }
              // console.log(this.tableHeader)
          }
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.ranking_wrap {
  width: 100%;
  height: 296px;
  background: #f8f8f8;
  .content {
    width: 540px;
    margin: 0 auto;
    padding-top: 130px;
    position: relative;
    .timeRanking {
      position: absolute;
      width: 68px;
      height: 68px;
      text-align: center;
      line-height: 68px;
      background: #ffffff;
      border-radius: 50%;
      font-weight: 400;
      font-size: 16px;
      color: #bc7b2a;
    }
    .timeRankingFirst {
      bottom: 10%;
      left: 43.5%;
    }
    .timeRankingSecond {
      width: 54px;
      height: 54px;
      line-height: 54px;
      bottom: 6%;
      left: 13%;
    }
    .timeRankingThird {
      width: 54px;
      height: 54px;
      line-height: 54px;
      bottom: 6%;
      right: 13%;
    }
    .personInformation {
      position: absolute;
    }
    .firstPerson {
      top: 14px;
      left: 42%;
      dd {
        width: 80px;
        height: 28px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
      }
    }
    .secondPerson {
      top: 76px;
      left: 12%;
      dd {
        width: 60px;
        height: 28px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        // line-height: 28px;
      }
    }
    .thirdPerson {
      top: 76px;
      right: 64px;
      dd {
        width: 60px;
        height: 28px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        // line-height: 28px;
      }
    }
  }
}
.content_table {
  width: 1200px;
  margin: 0 auto;
  margin-top: 2px;
  background: #f8f8f8;
  .rankStyle {
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    color: #999;
    display: inline-block;
    border-radius: 50%;
    background: #edeced;
  }
}
/deep/.el-table th{
    height: 80px;
    // line-height: 80px;
    border-bottom: 1px solid #fff;
}
/deep/.el-table td{
    height: 80px;
    border-bottom: 1px solid #fff;
}
/deep/ .cell {
  white-space: pre-line;
  font-size: 16px;
  text-align: center;
}
/deep/.el-table__body-wrapper {
background: #f8f8f8;
}
/deep/ .el-table__header-wrapper{
  background: #f8f8f8;
}
/deep/ .cell{
    font-size: 16px;
    padding-left: 31px;
}
</style>
