<template>
  <div class="timetable_wrap">
    <!-- 课程表头部 - 展示时间 -->
    <div class="timetable_header">
      <!-- 向前翻页 -->
      <el-button
        :class="{timetable_text_btn: true, timetable_header_icon: true, timetable_header_icon_disabled: weekPrev}" 
        @click="() => changeWeekBtn(1)"
        icon="el-icon-arrow-left"></el-button>

      <!-- 时间展示 -->
      <b class="timetable_title">{{ title.startTime }} - {{ title.endTime }}</b>

      <!-- 向后翻页 -->
      <el-button
        :class="{timetable_text_btn: true, timetable_header_icon: true, timetable_header_icon_disabled: !weekNext}"
        @click="() => changeWeekBtn(2)"
        icon="el-icon-arrow-right"></el-button>
    </div>

    <!-- 课表星期日期 -->
    <ul class="timetable_days">
      <li class="timetable_days_time">时间</li>
      <!-- 每周的时间 - 星期 / 日期 -->
      <li
        v-for="(item, idx) in dateList[page.page]"
        :key="`days_item_${idx}`"
        class="timetable_days_item">
        <p class="timetable_days_item_week">{{ item.week }}</p>
        <span class="timetable_days_item_date">{{ item.date }}</span>
      </li>
    </ul>

    <ul
      v-for="(items, idx) in timeList"
      :key="`timetable_content_${idx}`"
      :class="{ timetable_content: true, border_bottom_none: items.timeSeg === '晚上' }"
    >
      <li class="timetable_content_item border_right_none">
        <span class="timetable_item_text">{{ items.timeSeg }}</span>

        <div class="timetable_right ">
          <div v-if="items.timeSegList.length">
            <ul
              v-for="(item) in items.timeSegList"
              :key="item.timeId"
              class="timetable_content_right border_right_none"
              >
              <!-- 时间 -->
              <li
                class="timetable_content_item_set timetable_item border_bottom_none">
          
                <el-button
                  class="timetable_text_btn timetable_content_item_set_text">
                  {{ item.timeRange }}
                </el-button>

              </li>
              <!-- 横向表格 -->
              <li
                class="timetable_item border_bottom_none"
                v-for="(obj) in item.list"
                :key="obj.id">

                <div class="timetable_item timetable_item_con border_bottom_none border_right_none" v-if="obj.name">
                  <div class="timetable_item_child">
                    <el-tooltip class="item" effect="dark" :content="obj.name" placement="top-start">
                      <p>{{obj.name.substring(0,20)}}</p>
                    </el-tooltip>
                    <div v-if="role !== 'student'">
                      <p style="margin-top:4px;color: #3a8ee6;" v-if="obj.teaId " @click="addTeacher(obj)">{{ obj.teaName }}</p>
                      <p style="margin-top:10px" v-else @click="addTeacher(obj)"><i class="el-icon-plus"></i>请添加老师</p>
                    </div>
                    <div v-else>
                      <p style="margin-top:4px;color: #3a8ee6;" v-if="obj.teaId ">{{ obj.teaName }}</p>
                    </div>
                    <!-- 暂定班主任角色登录时可以选择任课老师 -->
                    <!-- <el-dropdown
                      class="timetable_item_con_dropdown"
                      @command="command => updadeItem(command, obj)">
                      <i class="el-icon-more timetable_item_con_more"></i>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item >
                            <el-cascader-panel 
                            :props="{ expandTrigger: 'hover'}"
                            @change="moveHandleChange"
                            :options="moveOptions">
                            </el-cascader-panel>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown> -->
                  </div>
                </div>
                
              </li>
            </ul>
          </div>
          <ul v-else class="timetable_content_right border_bottom_none">
            <li class="timetable_content_item_set timetable_item border_bottom_none"></li>
            <li
              v-for="n in dateList[page.page].length"
              :key="`${items.timeSeg}_set_${n}`"
              class=" timetable_item border_bottom_none"></li>
          </ul>
        </div>
      </li>
    </ul>
    <el-dialog
      title="添加老师"
      v-model="dialogVisible"
      width="40%"
      :before-close="handleClose">
      <el-select v-model="teacName" placeholder="请选择" @change="changeTeac">
        <el-option
          v-for="item in teacherList"
          :key="item.userId"
          :label="item.username"
          :value="item.userId">
        </el-option>
      </el-select>
      <template #footer>
      <span  class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateTeach">确 定</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>

import {
  getClassDates,
  getClassTimes,
  getClassDay,
  getTeacherList,
  updateTeacher,
  extendCourse,
  unuseDay,
  moveDay
} from '@/server/timetable'
import { getRole } from '@/utils/utils.js'
import http from "@/utils/request";
export default {
  name: 'timeTable',
  data() {
    return {
      timeInfo: {}, // 用于保存修改时间的元素信息
      timeType: '', // 保存修改时间 / 添加时间
      days: [], // 用于存放展示的时间 - 第几天
      timeList: [], // 存放请求回的时间数据
      newTimeList: {},
      
      
      timeSeg: '', // 用于存放选择上午下午晚上 
      dayIds: [], // 横向日期id
      dayIdsIndex: {
        id:{},
        idx: {}
      }, // 横向日期id 的下标
      times: [], // 存放时间
      timesObj: {}, // 存放时间 id 的坐标 
      dateList: [],
      page: {
        limit: 7,
        page: 0,
        total: 0
      },
      gradeId: 0,
      title: {
        startTime: '',
        endTime: '',
      },
      role: getRole(),
      teacherList: [],
      getRole: getRole(),
      dialogVisible:false,
      teacName:'',
      taeId:'',
      courseId:'',
      extendsTimes:'',
      options:[
        {
          value: 'zujian',
          label: '顺延',
          children: [
            {
            value: 1,
            label: '1个课时',
            },{
            value:2,
            label: '2个课时',
            },{
            value: 3,
            label: '3个课时',
            },{
            value: 4,
            label: '4个课时',
            },
          ]
        }         
      ],
      moveOptions:[
        {
          value: 'yidong',
          label: '移动',
          children: []
        },{
          value: 'shunyan',
          label: '顺延',
          children: []
        }
      ],
      moveDayId:'',
      moveTimeId:'',
      indexClick:''
    }
  },
  computed: {
    weekPrev () {
      const { total, page, limit } = this.page;

      return (
        page == 0 || 
        total < limit || 
        total == limit || 
        total < limit * (page - 1))
    },
    weekNext () {
      const {total, page, limit} = this.page;

      if (total < limit || total == limit) return false;

      return  total > limit * (page + 1)
    },
  },
  methods: {
    addTeacher(val){
      console.log(val)
      if(this.teacherList.length<1){
        this.$message.warning('创建班级时未添加老师，暂无老师可添加！');
        return
      }
      http.post('/corporate/university/teacher/grade/role/userGradeRole',{
        gradeId:this.gradeId
      }).then(({ data }) => {
        // console.log(data)
          if (data.code == 0) {
            let arr = []
             for(let i=0;i<data.data.length;i++){
              if(data.data[i].roleCode == 'mater'){
                arr.push(data.data[i].roleCode)
              }
            }
            // console.log(arr)
            if(arr.length>0){
              this.courseId = val.id
              this.dialogVisible = true
            }else{
              this.$message.warning('暂无权限分配讲师！');
              return
            }
            
          }else{
            this.$message.warning(data.msg);
          }
        })
      
      
    },
    handleClose(){
      this.dialogVisible = false

    },
    changeTeac(e){
      console.log(e)
      this.teaId = e
    },
    updateTeach() {
      updateTeacher({
          "id": this.courseId,
          "teaId": this.teaId
        })
        .then(({ data }) => {
          if (data.code === 0) {
            this.$message.success('添加成功！')
            this.getCourseList()
          }else{
            this.$message.warning(data.msg);
          }
          this.dialogVisible = false
        })
    },
    updadeItem(type,item){
      console.log(type)
      console.log(item)
      console.log('22222')
      this.courseId = item.id
      unuseDay({
        gradeId:this.gradeId
      }).then(({ data }) => {
        // console.log(data)
        // console.log('3333')
          if (data.code === 0) {
          
            let newArr = []
            data.data.forEach((item)=>{
              let obj = {}
              for(let i=0; i <= data.data.length; i++){
                  obj.label = item.date;
                  obj.value = item.dayId;
                  
                  let children = []
                  item.timeList.forEach((val)=>{
                    let childObj = {}
                    for(let j=0; j <= item.timeList.length; j++){
                      childObj.label = val.timeRange
                      childObj.value = val.timeId
                    }
                    children.push(childObj)
                  })
                  obj.children = children
              }
              newArr.push(obj);

            })
            let moveObj = {
                value: '1',
                label: '移动',
                children: []
            }
            moveObj.children = newArr
            let extendObj = {
              value: '2',
              label: '顺延',
              children: [
                {
                value: 1,
                label: '1个课时',
                },{
                value:2,
                label: '2个课时',
                },{
                value: 3,
                label: '3个课时',
                },{
                value: 4,
                label: '4个课时',
                },
              ]
            }
            this.moveOptions = new Array(moveObj,extendObj)
            // this.moveOptions.push(this.options)
            console.log(this.moveOptions)
           this.courseMove(item)
            this.coursePostpone(item)
          }else{
            this.$message.warning(data.msg);
          }
      }).catch((data)=>{
        // console.log(data)
        this.$message.warning(data.data.msg);
      })
      
    },
    moveHandleChange(val){
      // console.log(val)
      if(val[0] == 1){
        this.moveDayId = val[1]
        this.moveTimeId = val[2]
        // console.log('11111')
      }else{
        this.extendsTimes = val[1]
      }
      
    },
    courseMove(val){
      moveDay({
        "dayId":this.moveDayId,
        "id": val.id,
        "timeId":this.moveTimeId
      }).then(({ data }) => {
          if (data.code === 0) {
            this.$message.success('移动成功！')
            this.getCourseTimes()
        this.getCourseDate()
            this.getCourseList()
          }else{
            this.$message.warning(data.msg);
          }
      }).catch((data)=>{
        // console.log(data)
        this.$message.warning(data.data.msg);
      })
      
    },
    coursePostpone(val){
        // console.log(val)
        extendCourse({
          "id": val.id,
          "times":parseInt(this.extendsTimes) 
        }).then(({ data }) => {
          if (data.code === 0) {
            this.$message.success('顺延成功！')
            this.getCourseTimes()
        this.getCourseDate()
            this.getCourseList()

          }else{
            this.$message.warning(data.msg);
          }
          // this.dialogVisible = false
        })

    },
    // 修改点击切换星期 type: 1: 上一周 / 2: 下一周
    changeWeekBtn(type) {
      if (type === 1 && !this.weekPrev) {
        this.page.page--;
        this.getCourseTimes()
        this.getCourseDate()
      }

      if (type === 2 && this.weekNext) {
        this.page.page++;
        this.getCourseTimes()
        this.getCourseDate()
      }
    },
    

    // 点击表格元素跳转至添加课程和编辑课程
    editItem(item) {
      sessionStorage.setItem('timeInfo', JSON.stringify({...item, gradeId: this.gradeId }))

      this.$router.push({
        path: '/addclass'
      })
    },

    // 查询课程日期 横向日期
    getCourseDate() {
      const { page } = this.page
      getClassDates({ gradeId: this.gradeId })
      .then(({ data }) => {
        const { data: list } = data
        const dateList = []
        let arr = []
        list.forEach((item) => {
          if (arr.length < 7) {
            arr.push(item)

            if (arr.length === 7) {
              dateList.push(arr)

              arr = []
            }
          }
        })

        this.page.total = list.length
        this.dateList = dateList
        const week = dateList[page]
        console.log(week)
        this.title.startTime = week[0].date
        this.title.endTime = week.slice(-1)[0].date
      
        setTimeout(this.getCourseTimes, 0)
      })
      // 
    },

    // 获取时间项 竖向时间
    getCourseTimes() {
      getClassTimes({ gradeId: this.gradeId })
        .then(({ data }) => {
          if (data.code === 0) {
            
            const { infoList } = data.data
            this.times = infoList
            const timesObj = {}
            infoList.forEach((item, idx) => {
              item.timeSegList.forEach((item2, idx2) => {
                // 保存子元素的坐标
                timesObj[item2.timeId] = {
                  idx, idx2
                }
              })
            })

            this.timesObj = timesObj

            if (this.dateList.length) {
              this.getCourseList()
            } else {
              this.initTable({})
            }
          }
        })
    },
    // 清空表格中数据
    clear() {
      this.times.forEach(item => {
        // console.log(item)
        if (item.timeSegList.length) {
          item.timeSegList.forEach(o => {
            o.list = []
          })
        }
      })
    },
    // 根据日期查询一天的课程详情 组合竖向时间以及每个方格中的内容
    getCourseList() {
      const { page } = this.page
      const gradeId = this.gradeId
      const week = this.dateList[page]
      const arr = []
      week.forEach(item => {
        const data = {
          gradeId,
          dayId: item.sid,
        }
        arr.push(getClassDay(data))
      })

      const timesObj = this.timesObj
      const times = this.times
      Promise.all(arr).then(res => {
        // 清除时间中的 timeSegList 中的 list 值
        this.clear()
        res.forEach(({ data: { data, code } }) => {
          
          if (code === 0) {
            data.forEach(item => {
              const { timeId } = item
              if (timesObj[timeId]) {
                const { idx, idx2 } = timesObj[timeId];

                // 根据坐标组合
                if (times[idx] && times[idx].timeSegList && times[idx].timeSegList[idx2] && times[idx].timeSegList[idx2].list) {
                  times[idx].timeSegList[idx2].list.push(item)
                }
              }
              
            })
          }
        })
        
      })

      this.timeList = times
    },

    // 获取当前班级的讲师
    getTeacherList() {
      getTeacherList({ gradeId: this.gradeId })
        .then(({ data }) => {
          if(data.code === 0) {
            this.teacherList = data.data
          }
        })
    },

    // 初始化表格数据，补齐
    initTable(infoObj) {
      this.timeList = this.times.map((obj) => {
        const { timeSeg } = obj
        obj.timeSegList = obj.timeSegList.map(({ timeId, timeRange }) => {

          if (infoObj[timeId]) {
            return infoObj[timeId]
          } else {
            const list = this.dayIds.map(dayId => ({
              timeRange, timeId, dayId,  timeSeg,
              gradeId: this.gradeId,
            }))

            return {
              timeId, timeRange, list
            }
          }
        })

        return obj
      })
    },


  },

  created() {
    this.gradeId = this.$route.query.id
    const roles = JSON.parse(sessionStorage.getItem('role'))

    if (getRole() !== 'student' && roles.indexOf('mater') > -1) {
      this.role = 'mater'
      this.getTeacherList()
    }
    console.log(this.role)
    this.getCourseDate()
    
  }
}
</script>

<style lang="scss" scoped>

@mixin border($value: 1px, $lineType: solid, $color: #E6E6E6) {
  border: $value $lineType $color;
}
@mixin border_item ($border: left, $value: 1px, $lineType: solid, $color: #E6E6E6) {
  border-#{$border}: $value $lineType $color;
}
@mixin borderNone ($border: bottom) {
  border-#{$border}: none;
}

$white: #fff;
$bg_grey_6: #FAFAFA;
$font_color_grey1: #999;
$font_color_grey2: #BFBFBF;
$theme_color:#2D70F8;
$font_color_black: #333; 

$height: 40px;
.timetable_wrap {
  width: 1200px;
  margin: 20px auto;
  @include border;

  .timetable_header {
    width: 100%;
    height: $height;
    line-height: $height;
    background-color: $bg_grey_6;
    text-align: center;
    font-size: 14px;
    box-sizing: border-box;
  }

  .timetable_header_icon {
    color: $font_color_grey2 !important;
  }

  .timetable_header_icon_disabled {
    opacity: .3;
  }

  .timetable_title {
    margin-right: 9px;
    margin-left: 9px;
  }

  .timetable_days {
    @include border_item(top);
    @include border_item(bottom);
    display: flex;
    background-color: $bg_grey_6;
    box-sizing: border-box;

    li:last-child {
      border-right: none;
    }
  }

  .timetable_days_time {
    width: 182px;
    @include border_item(right);
    text-align: center;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
  }

  .timetable_days_item {
    position: relative;
    width: 148px;
    text-align: center;
    @include border_item(right);
    box-sizing: border-box;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .timetable_days_item_week {
    font-size: 20px;
    margin-bottom: 4px;
  }

  .timetable_days_item_date {
    color: #999;
  }

  .timetable_text_btn {
    padding: 0;
    line-height: 1;
    min-height: auto;
    background: none;
    border: none;
    color: $theme_color;
  }
  
  .timetable_content {
    @include border_item(bottom);
  }

  .timetable_content_item {
    width: 100%;
    display: flex;
    @include border_item(right);
    @include border_item(bottom);

    &:last-child {
      border-bottom: none;
    }
  }

  .timetable_item_text {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 47px;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    @include border_item(right);
    background-color: $bg_grey_6;
    min-height: 120px;
  }

  .timetable_right {
    width: 100%;
  }

  .timetable_content_set {
    width: 97px;
    background-color: $bg_grey_6;
    @include border_item(right);
  }

  .timetable_item {
    width: 145px;
    display: flex;
    align-items: center;
    height: 120px;
    justify-content: space-around;
    @include border_item(bottom);
    @include border_item(right);
    position: relative;
  }

  .timetable_content_item_set {
    width: 132px;
    background-color: $bg_grey_6;
  }

  .timetable_content_item_set_text {
    color: $font_color_black;
  }

  .timetable_content_right {
    width: 100%;
    flex: 1;
    display: flex;
    @include border_item(bottom);
    &:last-child {
      border-bottom: none
    }

    li:last-child {
      border-right: none
    }
  }

  .border_bottom_none {
    @include borderNone();
  }

  .border_right_none {
    @include borderNone(right);
  }

  .timetable_item_con {
    align-items: unset;
    justify-content: unset;
    padding: 10px;
    position: relative;
    display: unset;

    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  .timetable_item_child {
    padding: 10px;
    cursor: pointer;
  }
  /deep/ .timetable_item_con_dropdown{
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  /deep/ .timetable_item_con_more {
    transform: rotate(90deg);
    color: $font_color_grey1;
    cursor: pointer;
  }
}
</style>