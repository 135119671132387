<template>
  <div>
    <el-row class="wrap" justify="center" :class="[reverse?'box_reverse':'']">
      <el-col :span="12" class="right">

        <!-- 标题 -->
        <div class="top">
          <span class="tap">填空</span>{{itemIndex}}/{{dataLen}}{{item.name}}
        </div>

        <!-- 回顾 -->
        <div v-if="review && this.$route.query.index" class="review_middle review_tea">
          <div v-for="(v,i) in item.answerList" :key='i'  style="display:flex;align-items: center;" class="item_list">
            <span style="width:60px;display:block;">第{{capitalize[i+1]}}处:</span>
            <el-input class="aaa" type="text" :placeholder="v.content"  style="width:400px;"/>
          </div>
        </div>
        <div v-if="review" class="review_middle">
          <div v-for="(v,i) in item.gradeQuAnswers" :key='i'  style="display:flex;align-items: center;" class="item_list">
            <span style="width:60px;display:block;">第{{capitalize[i+1]}}处:</span>
            <el-input type="text"
              placeholder="请输入答案"
              v-model="content[i]"
              :class="[content.includes(v.content) && opId.includes(v.id)?'review_green':!content.includes(v.content)?'review_red':!opId.includes(v.id) &&
              !content.includes(v.content)?'reviewMult':'']"  style="width:400px;"/>
          </div>
        </div>

        <!-- 自测 -->
        <div v-if="test" class="middle" style="margin-top:30px;">
          <div  v-for="(v,i) in item.answerList" :key='i'>
            <div v-if="boolVal==false" style="display:flex;align-items: center;margin-top:20px;">
              <span style="width:60px;display:block;color:#999;">第{{capitalize[i+1]}}处:</span>
              <el-input type="text" v-model="answer[i]" @change="handleChange(v)" v-on:keyup="handleChange(v)" class="com_input" :class="[v.content === arr2[i]?'green':'red']" placeholder="请输入答案"  />
            </div>
            <div style="display:flex;align-items: center;margin-top:20px;" v-else>
              <span style="width:60px;display:block;color:#999;">第{{capitalize[i+1]}}处:</span>
              <el-input type="text" v-model="answer[i]" @change="handleChange(v)" v-on:keyup="handleChange(v)" class="com_input" placeholder="请输入答案"  />
            </div>
          </div>
        </div>

        <!-- 考试 -->
        <div v-if="exam" class="middle" style="margin-top:30px;">
          <div  v-for="(v,i) in item.answerList" :key='i'>
            <div style="display:flex;align-items: center;margin-top:10px;">
              <span style="width:60px;display:block;">第{{capitalize[i+1]}}处:</span>
              <el-input type="text" v-model="answer[i]" @change="handleChange(v)" class="com_input" placeholder="请输入答案"  />
            </div>
          </div>
        </div>

        <!-- 解析 -->
        <div v-if="review" class="review_analy">
          <div v-if="!this.$route.query.index" :class="[item.isAnswerRight ?'content_box1':'content_box2']">{{ item.isAnswerRight?'正确':'错误' }}</div>
          <div class="review_analysis" v-if="!reviewCorrects && !this.$route.query.index">
            <p style="margin-bottom:6px;">答案：</p>
            <p v-for="(v,i) in item.gradeQuAnswers" :key='i'>第{{capitalize[i+1]}}处:{{v.content}}</p>
          </div>
          <div class="review_analysis">
            <p style="margin-bottom:6px;">答案解析：</p>
            <p style="line-height: 24px;">{{item.analysis}}</p>
          </div>
        </div>
        <div v-else class="analy">
          <div :class="[valIsRight?'content_box1':'content_box2']" v-if="correct">{{ valIsRight?'正确':'错误' }}</div>
          <div class="analysis" v-if="correct">
            <p style="margin-bottom:6px;">答案：</p>
            <p v-for="(v,i) in item.answerList" :key='i'>第{{capitalize[i+1]}}处:{{v.content}}</p>
          </div>
          <div class="analysis" v-if="correct">
            <p style="margin-bottom:6px;">答案解析：</p>
            <p style="line-height: 24px;">{{item.analysis}}</p>
          </div>
        </div>

      </el-col>
      
      <el-col :span="8" class="left">
        <el-image v-if="item.questionsPic"
          style="width: 400px; height: 400px"
          fit="cover"
           :src="item.questionsPic"
        ></el-image
      ></el-col>
    </el-row>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  props:['correct','reverse','item','itemIndex','dataLen','boolVal','arr2','review','exam','test','valIsRight'],
  setup(props,context) {
    const selfValContent = ref([])
    const capitalize = ref(['零','一', '二'])
    const answer = ref([])
    if(localStorage.getItem('selfVal') && JSON.parse(localStorage.getItem('selfVal'))[props.itemIndex - 1]) {
      const selfVal = JSON.parse(localStorage.getItem('selfVal'))
      if (selfVal[props.itemIndex - 1].length > 1) {
        for (let i = 0; i < selfVal[props.itemIndex - 1].length; i++) {
          selfValContent.value.push(selfVal[props.itemIndex - 1][i].content)
        }
      } else {
        selfValContent.value.push(selfVal[props.itemIndex - 1].content)
      }
      answer.value = selfValContent.value
    }

    
    const opId = ref([])
    const content = ref([])
    const answerArr = ref([])
    const handleChange = (val)=>{
      const answerList = {
        quId:props.item.id,
        answerList:answer.value.map(v=>{
          answerArr.value.push(v)
         return {
          "content": v,
          "id": val.id,
          "opId": val.sid,
          "rightNum": null,
          'itemIndex': props.itemIndex,
          'answerArr': answerArr
        }
       })  
      }
      context.emit('handleChange',{bool:true,answerList,id:props.item.id,type:props.item.type})
    }
    const handleIndex=()=>{
      context.emit('handleIndex',props.itemIndex)
    }
    handleIndex()
    if (props.review) {
      if(props.item.studentAnswerRelas){
        props.item.studentAnswerRelas.forEach(n => {
          opId.value.push(n.opId) 
          content.value.push(n.content) 
        });
      }
    }
    return {
      answer,
      content,
      answerArr,
      opId,
      handleChange,
      selfValContent,
      capitalize,
      handleIndex
    };
  },
});
</script>
<style lang='scss'>
.review_tea{
  input::-webkit-input-placeholder {
    color: #00C456 !important;
  }
  input::-moz-input-placeholder {
    color: #00C456 !important;
  }
  input::-ms-input-placeholder {
    color: #00C456 !important;
  }
}
</style>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
input {
  border: none;
  border-bottom: 1px solid;
  width: 80px;
}
.box_reverse{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.wrap {
  width: 100%;
  height: 100%;
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .right {
    // padding-left:138px;
    .top{
      font-family: PingFangSC-Medium,PingFang SC;
      font-weight: bold;
    }
    .middle{
      margin-left: 44px;
    }
    .analy{
      margin-left: 44px;
    }
    .com_input{
      margin-left: 10px;
      /deep/.el-input__inner,/deep/.el-input{
        color: #2D70F8;
        width: 337px;
        background: transparent;
        border: 1px solid #ddd;
      }
    }

    .green{
      /deep/.el-input__inner,/deep/.el-input{
        color: green;
        width: 337px;
      }
    }
    .red{
      /deep/.el-input__inner,/deep/.el-input{
        color: red;
        width: 337px;
      }
    }
    .tap {
      width: 60px;
      height: 30px;
      background: #2e7fff;
      border-radius: 15px;
      color: white;
      margin-right: 10px;
      font-size: 12px;
      padding: 2px 6px;
    }
    .content_box2 {
      // width: 30px;
      display: inline-block;
      height: 20px;
      border-radius: 4px;
      border: 1px solid red;
      color: red;
      margin-top: 20px;
      font-size: 12px;
      line-height: 20px;
      padding: 0 4px;
    }
    .content_box1 {
      border-radius: 4px;
      border: 1px solid #00c456;
      color: #00c456;
      margin-top: 20px;
      display: inline-block;
      padding: 2px 6px;
    }
    .review_middle{
      padding-left:40px;
      .item_list:first-child{
        margin-top: 30px;
      }
      .item_list{
        margin-top: 14px;
      }
      .reviewMult{
        /deep/.el-input__inner,/deep/.el-input{
          width: 400px;
        }
      }
      .review_red{
        /deep/.el-input__inner,/deep/.el-input{
          width: 400px;
          color: #F04B4A;
        }
      }
      .review_green{
        /deep/.el-input__inner,/deep/.el-input{
          width: 400px;
          color: #00C456;
        }
      }
    }
    .review_analy{
      padding-left:40px;
      margin-top: 20px;
      .review_analysis{
        width: 460px;
        // height: 100px;
        border: 1px solid #DDDDDD;
        padding: 10px;
        margin-top: 20px;
        & > p:first-child {
          color: #2e7fff;
        }
      }
    }
    .analy{
      .analysis {
        width: 400px;
        height: 100px;
        background: #23243c;
        border: 1px solid #2c2e4c;
        margin-bottom: 10px;
        color: white;
        padding: 10px;
        margin-top: 20px;
        & > p:first-child {
          color: #2e7fff;
        }
      }
    }
  }
}
</style>